import React, { useState, useEffect } from 'react'
import Aos from 'aos'; 
import Grid from '@material-ui/core/Grid';
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import WomenInTechImage from '../../assets/images/Women_in_Tech.png';
import { makeStyles } from "@material-ui/core/styles";
import '../../components/aboutus/testimonials/Testimonials.css'


const useStyles = makeStyles( ( theme ) => ( {
    topImageContainer:{
        overflow: 'hidden',
    },
    media: {
        position: "relative",
        paddingBottom: "68.25%", // 16:9
        backgroundSize: "80%",
        marginBottom:'1rem'
    },
    explanationBlock:{
        display:'flex',
        paddingTop:'1rem',
        margin:'1rem 0rem',
        '@media screen and (max-width: 900px)': {
            flexDirection:'column'
        },
    },
    explanation: {
        height:'100%',
        lineHeight:'2.5rem',
        flex:1,
        padding:'1rem',
        color: "#15678D",
        fontWeight: 200,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 20,
        '@media screen and (max-width: 1024px)': {
            fontSize: 20,
            lineHeight:'2rem',

        },
        '@media screen and (max-width: 700px)': {
            fontSize: 20,
            lineHeight:'2rem',

        },
    },
    icon:{
        fontSize:'3rem',
        margin:'1rem',
        color:'#15678D',

    },

    iconSmall:{
        fontSize:'2rem',
        margin:'1rem',
        color:'#15678D',
        justifyContent:'center'

    },
    apply:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        marginTop: '1rem',
    },
    button:{
        position: "relative",
        margin:'1rem 1rem',
        padding:'1rem',
        width: 'auto',
        height: '3rem',
        fontSize: 'medium',
        textShadow: '0px 1px 1px black',
        background: 'transparent',
        border: '1px solid #fff',
        color: '#fff',
        textDecoration: 'none',
        alignContent: 'flex-end',
        justifyContent: 'space-between',
        '&:hover': {
            transition: '0.3s ease-in-out',
            background: '#15678D',
            borderStyle: 'none',
            color: '#fff',
            cursor: 'pointer',
        },
},
applyText: {
    display:'none',
    margin:'1rem',
    height:'100%',
    flex:1,
    color: "#fff",
    textShadow: '0px 0.5px 5px black',
    fontWeight: 200,
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: 20,
    '@media screen and (max-width: 1024px)': {
        fontSize: 20,
        lineHeight:'2rem',

    },
    '@media screen and (max-width: 700px)': {
        fontSize: 20,
        lineHeight:'2rem',

    },

}
} ) );
const WomenInTech = () =>
{

    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const imageUrl = windowWidth >= 650 ? WomenInTechImage : WomenInTechImage;
    const classes = useStyles();
    useEffect(() => {
        Aos.init({ duration: 3000});
    }, []);
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );

    Aos.refresh()

    return (
        <div className= {classes.topImageContainer} >
        <Grid
            container
            spacing={ 3 }
            alignItems="center"
            justify="center"
        >
            <Grid item xs={ 12 } sm={ 12 } md={ 8 } >
                <CardMedia
                    >
                    <div  className={ classes.explanationBlock}>
                        <div data-aos="fade-up" className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                            <i className="fas fa-laptop-code"></i>
                        </div>
                        <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={ classes.explanation }>
                        Technology related workforce is becoming more powerful employment opportunity in the developed countries, but gaining a position in tech workforce is often regarded as unattainable.
                        </div>
                            <br/>
                            <br/>
                    </div>  
                </CardMedia>
            </Grid>
        </Grid>
        { windowWidth >= 1023 ?
        <>
            <Grid
            data-aos="zoom-in"
            container
            spacing={ 3 }
            alignItems="center"
            justify="center"
            >
                <Grid item xs={ 12 } >
                    <CardMedia
                        className={ classes.media }
                        style={ { backgroundImage: `url(${ imageUrl })` } }
                        component="h1">
                    </CardMedia>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={ 3 }
                alignItems="center"
                justify="center"
            >
                <Grid item xs={ 12 } >
                    <CardMedia
                        >
                        <div className={ classes.explanationBlock}>
                            <div data-aos="zoom-in" data-aos-delay={(windowWidth > 1024 ? "800" : "100")} className={classes.icon}>
                                <i className="fas fa-female"></i>
                            </div>
                            <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={ classes.explanation }>
                                It is especially regarded as very low likely for women at risk. <br></br>Blue Tulip Human Rights Center, intends to demonstrate that women at risk can be transitioned into tech workforce by providing mentorship, technical and career support.
                            </div>
                                <br/>
                                <br/>
                            <div data-aos={(windowWidth > 1024 ? "zoom-in" : "fade-up")} className={classes.icon}>
                                <i data-aos="zoom-in" className="fas fa-handshake"></i>
                            </div>
                            <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={ classes.explanation }>
                                For this purpose, Blue Tulip Human Rights Center will collaborate with local tech workforce prep institutions which are specialized in coding, testing, automation, and information assurance.
                            </div>
                        </div>  
                    </CardMedia>
                </Grid>
            </Grid>
        </> :
        <>
        <Grid
            container
            spacing={ 3 }
            alignItems="center"
            justify="center"
        >
            <Grid item xs={ 12 } >
                <CardMedia
                    >
                    <div  className={ classes.explanationBlock}>
                        <div data-aos="fade-up" className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                            <i className="fas fa-female"></i>
                        </div>
                        <div data-aos="fade-up" className={ classes.explanation }>
                        It is especially regarded as very low likely for women at risk. <br></br>Blue Tulip Human Rights Center, intends to demonstrate that women at risk can be transitioned into tech workforce by providing mentorship, technical and career support.
                        </div>
                            <br/>
                            <br/>
                        <div data-aos="fade-up" className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                            <i className="fas fa-handshake"></i>
                        </div>
                        <div data-aos="fade-up" className={ classes.explanation }>
                        For this purpose, Blue Tulip Human Rights Center will collaborate with local tech workforce prep institutions which are specialized in coding, testing, automation, and information assurance.
                        </div>
                    </div>  
                </CardMedia>
            </Grid>
            <Grid
            container
            spacing={ 3 }
            alignItems="center"
            justify="center"
            >
                <Grid item xs={ 12 } >
                    <CardMedia
                        className={ classes.media }
                        style={ { backgroundImage: `url(${ imageUrl })` } }
                        component="h1">
                    </CardMedia>
                </Grid>
            </Grid>
        </Grid>
    </>}
    </div>
    )}

export default WomenInTech;