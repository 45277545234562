import React, { useEffect, useState } from 'react';
import Aos from 'aos';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import 'swiper/swiper-bundle.css';
import './TopImage.css';
import { makeStyles } from "@material-ui/core/styles";
import DesktopTopImage from '../../assets/images/DesktopTopImage.jpg'

SwiperCore.use([Navigation, Pagination, Autoplay]) 
 
const useStyles = makeStyles( ( theme ) => ( {
    topImageContainer: {
        // display:'flex'
        zIndex:1,
    },
    media: {
        position: "relative",
        // height: 0,
        paddingTop: "58.25%", // 16:9
        backgroundSize: "cover"
    },
    root: {
        // marginBottom:"3rem"
    },
    header: {
        position: "absolute",
        display: "flex",
        top: "10%",
        left: "10%",
        maxWidth: "20%",
        textAlign: "start",
        color: "#fff",
        // backgroundColor: "white",
        textShadow: '0px 1px 3px black',
        // padding: "0rem 0rem",
        fontWeight: 200,
        boxShadow: "10px 0 0 white",
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 60,
        '@media screen and (max-width: 1024px)': {
            fontSize: 40,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 20,
        },
    },
} ) );

const TopImage = () =>
{
    const slides = [];
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const imageUrl = windowWidth >= 650 ? DesktopTopImage : DesktopTopImage;
    const classes = useStyles();
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );

    useEffect(() => {
        Aos.init({ duration: 3000});
    }, []);

for (let i = 0; i < 5; i+=1) {
    slides.push(
        <SwiperSlide key={`slide-${i}`} tag="li" >
            <img src={`/images/student_${i+1}.jpg`}/>
        </SwiperSlide>
    )
}
    return (
       <React.Fragment >
           <Swiper  data-aos="fade-up"
                    loop={true}
                    autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true }}
                    tag="section" 
                    wrapperTag="ul" 
                    // navigation 
                    pagination 
                    spaceBetween={5} 
                    slidesPerView={1}>
               {slides}
           </Swiper>
       </React.Fragment>
    );
};

export default TopImage;
