import React, { useState, useEffect } from 'react'
import Aos from 'aos';
import {
    Grid,
    Typography
} from '@material-ui/core';
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( ( theme ) => ( {
    media: {
        position: "relative",
        paddingBottom: "22.25%", // 16:9
        backgroundSize: "50%",
        marginBottom: '3rem'
    },
    topImageContainer: {
        height: '40rem',
        marginTop:"3rem"
    },

    topImageContainerSmall: {
        height: '180rem',
        marginTop:"1rem"

    },
    explanationBlock: {
        display: 'flex',
        paddingTop: '1rem',
        margin: '2rem 2rem 0rem 2rem ',
        '@media screen and (max-width: 900px)': {
            flexDirection: 'column'
        },
    },
    explanation: {
        height: '100%',
        lineHeight: '2.5rem',
        flex: 1,
        padding: '1rem',
        color: "#15678D",
        fontWeight: 200,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 20,
        '@media screen and (max-width: 1024px)': {
            fontSize: 20,
            lineHeight: '2rem',

        },
        '@media screen and (max-width: 700px)': {
            fontSize: 15,
            lineHeight: '2rem',

        },
    },
    icon: {
        fontSize: '3rem',
        margin: '1rem',
        color: '#15678D',

    },
    iconConsentForm:{
        fontSize: '3rem',
        margin: '1rem',
        color: '#15678D',
        textAlign:"center",
        
    },
    iconConsentFormSmall:{
        fontSize: '2rem',
        margin: '0.5rem',
        color: '#15678D',
        textAlign:"center",
        
    },
    iconSmall: {
        fontSize: '2rem',
        margin: '1rem',
        color: '#15678D',
        justifyContent: 'center'

    },
    impSpan: {
        fontWeight: 900
    },
    formStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '1rem'

    },
    documentItem: {
        textDecoration: 'none',
        color: "#15678D",
        alignSelf: 'start',
        fontSize: '1.5rem',
    },
    documentItemSmall: {
        textDecoration: 'none',
        color: "#15678D",
        alignSelf: 'start',
        fontSize: '1rem',
    },
    documentLine: {
        textAlign: 'start',
    },
} ) );
const AnnualReports = () =>
{

    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    useEffect( () =>
    {
        Aos.init( { duration: 3000 } );
    }, [] );
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );
    return (
        <div data-aos="fade-up" className={ ( windowWidth > 1024 ? classes.topImageContainer : classes.topImageContainerSmall )} >
            <Grid
                container
                spacing={ 0 }
                alignItems="center"
                justify="center"
            >
                <Grid item xs={ 12 } sm={ 12 } md={ 10 } >
                    <CardMedia
                    >
                        <div className={ classes.explanationBlock }>
                            <div data-aos="fade-up" className={ ( windowWidth > 1024 ? classes.icon : classes.iconSmall ) }>
                                <i className="fas fa-user-tie"></i>
                            </div>
                            <div className={ classes.explanation }>
                            At Blue Tulip Human Rights Center, we are committed to maintaining the highest standards of transparency and accountability. To demonstrate our dedication to these principles, we are pleased to share our Form 990 with our community.

The Form 990 is an annual information return that most organizations exempt from income tax must file with the IRS. It provides detailed information about our mission, programs, and finances.

You can view our latest Form 990 in the below links. Other years' data can also be accessed at IRS non-profit database.

Thank you for your continued support and interest in Blue Tulip Human Rights Center. <br/><br/>If you have any questions or need further information, please do not hesitate to contact us.
                            </div>
                            <br />
                            <br />
                        </div>
                    </CardMedia>
                </Grid>

                <Grid data-aos="fade-up" className={ classes.formStyle } container spacing={ 1}>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={( windowWidth > 1024 ? classes.iconConsentForm  : classes.iconConsentFormSmall )} >
                        <a href="/documents/2023_Form990.pdf" target="_blank"><i className="far fa-file-pdf fa-1x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 6 } sm={ 5 } md={ 5 } className={ classes.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={( windowWidth > 1024 ? classes.documentItem  : classes.documentItemSmall )} href="/documents/2023_Form990.pdf" target="_blank">2023 Tax Document</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ classes.formStyle } container spacing={ 1 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={( windowWidth > 1024 ? classes.iconConsentForm  : classes.iconConsentFormSmall )} >
                        <a href="/documents/2022_Form990.pdf" target="_blank"><i className="far fa-file-pdf fa-1x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 6 } sm={ 5 } md={ 5 } className={ classes.documentLine }>
                        <Typography variant="subtitle1">
                            <a className= {( windowWidth > 1024 ? classes.documentItem  : classes.documentItemSmall )} href="/documents/2022_Form990.pdf" target="_blank">2022 Tax Document</a>
                        </Typography>
                    </Grid>
                </Grid>
                
                
            </Grid>
        </div>
    )
}
export default AnnualReports;