import React, { useState, useEffect } from "react";
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { useLocation } from "react-router-dom";
import { Grid, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( ( theme ) => ( {

  submitButton: {
  height: '3rem',
  background: '#1D58A8',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#1D58A8',
  }
},
}))

const ScholarshipVerification = () =>
{
  const classes = useStyles();
  const [ inProgress, setInProgress ] = useState( true )
  const [ isVerified, setIsVerified ] = useState( false );
  const [ isRejected, setIsRejected ] = useState( false );
  const { REACT_APP_VERIFICATION_POST_URL } = process.env;

  function usePageViews ()
  {
    console.log( "usePageViews starts here" )
    let location = useLocation();
    console.log( REACT_APP_VERIFICATION_POST_URL + location.search )
    useEffect( () =>
    {
      axios.post( REACT_APP_VERIFICATION_POST_URL + location.search )
        .then( function ( response )
        {
          console.log( "usePageViews response: ", response )
          setIsVerified( true )
          setInProgress( false )
        } )
        .catch( function ( error )
        {
          console.log( "usePageViews error: ", error )
          setIsRejected( true )
          setInProgress( false )
        } )
    }, [] )
  };


  usePageViews();
  return (
    <Grid container style={ { height: '30em', marginTop: '2rem' } }>
      <Grid item md={ 2 }></Grid>
      <Grid item md={ 8 }>
        { inProgress && ( <Alert variant="filled" severity="info" >
          Please wait while we are verifying your email address...</Alert>
        ) }
        { isVerified && (
          <Alert
            variant="filled"
            severity="success"
            action={
              <Button
                variant="contained"
                className={ classes.submitButton }
                size="large"
                href="/scholarship/signin">
                SIGN IN
              </Button>
            }
          >
          Your email address is verified successfully! Now, you can Sign in to the scholarship application</Alert>
        ) }
        { isRejected && ( <Alert variant="filled" severity="error">
          Oops! Something went wrong, please try again later.</Alert>
        ) }
      </Grid>
      <Grid item md={ 2 }></Grid>
    </Grid>
  );
}
export default ScholarshipVerification;