import React, { useState, useEffect } from "react";
import '../testimonials/Testimonials.css'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CardMedia } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import pic1 from '../../../assets/images/melissasm.jpg'
import pic2 from '../../../assets/images/sara.jpeg'
import pic3 from '../../../assets/images/Cathy.jpg'
import pic4 from '../../../assets/images/Jan_Headshot.jpg'

const useStyles = makeStyles( ( theme ) => ( {
    root: {
        marginTop:'2rem',
    },
    media: {
        boxShadow: "0 5px 10px grey",
        marginLeft:'3.5rem',
        marginTop:'2rem'
    },
    who: {
        margin: '3em 2em',
        color: '#15678D',
    },
    paper: {
        padding: theme.spacing( 10 ),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        // height: '16rem',
        alignItems:'center',
        justifyContent:'space-between'
    },
    
    details: {
        color: '#15678D',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'1.2rem',
        textDecoration: 'none',
    },

    detailsSmall:{
        color: '#34455E',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'0.8rem',
        textDecoration: 'none',
    },
    whoInfoTop:{
        margin:'2rem 3rem -1rem 3rem',
        fontSize:'1rem',
        display:'flex',
     },
    whoInfo:{
       marginTop:'5rem',
       marginBottom:'5rem',
       fontSize:'1.6rem',
       display:'flex',
    },

    whoInfoSmall:{
        marginTop:'2rem',
        fontSize:'1.2rem',
        display:'flex',
        flexWrap:'wrap',
        textAlign:'center',
        margin:'1rem'

     },
     explanation:{
        borderLeft:'4px solid #15678D',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
        flex:1,
     color:'#15678D'

     },
     explanationSmall:{
        borderTop:'4px solid #15678D',
        width:'100%',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
        textAlign:'center'

     },

    icon:{
        fontSize:'5rem',
        margin:'2rem 2rem',
    },

    iconSmall:{
        fontSize:'3rem',
        margin:'2rem 2rem',

    },
    whoBtn: {
        // position: "relative",
        width: '8rem',
        height: '3rem',
        marginTop:'5rem',
        fontSize: 'medium',
        backgroundColor: '#fff',
        border: '1px solid #15678D',
        color: '#15678D',
        boxShadow: '0 0 3px 1px #fff',
        textDecoration: 'none',
        borderRadius:'0.2rem',
            '&:hover': {
                transition: '0.7s ease-in-out',
                background: '#15678D',
                borderStyle: 'none',
                color: '#fff',
                cursor: 'pointer',
            },
    },

    whoName:{
        color:'#15678D',
        fontSize:'1.2rem',
        lineHeight:'1.8rem',
        paddingBottom:'1rem'
    },

    whoNameBottom:{
        color:'#15678D',
        fontSize:'1.2rem',
        lineHeight:'1.8rem',
        marginTop:'5rem'
    },
    melissaName:{
        fontSize:'1rem'
    }


} ) );

export default function WhoCathy ()
{
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );
    return (
        <div className={ classes.who }>
            <Grid container spacing={ 3 } >
                <Grid item xs={ 12 } sm={ 12 } md={ 12 } >
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 8 } sm={ 6 } md={ 2 }>
                                <CardMedia
                                    component="img"
                                    alt="Cathy's picture"
                                    height="300"
                                    className={ classes.media }
                                    image={ pic3 }
                                />
                            </Grid>
                            <Grid item xs={ 12 } sm={10 } md={ 10 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                        <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>Cathy Lewis is one of Hampton Roads’ most veteran media professionals, having worked in commercial and public broadcasting for more than 30 years. She is the founding host of “HearSay with Cathy Lewis” on 89.5 WHRV-FM which debuted in 1996. Ten Virginia governors, numerous members Virginia’s congressional delegation and resident Obama have appeared on the broadcast.</span>
                                        <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>Cathy also serves as President and CEO of the CIVIC Leadership Institute. She was asked to lead the organization in 2001 by the late philanthropist Joshua P. Darden, Jr., and today, CIVIC is recognized as a robust engine for leveraging the leadership of more than 600 executives in service to the Hampton Roads region.</span>
                                        <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>With expertise in civil discourse, community engagement, and communications, Cathy is a popular speaker, trainer, and facilitator of corporate and community conversations. She is a national member of the National Speakers Association® and has served as President of the Virginia chapter.</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={ 12 } sm={10 } md={ 12 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                        <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>Frequently ranked among the region’s top radio personalities, Cathy has also been named consistently to the Inside Business Power List, an annual roll call of the 75 most powerful people in the region. In addition, she has been named an Influential Woman of Virginia by Lawyer’s Weekly and one of the 50 Very Important People in Hampton Roads by Hampton Roads Monthly. </span>
                                        <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>Cathy’s community services include eight years as a trustee for The Williams School, where she served two terms as president. Her husband is the rector of Christ & St. Luke’s Episcopal Church in Norfolk. Cathy’s humor essays about her unexpected life as a clergy spouse and sports mom have aired nationally on The Hallmark Channel. Cathy is a cum laude graduate of the W. Page Pitt School of Journalism at Marshall University and holds an honorary doctorate from Virginia Wesleyan College.</span>
                                    </div>
                                    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 12}>
                    <h1 style={ { color: '#15678D', textShadow: '1px 1px #fff', textAlign:'center', marginTop: '2rem', marginBottom: '1em' } }>Other Senior Board of Directors</h1>
                </Grid>
                    <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                            <Card className={ classes.root }>
                                <Grid container direction='row' spacing={ 3 } >
                                    <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                        <CardMedia
                                            component="img"
                                            alt="Focus News"
                                            height="300"
                                            image={ pic1 }
                                        />
                                    </Grid>
                                    <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                        <div className={classes.info}>
                                            <div className={classes.whoNameBottom}> 
                                                <b className={classes.melissaName}>Melissa Page Deutsch</b>
                                            </div>
                                            <a href='/who-melissa'>
                                                <button className={classes.whoBtn}>
                                                    About Melissa
                                                </button>
                                            </a>    
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>
                    </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Sara's picture"
                                    height="300"
                                    image={ pic2 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                            <div className={classes.info}>
                                <div className={classes.whoNameBottom}> 
                                    <b>Sara Elizabeth Dill</b>
                                </div >
                                    <a href='/who-sara'>
                                        <button className={classes.whoBtn}>
                                            About Sara
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Jan's picture"
                                    height="300"
                                    image={ pic4 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <div className={classes.info}>
                                    <div className={classes.whoNameBottom}> 
                                        <b>Jan Pederson</b>
                                    </div >
                                    <a href='/who-jan'>
                                        <button className={classes.whoBtn}>
                                            About Jan
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

            </Grid>
        </div>)}