import React, { useState, createRef } from "react";
import axios from 'axios';
import { Formik } from "formik";
import * as Yup from "yup";
import MenuItem from '@material-ui/core/MenuItem';
import
{
  ListItemText,
  Checkbox,
  Input,
  Button,
  TextField,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import { stateNameList } from '../../assets/stateNameList';
import { Skillset } from '../../assets/Skillset';
import './JoinUs.css'
import ReCAPTCHA from 'react-google-recaptcha';


const submissionValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Please enter your first name"),
  lastName: Yup.string().required("Please enter your last name"),
  emails: Yup.string().email("Please enter a valid email address").required("Please enter your email address"),
  city: Yup.string().required("Please enter your city name"),
  state: Yup.string().required("Please enter your state name"),
});

const stylesFunc = makeStyles((theme) => ({
  wrapper: {
    marginTop: "3rem",
    height: "calc(200vh - 19.0625rem)",
    // textAlign: 'start',
    marginBottom: "9rem",
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: ''

  },
  
  formLogo: {
    maxWidth: 150,
    marginBottom: 20
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
    color: "#34455E"
  },
  submitButton: {
    marginBottom: '1em',
    background: '#14688D',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#14688D'
    }
  },
  formStyle:{
    zIndex:5
}
}
));

const JoinUs = () =>
{
  const [ isSuccess, setIsSuccess ] = useState(false);
  const [ isFailure, setIsFailure ] = useState(false);
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(false);
  const [ memberSubmission, setMemberSubmission ] = useState('');
  const [ skill, setSkill ] = useState([]);
  const reRef = createRef();

  const handleSkillChange = (event) =>
  {
    setSkill(event.target.value);
  };

  const { REACT_APP_MEMBERSHIP_API } = process.env;
  
  const handleChange = (event) =>
  {
    setMemberSubmission({ ...memberSubmission, [ event.target.name ]: (event.target.value) })
  }

  const handleSubmit = async (values) =>
  {
    console.log("values: ", values)
    const g_recaptcha_response = await reRef.current.executeAsync();
    axios.post(REACT_APP_MEMBERSHIP_API, values, {
      headers: {g_recaptcha_response, 'Content-Type': 'application/json'}})
      .then((response) =>
      {
        console.log("response: ", response)
        setIsSuccess(true)
        setIsButtonDisabled(true)
      })
      .catch((error) =>
      {
        console.log("error message: ", error)
        setIsFailure(true)
        setIsButtonDisabled(true)
      })
  };

  const formStyles = stylesFunc();

  return (

    <Container className={ formStyles.wrapper } maxWidth="sm">
      <div className={ formStyles.header }>
        <i className="fas fa-users fa-5x" style={ { color: "#14688D" } }></i>
        <Typography className={ formStyles.formHeader } variant="h4">
          Membership Application
        </Typography>
      </div>
      <Formik
        initialValues={ {
          firstName: '',
          lastName: '',
          emails: '',
          city: '',
          state: '',
          address: '',
          phone: '',
          linkedIn: '',
          faceBook: '',
          skill: [],
        } }
        validationSchema={ submissionValidationSchema }
        onSubmit={ handleSubmit }
        onChange={ handleChange }
      >
        { ({ handleSubmit, handleChange, values, errors }) => (
          <form onSubmit={ handleSubmit }>
            <Grid className={formStyles.formStyle} container spacing={ 3 }>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  type="text"
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  value={ values.firstName }
                  onChange={ handleChange }
                  helperText={ errors.firstName }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  type="text"
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  value={ values.lastName }
                  onChange={ handleChange }
                  helperText={ errors.lastName }
                />
              </Grid>

              <Grid item xs={ 12 }>
                <TextField
                  required
                  name="emails"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={ values.emails }
                  onChange={ handleChange }
                  helperText={ errors.emails }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="city"
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={ values.city }
                  onChange={ handleChange }
                  helperText={ errors.city }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  select
                  name="state"
                  label="State"
                  variant="outlined"
                  fullWidth
                  value={ values.state }
                  onChange={ handleChange }
                  helperText={ errors.state }
                >
                  { stateNameList.map((option) => (
                    <MenuItem key={ option.value } value={ option.stateName }>
                      {option.stateName }
                    </MenuItem>
                  )) }
                </TextField>
              </Grid>
              <Grid item xs={ 12 }>
                <TextField
                  name="address"
                  label="Address"
                  variant="outlined"
                  fullWidth
                  value={ values.address }
                  onChange={ handleChange }
                />
              </Grid>
              <Grid item xs={ 12 }>
                <TextField
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  value={ values.phone }
                  onChange={ handleChange }
                />
              </Grid>
              <Grid item xs={ 12 }>
                <TextField
                  // required
                  select
                  name="Skillset"
                  label="Skill set"
                  variant="outlined"
                  fullWidth
                  helperText={ errors.skill }
                  input={ <Input /> }
                  SelectProps={ {
                    multiple: true,
                    value: skill,
                    onChange: handleSkillChange,
                    renderValue: (selected) => selected.join(', ')
                  } }
                >
                  { Skillset.map((option) => (
                    <MenuItem  key={ option.value } value={ option.skill }>
                      <Checkbox style={ { color: "#34455E", marginRight:'1rem' } } checked={ skill.indexOf(option.skill) > -1 } />
                      <ListItemText  style={ { marginTop:'0.7rem' } } primary={ " " + option.skill } />
                    </MenuItem>
                  )) }
                </TextField>
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  name="linkedIn"
                  label="LinkedIn"
                  variant="outlined"
                  fullWidth
                  value={ values.linkedIn }
                  onChange={ handleChange }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  name="faceBook"
                  label="Facebook"
                  variant="outlined"
                  fullWidth
                  value={ values.faceBook }
                  onChange={ handleChange }
                />
              </Grid>
              <ReCAPTCHA
                sitekey={ process.env.REACT_APP_RECAPTCHA_SITE_KEY }
                size="invisible"
                ref={ reRef }
              />
              <Grid item xs={3}></Grid>
              <Grid item xs={ 6 }>
                <Button
                  className={ formStyles.submitButton }
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={ isButtonDisabled }
                > Submit
                </Button>
                { isSuccess && (<Alert variant="filled" severity="success">
                  Your request has been submitted successfully! Please check your inbox for a verification email.</Alert>
                ) }
                { isFailure && (<Alert variant="filled" severity="error">
                  Oops! Something went wrong, please try again later.</Alert>) }
              </Grid>
            </Grid>
          </form>
        ) }
      </Formik>
    </Container>
  );
}

export default JoinUs;
