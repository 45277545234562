import React, { useState, useEffect } from "react";
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { useLocation } from "react-router-dom";

const Verification = () => {
  const [inProgress, setInProgress] = useState(true)
  const [isVerified, setIsVerified] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const { REACT_APP_VERIFICATION_BASE_URL } = process.env

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    axios.post(REACT_APP_VERIFICATION_BASE_URL + location.search)
    .then(function(response) {
        console.log(response)
        setIsVerified(true)
        setInProgress(false)
        })
        .catch(function(error) {
        console.log(error)
        setIsRejected(true)
        setInProgress(false)
    })},[location])};


 usePageViews();
  return (
    <div style={{height:'30em'}}>
      {inProgress && (<Alert variant="filled" severity="info" >
        Please wait while we are verifying your email address...</Alert>
      )}
      {isVerified && (<Alert variant= "filled" severity="success">
        Your email address is verified successfully! We'll send you another email to complete the membership process as soon as possible.</Alert>
      )}
      {isRejected && (<Alert variant= "filled" severity="error">
        Oops! Something went wrong, please try again later.</Alert>
      )}
     </div>
    );
}
export default Verification;
