import React, { useState, createRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import
{
  Button,
  TextField,
  Grid,
  Container,
  Typography,
  Link,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from '../../services/AuthService';
import UserService from '../../services/UserService';
import Alert from '@material-ui/lab/Alert';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ReCAPTCHA from 'react-google-recaptcha';


const submissionValidationSchema = Yup.object().shape( {
  email: Yup.string().email( "Please enter a valid email address" ).required( "Please enter your email address" ),
  password: Yup.string().required( 'Please enter your password ' )
} );

const stylesFunc = makeStyles( ( theme ) => ( {
  wrapper: {
    marginTop: "3rem",
    height: "calc(200vh - 19.0625rem)",
    textAlign: 'center',
    marginBottom: "6rem",
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: ''
  },
  root: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 1,
    },
    '& input:invalid:focus + fieldset': {
      borderColor: 'red',
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      borderColor: 'green',
      padding: '4px !important', // override inline-style
    },
  },
  formLogo: {
    maxWidth: 150,
    marginBottom: 20
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  submitButtonContainer:{
    alignItems:'center',

  },
  
  submitButton: {
    marginBottom: '1em',
    background: '#15678D',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#15678D'
    }
  },
  signUpLink: {
    color: '#15678D',
    justifyContent:'center'
  
  }
} ) );

const Signin = ( props ) =>
{
  const [ isSuccess, setIsSuccess ] = useState( false );
  const [ isFailure, setIsFailure ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ message, setMessage ] = useState( "" );
  const [ isDisabled, setIsDisabled ] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const reRef = createRef();


  const handleClickShowPassword = ()=>
  {
      setShowPassword(!showPassword)
  }

  const handleLogin = async ( values ) =>
  {
    const g_recaptcha_response = await reRef.current.executeAsync();
    setMessage( "" );
    setLoading( true );
    // console.log("captcha_response: ", g_recaptcha_response)
    // console.log("values.email", values.email)
    // console.log("values.password", values.password)
    AuthService.login( values.email, values.password, g_recaptcha_response ).then(
      ( response ) =>
      {
        console.log( "response: ", response )
        props.history.push( "/scholarship/otp" );
        window.location.reload();
        setIsDisabled( true )
        setIsSuccess( true )
      },
      ( error ) =>
      {
        const resMessage =
          ( error.response &&
            error.response.data &&
            error.response.data.message ) ||
          error.message ||
          error.toString();
        // setLoading( false );
        setMessage( resMessage );
        setIsFailure( true )
      }
    );
    setLoading( false );
  };
  const formStyles = stylesFunc();
  return (

    <Container className={ formStyles.wrapper } maxWidth="sm">
      <div style={ { display: 'flex', justifyContent: 'flex-end' } }>  </div>
      <div className={ formStyles.header }> { UserService.getUserBoard() }
        <i className="fas fa-graduation-cap fa-5x" style={ { color: "#15678D" } }></i>
        <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h4">
          Scholarship Program
              </Typography>
        <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h5">
          Sign In
              </Typography>
      </div>
      <Formik
        initialValues={ {
          email: '',
          password: '',
        } }
        validationSchema={ submissionValidationSchema }
        onSubmit={ handleLogin }
      >
        { ( { handleSubmit, handleChange, values, errors, touched } ) => (
          <form onSubmit={ handleSubmit } className={ formStyles.root }>
            <Grid container spacing={ 3 } justify="center">
              <Grid item xs={ 10 }>
                <TextField
                  required
                  name="email"
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  value={ values.email }
                  onChange={ handleChange }
                  error={ touched.email && Boolean( errors.email ) }
                  helperText={ errors.email }
                />
              </Grid>
              <Grid item xs={ 10 }>
                <TextField
                  required
                  name="password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  fullWidth
                  value={ values.password }
                  onChange={ handleChange }
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={ errors.password && Boolean( errors.password ) }
                  helperText={ errors.password }
                />
              </Grid>
              <ReCAPTCHA
                sitekey={ process.env.REACT_APP_RECAPTCHA_SITE_KEY }
                size="invisible"
                ref={ reRef }
              />
              <Grid item xs={ 6 } className={ formStyles.submitButtonContainer }>
                <Button
                  disabled={ isDisabled }
                  className={ formStyles.submitButton }
                  type="submit"
                  variant="contained"
                  fullWidth
                > Sign In
                </Button>
                    { isSuccess && ( <Alert variant="filled" severity="success">
                      Password is accepted</Alert>
                    ) }
                    { isFailure && ( <Alert variant="filled" severity="error">
                      The email address or password you typed in doesn't match with our records</Alert> ) }
              </Grid>
              {/* <Grid container spacing={3} justifyContent="center"> */}
                <Grid item xs={ 10 } direction="row"  justify="center" className={ formStyles.signUpLink }>
                            <Link href="/scholarship/forgot-password" > Forgot your password?</Link>
                </Grid>
                <br></br>
                <Grid item xs={ 10 } className={ formStyles.signUpLink }>Don't you have an account?
                  <Link href="/scholarship/register" >Sign Up</Link>
                </Grid>
              {/* </Grid> */}
            </Grid>
          </form>
        ) }
      </Formik>
    </Container>
  );
}

export default Signin;