import name1 from "../../../assets/images/Cathy.png";
import name2 from "../../../assets/images/tom.jpg";
import name3 from "../../../assets/images/courtney.png";

export const testim = [
    {
        id: 1,
        author: "Cathy Lewis",
        job: "WHRV",
        date: "September 27, 2017",
        image: name1,
        height: "100px",
        speechs:
            "We should note these are women and men as well, but women with children who are in pre-trialed detention in jails across Turkey with their babies…Babies are growing up in prison….2000 school shut down, 122000 people detained, 65000 people arrested that’s shocking and hundreds of human rights violations according to Amnesty International in these cases…Blue Tulip Human Rights Center is established to advocate for these people and the issue is there is no freedom of speech now because 149 media outlets have been shut down, 274 journalists arrested…Turkey is now ranking 151st of 180 in terms of press freedoms. This organization, by the way, says it is not really interested in who is doing this, why they are doing it… they are just interested in bringing relief to the victims of this circumstance and it is a terrible one indeed… blue-tulip.org is where you can learn more… People who are being persecuted in masses, some families both parents are in jail, their children are trying to survive without their parents, more than 650 mothers in jail with their children without a separate place or a bed….",
    },

    {
        id: 2,
        author: "Tom Bowman",
        job: "NPR",
        date: "December 22, 2016",
        image: name2,
        speechs:
            "A group of Turkish military officers deployed to the U.S. wants to stay in America much longer than a typical rotation for visiting foreign officers.More than two dozen officers deployed to a NATO command in the Norfolk, Va., area, are seeking asylum in the U.S., fearing they will be wrongly imprisoned by the government of President Recep Tayyip Erdogan, NPR has learned. The men, including Turkish navy, air force and army officers who rank from major to much more senior grades, have been accused of ties to this summer’s failed coup attempt against Erdogan. Some have been posted in the U.S. for as long as three years. The regime in Ankara is already putting pressure on their family members back home.",
    },

    {
        id: 3,
        author: "Courtney Mabeus",
        job: "The Virginian-Pilot",
        date: "December 22, 2016",
        image: name3,
        speechs:
            "Erdogan’s purge has reached as far as Hampton Roads. It includes more than two dozen military officials assigned to the local NATO command, according to several dismissed officers and family members who spoke to The Virginian-Pilot in recent weeks, all on the condition of anonymity for fear of further retaliation. By the end of September, some of those officers’ names began to appear on lists of those being criminally investigated along with demands that they surrender their diplomatic passports and return home. Others were dismissed by orders from the Ministry of National Defense, one former officer said. Those officers say they have no information about what they’re charged with. Returning to Turkey would almost certainly mean fates similar to those of their friends and colleagues who have been jailed, the officers said. That group includes one from the local NATO command who did return and was arrested, they said. They identified their colleagues’ swollen and bloodied faces and bodies in photos that surfaced on the internet among claims that they were beaten while detained.​",
    },
];
