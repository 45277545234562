import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import './News.css';
import moment from "moment";

const useStyles = makeStyles( ( theme ) => ( {
    root: {
        position: 'relative',
        marginBottom: '2rem',
        width: '25rem',
        top: '1rem',
    },
    sideNews: {
        justifyContent: "center",
        alignContent: 'center',
    },
    frontMedia: {
        minHeight: '5rem',
        height: '6rem',
        width: '100%',
        backgroundSize: '100% 100%',
        position: 'relative',
        '@media screen and (max-width: 1024px)': {
            height: '4rem',
        },
        boxShadow: "0 5px 10px grey",
    },
    title: {
        position: "relative",
        textAlign: "start",
        color: "#1D58A8",
        width:"100%",
        '@media screen and (max-width: 1300px)': {
            width: "90%",
            fontSize: 14,
        },
        '@media screen and (max-width: 1024px)': {
            width: "80%",
            fontSize: 13,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 12,
        },
        fontWeight: 900,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 16,
        textDecoration:'none',
    },
    date: {
        position: "relative",
        textAlign: "start",
        color: "#34455E",
        fontWeight: 600,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 14,
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 10,
        },
    }
} ) )

export default function NewsSideElement ( { id, image, date, author, title, summary, body, link, tags } )
{

    const [ isFlipped, setIsFlipped ] = useState( false );

    const classes = useStyles();

    const handleClick = () =>
    {
        setIsFlipped( !isFlipped );
    }

    const formattedDate = moment( date ).format( 'MMMM D, YYYY' )

    return (
        <div className={ classes.root }>
            <Grid container spacing={ 2 } alignItems="center" justify="center" >
                <Grid item xs={ 4 } sm={ 4 } md={ 5 }>
                    <a href={ `/news/${ id }` } >
                        <CardMedia
                            className={ classes.frontMedia }
                            component="div"
                            image={ `data:image/jpeg;base64, ${ image }` }
                        >
                        </CardMedia>
                    </a>
                </Grid>
                <Grid item xs={ 6 } sm={ 6 } md={ 7 } >
                    <Typography gutterBottom variant="subtitle2" className={ classes.date }>
                        { formattedDate }
                    </Typography>
                    <a href={ `/news/${ id }`} className={ classes.title } >
                        <Typography gutterBottom component="h2" className={ classes.title }>
                            { title }
                        </Typography>
                    </a>
            </Grid>
            </Grid>
        </div >
    )
};