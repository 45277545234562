import React from "react";
import { testim } from "./testim.js";
import "./Testimonials.css";

export default function Testimonials(props) {
    return (
        <div className="testim">
            <div className="titleWords words-1">
            </div>
            <div>
                {testim.map((item) => {
                    return (
                        <React.Fragment>
                            <div className="testimonials">
                                <div className="title-wrap">
                                    <h2>{item.author}</h2>
                                    <h3>{item.job}</h3>
                                    <h3>{item.date}</h3>
                                </div>
                                <div className="image-wrap">
                                    <img src={item.image} alt="board member"/>
                                </div>
                            </div>
                            <div>
                                <p className="speech">{item.speechs}</p>
                            </div>

                            {/* <hr style={{ padding: 10 }} /> */}
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                            <br></br>
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
}
