import React, { useState } from 'react';
import { Button } from '../button/Button';
import { MenuItems } from './MenuItems';
import { Link } from 'react-router-dom';
import './Navbar.css';
import mediumLogo from '../../assets/images/mediumLogo.svg';
import logoWithName from '../../assets/images/logo_Blue_Tulip.png';
import xLogo from '../../assets/images/x-logo-black.png';
import logo from '../../assets/images/leaves.svg';
import logoName from '../../assets/images/just_text.svg';
import DropdownProjects from './dropdown/projects/DropdownProjects';
import DropdownAboutus from './dropdown/aboutus/DropdownAboutus';
import DropdownTakeAction from './dropdown/takeAction/DropdownTakeAction';
import SideDrawer from './SideDrawer';

function Navbar ()
{
    const [ click, setClick ] = useState( false );
    const [ aboutusDropdown, setAboutusDropdown ] = useState( false );
    const [ projectsDropdown, setProjectsDropdown ] = useState( false )
    const [ takeActionDropdown, setTakeActionDropdown ] = useState( false )

    const handleClick = () => setClick( !click );
    // const closeMobileMenu = () => setClick( false );

    const onMouseEnterAboutus = () =>
    {
        if ( window.innerWidth < 960 ) {
            setAboutusDropdown( false );
        } else {
            setAboutusDropdown( true );
        }
    };

    const onMouseEnterProjects = () =>
    {
        if ( window.innerWidth < 960 ) {
            setProjectsDropdown( false );
        } else {
            setProjectsDropdown( true );
        }
    };

    const onMouseEnterTakeAction = () =>
    {
        if ( window.innerWidth < 960 ) {
            setTakeActionDropdown( false );
        } else {
            setTakeActionDropdown( true );
        }
    };

    const onMouseLeaveAboutus = () =>
    {
        if ( window.innerWidth < 960 ) {
            setAboutusDropdown( true );
        } else {
            setAboutusDropdown( false );
        }
    };

    const onMouseLeaveProjects = () =>
    {
        if ( window.innerWidth < 960 ) {
            setProjectsDropdown( true );
        } else {
            setProjectsDropdown( false );
        }
    };
    const onMouseLeaveTakeAction = () =>
    {
        if ( window.innerWidth < 960 ) {
            setTakeActionDropdown( true );
        } else {
            setTakeActionDropdown( false );
        }
    };

    return (
        ( window.innerWidth > 1024 ?
            <div>
                <nav className="NavbarItems" >
                    <div className='logoset'>
                        <Link to="/" >
                            <img className="logo" src={ logo } alt="logo"></img>
                        </Link >
                        <Link to="/" className="logoName"  >
                            <img className="logoName" src={ logoName } alt="logo name"></img>
                        </Link >
                    </div>

                    <div className='menu-icon' onClick={ handleClick } >
                        <i className={ click ? 'fas fa-times' : 'fas fa-bars' }
                        />
                    </div>
                    <ul className={ click ? 'nav-menu active' : 'nav-menu passive' } >
                        <li className='nav-item'
                            onMouseEnter={ onMouseEnterAboutus }
                            onMouseLeave={ onMouseLeaveAboutus }
                        >
                            <Link to='#' className='nav-links ' >
                                ABOUT US <i className='fas fa-caret-down ' />
                            </Link> { aboutusDropdown && < DropdownAboutus /> }
                        </li>
                        <li className='nav-item' onMouseEnter={ onMouseEnterProjects } onMouseLeave={ onMouseLeaveProjects } >
                            <Link to='#' className='nav-links ' >
                                PROJECTS <i className='fas fa-caret-down' />

                            </Link> { projectsDropdown && < DropdownProjects /> }
                        </li>
                        <li className='nav-item' onMouseEnter={ onMouseEnterTakeAction } onMouseLeave={ onMouseLeaveTakeAction } >
                            <Link to='#' className='nav-links '  >
                                TAKE ACTION <i className='fas fa-caret-down' />

                            </Link> { takeActionDropdown && < DropdownTakeAction /> }
                        </li>
                        {
                            MenuItems.map( ( item, index ) =>
                            {
                                return (
                                    <li key={ index }>
                                        <a className={ item.cName } href={ item.path } target={ item.name === 'MEMBERS' ? "_blank" : null }> { item.name } </a>
                                    </li>
                                )
                            } ) }
                    </ul>
                    <div className="social" >
                        <a className="fab fa-facebook-square fa-2x" href='https://www.facebook.com/bluetulip.org/' rel="noreferrer" target="_blank"  ></a>
                        <a className="fab fa-linkedin fa-2x" href='https://www.linkedin.com/in/blue-tulip-human-rights-center' rel="noreferrer" target="_blank" ></a>
                        {/* <a className="fab fa-twitter fa-2x" href='https://twitter.com/BlueTulipHRC' target="_blank" rel="noreferrer" ></a> */}
                        <a href='https://twitter.com/BlueTulipHRC' target="_blank" rel="noreferrer">
                            <img className="xLogo" src={xLogo} alt="x platform" />
                        </a>
                        <a href='https://medium.com/@BlueTulipHRC' className="mediumLogoContainer" rel="noreferrer" target="_blank" >
                            <img className="mediumLogo" src={ mediumLogo }></img>
                        </a>
                    </div>
                    <div className="donate" >
                        <a href="/donate" id="btn"> <button className="btn donateButton"> DONATE </button>
                        </a>
                        {/* <a href="https://www.aboutamazon.com/news/community/how-to-sign-up-for-amazonsmile" rel="noreferrer" target="_blank" > <img className="amazon-logo" src={ amazon } alt="Amazon Smile Logo"></img></a> */}
                    </div>
                </nav>
            </div>
            :
            <SideDrawer /> )
    );
}

export default Navbar;