import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import './News.css';
import '../../components/focus/Focus.css';
import moment from "moment";

const useStyles = makeStyles( ( theme ) => ( {
    root: {
        position: 'relative',
        top: '1rem',
        color: '#34455E',
        marginBottom: '4rem'
    },

    frontMedia: {
        marginTop: '2rem',
        height: '25rem',
        backgroundSize: '100% 100%',
        position: 'relative',
        '@media screen and (max-width: 1300px)': {
            height: '20rem',
        },
        '@media screen and (max-width: 1024px)': {
            height: '15rem',
        },
        '@media screen and (max-width: 770px)': {
            height: '12rem',
        },
        '@media screen and (max-width: 500px)': {
            height: '10rem',
        },
        boxShadow: "0 2px 10px #34455E",
    },

    body: {
        fontFamily: "Arial, Helvetica, sans-serif",
        marginTop: '1rem',
        color: '#34455E',
        fontSize: 16,
        whiteSpace: "pre-wrap",
        '@media screen and (max-width: 1024px)': {
            fontSize: 14,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 10,
        },
    },
    title: {
        position: "relative",
        textAlign: "start",
        color: "#34455E",
        fontWeight: 600,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 26,
        '@media screen and (max-width: 1024px)': {
            fontSize: 18,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 15,
        },
    },
    author: {
        position: "relative",
        textAlign: "start",
        marginTop: '1rem',
        color: "#34455E",
        fontWeight: 600,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 19,
        '@media screen and (max-width: 1024px)': {
            fontSize: 15,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 12,
        },
    },
    tags: {
        position: "relative",
        display: 'flex',
        width: "1rem",
        maxWidth: "85%",
        textAlign: "center",
        color: "white",
        // backgroundColor: "black",
        padding: "2px 2px",
        fontWeight: 600,
        marginBottom: '1rem',
        boxShadow: "10px 0 0 white",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 16,
        '@media screen and (max-width: 1300px)': {
            fontSize: 14,
            width: "2rem",
        },
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
            width: "2rem",
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 9,
            width: "1.5rem",
        },
    },
    tagsTitle: {
        position: "relative",
        fontWeight: 600,
        width: "2rem",
        color: "#34455E",
        marginRight: "1rem",
        boxShadow: "10px 0 0 white",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 20,
        '@media screen and (max-width: 1024px)': {
            fontSize: 16,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 12,
        },
    },
    tagParent: {
        display: "flex",
        marginTop: "1rem"

    },
    btns: {
        position: "relative",
        left: "70%",
        width: '10rem',
        height: '2rem',
        cursor: 'pointer',
        background: '#769ED2',
        border: '1px solid #91C9FF',
        outline: 'none',
        color: 'white',
        transition: '1s ease-in-out',
        'btns:hover': {
            transition: '1s ease-in-out',
            background: '#769ED2',
            color: '#1D58A8',
        },
        'btns:hover svg': {
            strokeDashoffset: '-480',
        },
        'btns span': {
            color: 'white',
            fontSize: '14px',
            fontWeight: 100,
        },
    },


} ) )

export default function SingleNewsElement ( { id, image, date, author, title, summary, body, link, tags } )
{

    const [ tagColor, setTagColor ] = useState( "#2667ff" )
    const classes = useStyles();
    let tag = "";

    const formattedDate = moment( date ).format( 'MMMM D, YYYY' )
    console.log( "tagColor", tagColor )
    console.log( "tags", tags )

    useEffect( () =>
    {
        function getTagColor ()
        {
            if ( tags != undefined && tags.length > 0 ) {
                for ( let i = 0; i < tags.length; i++ ) {
                    tag = tags[ i ]
                    console.log( 'tags[i]', tags[ i ] )
                    console.log( 'tags.length', tags.length )

                    switch ( tag ) {
                        case 'FREEDOM':
                            setTagColor( "lightblue" )//"#43aa8b" );
                            console.log( "FREEDOM was read" )
                            continue;
                        case 'NEWS':
                            setTagColor( "red" )//"#ef233c" );
                            break;
                        case 'HUMAN-RIGHTS':
                            setTagColor( "lightpurple" )//"#ff499e" );
                            break;
                        case 'HEALTH':
                            setTagColor( "orange" ) //"#2ec4b6" );
                            console.log( "HEALTH was read" )
                            continue;
                        case 'EVENT':
                            setTagColor( "green" )//"#2667ff" );
                            break;
                        case 'POLITICS':
                            setTagColor( "grey" )//"#403d39" );
                            console.log( "POLITICS was read" )
                            continue;
                        default:
                            setTagColor( "pink" )//"#ff9f1c" )
                            break;
                    }
                    return tagColor;
                }

            }
        } getTagColor()
    }, [ tag ] );

    return (
        <div className={ classes.root }>
            <CardMedia
                className={ classes.frontMedia }
                component="div"
                image={ `data:image/jpeg;base64, ${ image }` }
            >

            </CardMedia>

            <div>
                <CardContent >
                    <Typography gutterBottom component="h2" className={ classes.title } >
                        { title }
                    </Typography><hr></hr>
                    <Typography gutterBottom variant="subtitle2" className={ classes.author } >
                        { `By ${ (undefined ? "" : author) } | ${ formattedDate }` }
                    </Typography>
                    <Typography gutterBottom variant="subtitle1" style={{fontSize:'14'}}className={ classes.body } >
                        { body }
                    </Typography>
                    <div className={ classes.tagParent }>
                        <Typography gutterBottom variant="subtitle2" className={ classes.tags }  >
                            { tags?.map( ( tag ) => 
                            {
                                return (
                                    <div style={ { display: 'flex', flexDirection: 'row' } }>
                                        <div style={ { padding: '0 1rem', width: '9rem', backgroundColor: tagColor, marginRight: '1rem', marginBottom: '1rem' } }>
                                            { tag }
                                            { console.log( "after return", tagColor ) }
                                        </div>
                                    </div> )
                            } ) }
                        </Typography>
                    </div>
                    {link && <a href={ link }  target="_blank" className="r-link link text-underlined">read more</a>}
                </CardContent>

            </div>






        </div>
    )
};