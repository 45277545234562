import React, { useMemo, useCallback, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Button, Box, Paper, Typography } from "@material-ui/core";
import axios from 'axios';
import AuthService from '../../services/AuthService';
import Alert from '@material-ui/lab/Alert';
import { useDropzone } from 'react-dropzone';
import NumberFormat from "react-number-format";




const StyledTableCell = withStyles( ( theme ) => ( {
  head: {
    backgroundColor: '#14688D',
    color: theme.palette.common.white,
    fontSize: 18,
  },
  body: {
    fontSize: 16,

  },
} ) )( TableCell );

const StyledTableRow = withStyles( ( theme ) => ( {
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      marginTop: '2rem',
    },
  },
} ) )( TableRow );

function createData ( question, answer )
{
  return { question, answer };
}

const useStyles = makeStyles( ( theme ) => ( {
  table: {
    width: '100%',
    justifyContent: "center",
  },
  reviewButton: {
    marginTop: '1rem',
    marginBottom: '2em',
    background: '#14688D',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#14688D'
    }
  },
  submitButton: {
    height: '3rem',
    marginBottom: '1rem',
    background: '#14688D',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#14688D',
    }
  },
  container: {
    marginTop: '3rem',
    overflow: 'hidden',
  },
  main: {
    marginTop: '2rem',
    justifyContent: 'center',
  },
  hero: {
    ...theme.typography.button,
    backgroundColor: '#14688D',
    padding: theme.spacing( 3 ),
    color: 'white',
    fontSize: '20px',
  },
  scholarshipAlert: {
    position: 'relative',
    marginBottom: '2rem',
    height: '9em',
    color: '#fff',
    textAlign: 'center',
    border: '1px solid #FF9800',
    borderRadius: '5px',
    backgroundColor: '#FF9800'
  },
  twoFiles:{
    color:'red'
  },
  warning: {
    padding: '4px !important',
    position: 'relative',
  },
} ) );

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: 'rem',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#1D58A8',
  borderStyle: 'dashed',
  backgroundColor: '#42aad34d',
  fontSize: '20px',
  height: '10rem',
  textAlign: 'center',
  justifyContent: 'center',
  color: '#5f5858',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: 'green',
  borderWidth: 'solid 5px green'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
function NumberFormatCustom ( props )
{
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      { ...other }
      getInputRef={ inputRef }
      onValueChange={ values =>
      {
        onChange( {
          target: {
            name: props.name,
            value: values.value,
          }
        } );
        console.log( values.value )
      } }
      prefix={ '$ ' }
      thousandSeparator
      suffix={ '.00' }
    />
  );
}
const Review = ( { inputData, navigation } ) =>
{
  const [ isFailure, setIsFailure ] = useState( false );
  const [ isPostSuccess, setIsPostSuccess ] = useState( false );
  const { REACT_APP_FORM_DATA_SUBMISSION_API } = process.env;

  const onDrop = useCallback( acceptedFiles =>
  {
    console.log( 'accepted files==> ', acceptedFiles );
  }, [] );

  const {
    firstName,
    lastName,
    legalStatus,
    dob,
    schoolEmail,
    personalEmail,
    phone,
    address,
    city,
    zipcode,
    state,
    school,
    progress,
    program,
    studentId,
    startYear,
    grade,
    militaryAffiliation,
    rent,
    utilities,
    // internet,
    // electiricityGas,
    // water,
    // car,
    // home,
    // medical,
    // food,
    tuition,
    // textbooks,
    // supplies,
    // creditCard,
    // carPurchase,
    // householdPurchase,
    salary,
    stipend,
    federal,
    incomeFromstate,
    university,
    // family,
    other,
    zelle,
    bankRouting,
    bankAccount,
  } = inputData;

  const basics = [
    createData( 'First Name: ', firstName ),
    createData( 'Last Name: ', lastName ),
    createData( 'Legal Status: ', legalStatus ),
    createData( 'Date of Birth: ', dob ),
    createData( 'School Email Address: ', schoolEmail ),
    createData( 'Personal Email Address: ', personalEmail ),
    createData( 'Phone Number: ', phone ),
    createData( 'Address: ', address ),
    createData( 'City: ', city ),
    createData( 'Zip Code: ', zipcode ),
    createData( 'State: ', state ),
    createData( 'University / College: ', school ),
    createData( 'Progress in the Program: ', progress ),
    createData( 'Program: ', program ),
    createData( 'Student ID: ', studentId ),
    createData( 'Year Started: ', startYear ),
    createData( 'Average Grade of Previous Semester: ', grade ),
    createData( 'Military Affiliation: ', militaryAffiliation ),
  ];
  const expense = [
    createData( 'Rent: ', rent ),
    createData( 'Utilities: ', utilities ),
    // createData( 'Internet: ', internet ),
    // createData( 'Electricity - Gas: ', electiricityGas ),
    // createData( 'Water: ', water ),
    // createData( 'Car Insurance: ', car ),
    // createData( 'Home Insurance: ', home ),
    // createData( 'Medical Insurance: ', medical ),
    // createData( 'Food: ', food ),
    createData( 'Tuition: ', tuition ),
    // createData( 'Textbooks: ', textbooks ),
    // createData( 'Supplies: ', supplies ),
    // createData( 'Credit Card: ', creditCard ),
    // createData( 'Car Purchase: ', carPurchase ),
    // createData( 'Household Purchase: ', householdPurchase ),
  ];
  const income = [
    createData( 'Salary', salary ),
    createData( 'Stipend', stipend ),
    createData( 'Grant - Federal', federal ),
    createData( 'Grant - State', incomeFromstate ),
    createData( 'Grant - University', university ),
    // createData( 'Family: ', family ),
    createData( 'Other: ', other ),
    createData( 'Zelle: ', zelle ),
    createData( 'Routing Number: ', bankRouting ),
    createData( 'Bank Account Number: ', bankAccount ),
  ];
  const classes = useStyles();

  const { go } = navigation;
  const { next } = navigation;

  const postApplication = () =>
  {
    const currentUser = AuthService.getCurrentUser();
    let formData = new FormData();
    let myData = JSON.stringify( inputData )
    formData.append( "file1", acceptedFiles[ 0 ] )
    formData.append( "file2", acceptedFiles[ 1 ] )
    formData.append( "scholarshipDto", myData )

    for ( var value of formData.values() ) {
      console.log( 'form data content==> ', value );
    }
    
    axios.post( REACT_APP_FORM_DATA_SUBMISSION_API, formData, {
        headers: { 'Authorization': currentUser, 'Content-Type': 'multipart/form-data'}
      } )
      .then(function () {
          { next() }
        } )
      .catch( function ( error )
      {
        console.log( "error: ", error )
        setIsFailure( true )
        setIsPostSuccess( false )
      } )
  }
  const keepDisabled = () =>
  {
    if ( acceptedFiles.length < 2 ) {
      return true;
    } else if ( acceptedFiles.length >= 1 && !isPostSuccess ) {
      return false;
    } else {
      return true;
    }
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections
  } = useDropzone( { onDrop, maxFiles: 2, accept: ".pdf", multiple: true } );

  const style = useMemo( () => ( {
    ...baseStyle,

    ...( isDragAccept ? acceptStyle : {} ),
    ...( isDragReject ? rejectStyle : {} ),
    ...( isDragActive ? activeStyle : {} ),
  } ), [
    isDragActive,
    isDragReject,
    isDragAccept
  ] );

  // Since this portion is kind of a repeat with the line# 501 commented out to avoid repeated file names displayed
  // const acceptedFileItems = acceptedFiles.map(( file ) =>
  // {
  //   return (
  //   <ul>
  //     <li key={ file.name }>
  //       { file.name} - SUCCESSFULLY UPLOADED
  //           </li><br></br>
  //   </ul>
  // ) });
  const fileRejectionItems = fileRejections.map( ( { file, errors } ) =>
  {
    return (
      <ul>
        <li key={ file.name }>
          { file.name } - UPLOAD REJECTED
            <li>
            { errors.map( e => <li key={ e.code }>{ e.message }</li> ) }
          </li>
        </li>
      </ul>
    )
  } );

  var nf = new Intl.NumberFormat();



  return (

    <TableContainer className={ classes.container } component={ Paper }>
      <Grid container spacing={ 1 } justify="center" >
        <Grid item xs={ 10 } md={ 4 } >
          <Table className={ classes.table } aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">BASIC INFORMATION</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { basics.map( ( row ) => (
                <StyledTableRow key={ row.name }>
                  <StyledTableCell align="left">{ row.question }</StyledTableCell>
                  <StyledTableCell align="left" type="decimal">{ row.answer }</StyledTableCell>
                </StyledTableRow>
              ) ) }
            </TableBody>
          </Table>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="3vh"
          >
            <Button
              className={ classes.reviewButton }
              type="submit"
              variant="contained"
              onClick={ () => go( "Basic Information" ) }
            >   Edit Basic Information
            </Button>
          </Box>
        </Grid>
        <Grid item xs={ 10 } md={ 4 } >
          <Table className={ classes.table } aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">EXPENDITURE (US DOLLARS)</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { expense.map( ( row ) => (
                <StyledTableRow key={ row.name }>
                  <StyledTableCell align="left">{ row.question }</StyledTableCell>
                  <StyledTableCell align="left" > { nf.format( row.answer ) } </StyledTableCell>
                </StyledTableRow>
              ) ) }
            </TableBody>
          </Table>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="3vh"
          >
            <Button
              className={ classes.reviewButton }
              type="submit"
              variant="contained"
              onClick={ () => go( "Expenditure" ) }
            >   Edit Expenditure
            </Button>
          </Box>
        </Grid>
        <Grid item xs={ 10 } md={ 4 } >
          <Table className={ classes.table } aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">INCOME (US DOLLARS)</StyledTableCell>
                <StyledTableCell align="left"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { income.map( ( row ) => (
                <StyledTableRow key={ row.name }>
                  <StyledTableCell align="left">{ row.question }</StyledTableCell>
                  <StyledTableCell align="left" >{ nf.format( row.answer ) } </StyledTableCell>
                </StyledTableRow>
              ) ) }
            </TableBody>
          </Table>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="3vh"
          >
            <Button
              className={ classes.reviewButton }
              type="submit"
              variant="contained"
              onClick={ () => go( "Income" ) }
            >   Edit Income
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={ 3 } component="main" className={ classes.main }>
        <Grid item xs={ 1 } md={ 2 }></Grid>
        <Grid item xs={ 10 } md={ 8 }>
          <Typography variant="h5" align="center" color="textSecondary" className={ classes.hero }>
            Please answer following questions in your essay <br></br>(1,000 words or less)
        </Typography>
        </Grid>
        <Grid item xs={ 1 } md={ 2 }></Grid>
        <Grid item xs={ 1 } md={ 2 }></Grid>
        <Grid item xs={ 10 } md={ 8 }>
          <Typography variant="h6" align="justify" color="textSecondary" component="p" >
            1.	Tell us about yourself. (Please write a short autobiography including information about your family, work experience, community involvement, hobbies, spare time activities, and what you hope to do in the future.)
        </Typography>
          <Typography variant="h6" align="justify" color="textSecondary" component="p" >
            2.	What are your academic and/or career goals? (Explain what you want to study, and then what kind of career you want to lead afterwards.)
        </Typography>
          <Typography variant="h6" align="justify" color="textSecondary" component="p" >
            3.	How will this scholarship help you? (Please answer this by explaining how the award money will help you in at least one of the following ways: financially, professionally, and/or academically.)
        </Typography>
          <Typography variant="h6" align="justify" color="textSecondary" component="p" >
            4.	Why do you deserve this scholarship? (What differentiates you from other students who apply to our scholarship? Discuss your background, identity, interest, or talent)
        </Typography>
        </Grid>
        <Grid item xs={ 1 } md={ 2 }></Grid>
        <Grid item md={ 4 }></Grid>
        <Grid item md={ 4 } className={ classes.scholarshipAlert }>
          <Grid className={ classes.warning }>
            Please make sure you upload 2 files, <br></br><p className={ classes.twoFiles}>Essay and Tuition documents as PDF</p><br></br><br></br>
            Tuition Document should include your school, student ID, semester and tuition information.
          </Grid>
        </Grid>
        <Grid item md={ 4 }></Grid>
      </Grid>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 1 } md={ 4 }></Grid>
        <Grid item xs={ 10 } md={ 4 }>
          <div { ...getRootProps( { style } ) }>
            <input { ...getInputProps() } />
            { !isDragActive && <p className={ classes.twoFiles}>Upload 2 PDF files simultanously</p> }<br></br><br></br>{ 'Click Here or DRAG & DROP !' }
            { isDragActive && "Drop it here!" }
          </div><br></br>
        </Grid>
        <Grid item xs={ 1 } md={ 4 }></Grid>
        <Grid item xs={ 1 } md={ 4 }></Grid>
        <Grid item xs={ 10 } md={ 4 }>
          <aside>
            <br></br>
            <ul className="list-group mt-2">
              { acceptedFiles.length > 0 && acceptedFiles.map( acceptedFile => (
                <li className="list-group-item list-group-item-success">
                  {acceptedFile.name + ' - UPLOADED' }  {fileRejectionItems }
                </li>
              ) ) }
            </ul>
          </aside>
        </Grid>
        <Grid item xs={ 1 } md={ 4 }></Grid>
        {/* </div> */ }
      </Grid>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 1 } md={ 4 }></Grid>
        <Grid item xs={ 10 } md={ 4 }>
          {/* { ( isPostSuccess ) && ( <Alert variant="filled" severity="success">
            YOU ARE ALL SET! The form and PDF file have been submitted successfully!</Alert> ) } */}
          { isFailure && ( <Alert variant="filled" severity="error">
            You should be signed in and uploaded a pdf file to proceed</Alert> ) }
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop='1rem'
          >
            <Button
              className={ classes.submitButton }
              type="submit"
              variant="contained"
              disabled={ keepDisabled() }
              onClick={ postApplication }
            >   Submit
            </Button>
          </Box>

        </Grid>
        <Grid item xs={ 1 } md={ 4 }></Grid>
      </Grid>
    </TableContainer>
  );
}
export default Review;