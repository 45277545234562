import React, { useState, useEffect } from 'react';
import Aos from 'aos';
import "aos/dist/aos.css";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import scholar from '../../assets/images/Designer-9.png';
import womenTech from '../../assets/images/womenInTech.jpg';
import mental from '../../assets/images/Picture1.png';
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles( ( theme ) => ( {
    focusContainer: {
        display: 'flex',
        position: "relative",
        paddingTop: "2.25%", // 16:9
        padding: '0rem 3rem 3rem 3rem',
        
        '@media screen and (max-width: 1024px)': {
            fontSize: 23,
            paddingTop: '1rem',
            padding: '0rem 0rem 0rem 0rem',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 18,
            padding: '0rem 0rem 0rem 0rem',
            paddingTop: '1rem',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    projectComponent: {
        height: 400,
        marginTop:'10rem',
        boxShadow: "0 5px 12px grey",
    },
    projectComponentSmall: {
        height: 300,
        boxShadow: "0 5px 12px grey",
    },
    projectText: {
        height: 400,
        marginTop:'10rem',
    },
    projectTextSmall: {
        height: 300,
    },
    project: {
        position: "relative",
        paddingTop: '3rem',
        textAlign: 'center',
        color: "#34455E",
        fontWeight: 100,
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 46,
        '@media screen and (max-width: 1024px)': {
            fontSize: 23,
            paddingTop: '1rem',
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 18,
            paddingTop: '1rem',
        },
    },
    projectDetail: {
        position: "relative",
        paddingTop: '1rem',
        paddingLeft: '2rem',
        textAlign: 'left',
        color: "#34455E",
        fontWeight: 100,
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 22,
        '@media screen and (max-width: 1024px)': {
            fontSize: 16,
            textAlign: 'center',
            paddingLeft: '0rem',

        },
        '@media screen and (max-width: 700px)': {
            fontSize: 14,
            paddingLeft: '0rem',
            textAlign: 'center',
        },
    },
    projectDetailWIT: {
        position: "relative",
        paddingTop: '1rem',
        paddingRight: '2rem',
        textAlign: 'right',
        color: "#34455E",
        fontWeight: 100,
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 22,
        '@media screen and (max-width: 1024px)': {
            fontSize: 16,
            textAlign: 'center',
            paddingLeft: '0rem',

        },
        '@media screen and (max-width: 700px)': {
            fontSize: 12,
            paddingLeft: '0rem',
            textAlign: 'center',
        },
    },
    topImageBtn: {
        borderRadius:'0.2rem',
        position: "relative",
        width: '8rem',
        height: '3rem',
        fontSize: 'medium',
        backgroundColor: '#fff',
        border: '1px solid #34455E',
        color: '#34455E',
        boxShadow: '0 0 3px 1px #fff',
        textDecoration: 'none',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
            transition: '0.5s ease-in-out',
            background: '#1D58A8',
            borderStyle: 'none',
            color: '#fff',
            cursor: 'pointer',
        },
    },
    buttonBlock: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
        marginBottom: '4rem'
    }
} ) );


export default function Projects ()
{

    const classes = useStyles();
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    useEffect(() => {
        Aos.init({ duration: 3000});
    }, []);
    return (
        <Grid container spacing={ 1 } alignItems="center" justify="center" className={ classes.focusContainer }>
            <Grid data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} item xs={ 10 } sm={ 10 } md={ 4 } >
                <a href="/scholarship" >
                    <CardMedia
                        className={( windowWidth > 1024 ? classes.projectComponent : classes.projectComponentSmall )}
                        component="div"
                        image={ scholar }
                    >
                    </CardMedia>
                </a>
            </Grid>
            <Grid 
                data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} 
                className={( windowWidth > 1024 ? classes.projectText : null )} 
                item xs={ 10 } sm={ 10 } md={ 6 } 
            >
                <Typography gutterBottom variant="h1" className={ classes.project } >
                    Scholarship Program
                </Typography>
                <Typography gutterBottom variant="subtitle1" className={ classes.projectDetail } >
                    15 students for Spring 2021, 17 students for Fall 2021, 21 students for Spring 2022, 
                    26 students for Fall 2022, 29 students for Spring 2023 and 27 students for Fall 2023 
                    were awarded with the Blue Tulip Scholarship. 
                    <br></br>
                    {/* Apply for the Spring 2024 Scholarship Program Grants now!  */}
                    We'll continue to support exceptional students in the fulfillment of their potentials. 
                    {/* Stay tuned to get the exciting news about Blue Tulip Scholarship Program. */}

                </Typography>
                <div className={ classes.buttonBlock }>
                    <a href="/scholarship" >
                        <button className={ classes.topImageBtn }> LEARN MORE </button>
                    </a>
                </div>
            </Grid>
            { windowWidth >= 1023 ?
                <>
                    <Grid className={classes.projectText} data-aos="fade-right" item xs={ 10 } sm={ 10 } md={ 6 } >
                        <Typography gutterBottom variant="subtitle2" className={ classes.project } >
                            Women in Tech
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" className={ classes.projectDetailWIT } >
                            Blue Tulip Human Rights Center intends to demonstrate that women at risk can be transitioned into tech workforce by providing mentorship, technical and career support.
                        </Typography>
                        <div className={ classes.buttonBlock }>
                            <a href="/women-in-tech" >
                                <button className={ classes.topImageBtn }> LEARN MORE </button>
                            </a>
                        </div>
                    </Grid>
                    <Grid data-aos="fade-left" item xs={ 10 } sm={ 10 } md={ 4 } >
                        <a href="/women-in-tech" >
                            <CardMedia
                                className= {classes.projectComponent}
                                component="div"
                                image={ womenTech }
                            >
                            </CardMedia>
                        </a>
                    </Grid>
                </>
                :
                <>
                    <Grid data-aos="fade-up" item xs={ 10 } sm={ 10 } md={ 4 } >
                        <a href="/women-in-tech" >
                            <CardMedia
                                className={ classes.projectComponentSmall }
                                component="div"
                                image={ womenTech }
                            >
                            </CardMedia>
                        </a>
                    </Grid>
                    <Grid data-aos="fade-up" item xs={ 10 } sm={ 10 } md={ 6 } >
                        <Typography gutterBottom variant="subtitle2" className={ classes.project } >
                            Women in Tech
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" className={ classes.projectDetail } >
                            Blue Tulip Human Rights Center intends to demonstrate that women at risk can be transitioned into tech workforce by providing mentorship, technical and career support.
                        </Typography>
                        <div className={ classes.buttonBlock }>
                            <a href="/women-in-tech" >
                                <button className={ classes.topImageBtn }> LEARN MORE </button>
                            </a>
                        </div>
                    </Grid>
                </> 
            }
            <Grid data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} item xs={ 10 } sm={ 10 } md={ 4 } >
                <a href="/psychological-support">
                    <CardMedia
                        className={( windowWidth > 1024 ? classes.projectComponent : classes.projectComponentSmall )}
                        component="div"
                        image={ mental }
                    >
                    </CardMedia>
                </a>
            </Grid>
            <Grid className={( windowWidth > 1024 ? classes.projectText : null )}  data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} item xs={ 10 } sm={ 10 } md={ 6 } >
                <Typography gutterBottom variant="subtitle2" className={ classes.project } >
                    Psychological Support
                </Typography>
                <Typography gutterBottom variant="subtitle1" className={ classes.projectDetail } >
                    Blue Tulip Human Rights Center corporates with the Psychological Development Institute to provide support to the traumatized forced migrants to facilitate integration.
                </Typography>
                <div className={ classes.buttonBlock }>
                    <a href="/psychological-support" >
                        <button className={ classes.topImageBtn }> LEARN MORE </button>
                    </a>
                </div>
            </Grid>
        </Grid>
    )
}

