import React, { useState, createRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import
{
  Button,
  TextField,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";

const submissionValidationSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your first name"),
  emails: Yup.string().email("Please enter a valid email address").required("Please enter your email address"),
});

const stylesFunc = makeStyles((theme) => ({
  wrapper: {
    marginTop: "3rem",
   //  height: "calc(140vh - 19.0625rem)",
    textAlign: 'start',
    marginBottom: "9rem",
    overflow:'hidden'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  headerInfo: {
   margin:'5rem 0rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: ''

},
info:{
   color: "#34455E",
   textAlign:'center'
},
  MuiSelectIconOutlined :{
    // right:'20% !important'
    display:'flex',
    flexDirection:'row',
    justifyContent:'space-between',
    // backgroundColor:'red',
    alignItems: 'center',
    color:'white !important'
  },
  formLogo: {
    maxWidth: 150,
   //  marginBottom: 20
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
    color: "#34455E"
  },
  submitButton: {
    marginBottom: '1em',
    background: '#14688D',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#14688D'
    }
  }
}));

const ContactUs = () =>
{
  const [ isSuccess, setIsSuccess ] = useState(false);
  const [ isFailure, setIsFailure ] = useState(false);
  const [ isButtonDisabled, setIsButtonDisabled ] = useState(false);
  const [ memberSubmission, setMemberSubmission ] = useState('');
  const { REACT_APP_CONTACT_US_API } = process.env;

  const reRef = createRef();

  const { REACT_APP_MEMBERSHIP_API } = process.env;
  
  const handleChange = (event) =>
  {
    setMemberSubmission({ ...memberSubmission, [ event.target.name ]: (event.target.value) })
  }

  const handleSubmit = async (values) =>
  {
    console.log("values: ", values)
    const g_recaptcha_response = await reRef.current.executeAsync();
    axios.post(REACT_APP_CONTACT_US_API, values, {
      headers: {g_recaptcha_response, 'Content-Type': 'application/json'}})
      .then((response) =>
      {
        console.log("response: ", response)
        setIsSuccess(true)
        setIsButtonDisabled(true)
      })
      .catch((error) =>
      {
        console.log("error message: ", error)
        setIsFailure(true)
        setIsButtonDisabled(true)
      })
  };


  const formStyles = stylesFunc();

  return (
    <Container className={ formStyles.wrapper } maxWidth="md">
      <Grid container spacing={ 6 } >
         <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
            <div className={ formStyles.headerInfo }>
               <i className="fas fa-map-marked-alt fa-5x" style={ { color: "#14688D" } }></i>
               <br></br>
               <Typography className={ formStyles.info } variant="h5">
                  PO Box 4866 Virginia Beach, VA 23454
               </Typography>
            </div>
            <div className={ formStyles.headerInfo }>
               <i className="fas fa-at fa-5x" style={ { color: "#14688D" } }></i>
               <br></br>
               <Typography className={ formStyles.info } variant="h5">
                  blue-tulip@blue-tulip.org
               </Typography>
            </div>
         </Grid>
         <Grid item xs={ 12 } sm={ 6 } md={ 6 }>
            <div className={ formStyles.header }>
               <i className="fas far fa-file-signature fa-2x" style={ { color: "#14688D" } }></i>
               <Typography className={ formStyles.formHeader } variant="h4">
                  Send a Message
               </Typography>
            </div>
            <Formik
               initialValues={ {
                  name: '',
                  emails: '',
                  subject:'',
                  message:''
               } }
               validationSchema={ submissionValidationSchema }
               onSubmit={ handleSubmit }
               onChange={ handleChange }
            >
               {({ handleSubmit, handleChange, values, errors }) => (
                  <form onSubmit={ handleSubmit }>
                     <Grid container spacing={ 3 }>
                        <Grid item xs={ 6 }>
                           <TextField
                              required
                              type="text"
                              name="name"
                              label="Name"
                              variant="outlined"
                              fullWidth
                              value={ values.name }
                              onChange={ handleChange }
                              helperText={ errors.name }
                           />
                        </Grid>
                        <Grid item xs={ 6 }>
                           <TextField
                              required
                              name="emails"
                              label="Email"
                              variant="outlined"
                              fullWidth
                              value={ values.emails }
                              onChange={ handleChange }
                              helperText={ errors.emails }
                           />
                        </Grid>
                        <Grid item xs={ 12 }>
                           <TextField
                              name="subject"
                              label="Subject"
                              variant="outlined"
                              fullWidth
                              value={ values.subject }
                              onChange={ handleChange }
                           />
                        </Grid>
                        <Grid item xs={ 12 }>
                           <TextField
                              id="outlined-multiline-static"
                              name="message"
                              label="Message"
                              multiline
                              rows={10}
                              variant="outlined"
                              fullWidth
                              value={ values.message }
                              onChange={ handleChange }
                           />
                        </Grid>
                        <Grid item xs={ 3 } md={3} sm={3}></Grid>
                        <Grid item xs={ 6 } md={6} sm={6}>
                           <Button
                              className={ formStyles.submitButton }
                              type="submit"
                              variant="contained"
                              color="primary"
                              fullWidth
                              disabled={ isButtonDisabled }
                           > Send Message
                           </Button>
                           { isSuccess && (<Alert variant="filled" severity="success">
                              Your request has been submitted successfully!</Alert>
                           ) }
                           { isFailure && (<Alert variant="filled" severity="error">
                              Oops! Something went wrong, please try again later.</Alert>) }
                        </Grid>
                     </Grid>
                  </form>
               ) }
            </Formik>
         </Grid>
      </Grid>
   </Container>
  );
}

export default ContactUs;
