import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CardMedia } from '@material-ui/core'
import Typography from "@material-ui/core/Typography";
import './Board.css'
import pic1 from './images/Cathy.jpg'
import pic2 from './images/melissasm.jpg'
import pic3 from './images/SaraElizabetDill.jpg'

const useStyles = makeStyles( ( theme ) => ( {
    root: {
        flexGrow: 1,
        // margin: '10em 2em',

    },
    paper: {
        padding: theme.spacing( 10 ),
        textAlign: 'center',
        color: theme.palette.text.secondary,

    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        maxHeight: 300,
        // marginTop: '2em',

        // boxShadow:'1px 1px 5px 3px #424346bd'  
    },

    details: {
        color: '#303133bd',

    },
} ) );

export default function Board ()
{
    const classes = useStyles();
    return (
        <div className={ classes.root }>
            <h1 style={ { color: '#303133bd', textShadow: '1px 1px #fff', marginBottom: '1em' } }>Senior Board of Directors</h1>

            <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Grid container spacing={ 1 } >
                        <Grid item xs={ 4 } sm={ 4 } md={ 4 }>
                            <CardMedia
                                component="img"
                                alt="Focus News"
                                height="280"
                                image={ pic2 }
                            />
                        </Grid>
                        <Grid item xs={ 8 } sm={ 8 } md={ 8 }>
                            <h2 style={ { color: '#303133bd', textShadow: '1px 1px #fff' } }>Melissa Page Deutsch</h2>
                            <Typography
                                gutterBottom
                                component="h2"
                                className={ classes.info }
                                display='block'
                            >

                                <p className={ classes.details }>Melissa Page Deutsch, M.S., CCC-SLP,
                                CPCC, ACC, is a credentialed personal development coach and licensed 
                                speech-language pathologist. . .
                                <a href='/about-us' className={ classes.details }>Read more</a></p>

                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Grid container spacing={ 1 }>
                        <Grid item xs={ 4 } sm={ 4 } md={ 4 }>
                            <CardMedia
                                component="img"
                                alt="Focus News"
                                height="280"
                                image={ pic3 }
                            />
                        </Grid>
                        <Grid item xs={ 8 } sm={ 8 } md={ 8 }>
                            <h2 style={ { color: '#303133bd', textShadow: '1px 1px #fff' } }>Sara Elizabeth Dill</h2>
                            <Typography
                                gutterBottom
                                component="h2"
                                className={ classes.info }
                                display='block'
                            >
                                <p className={ classes.details }>Sara Elizabeth Dill is a partner at
                                Anethum Global in Washington, D.C. Her work includes legal representation,
                                consulting, advocacy, political strategy, and lobbying services for
                                individuals, corporations, and domestic and foreign governments. . .
                                <a href='/about-us' className={ classes.details }>Read more</a></p>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Grid container spacing={ 1 }>
                        <Grid item xs={ 4 } sm={ 4 } md={ 4 }>
                            <CardMedia
                                component="img"
                                alt="Focus News"
                                height="280"
                                image={ pic1 }
                            />
                        </Grid>
                        <Grid item xs={ 8 } sm={ 8 } md={ 8 }>
                            <h2 style={ { color: '#303133bd', textShadow: '1px 1px #fff' } }>Cathy Lewis</h2>
                            <Typography
                                gutterBottom
                                component="h2"
                                className={ classes.info }
                                display='block'
                            >
                                <p className={ classes.details }>Cathy Lewis is one of Hampton Roads’ most
                                veteran media professionals, having worked in commercial and public
                                broadcasting for more than 30 years. She is the founding host of
                                “HearSay with Cathy Lewis” on 89.5 WHRV-FM debuted in 1996. . .

                                <a href='/about-us' className={ classes.details }> Read more</a></p>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}










