import React from "react";
import Board from "../board/Board";

export default function aboutus() {
    return (
        <div>
            <Board />
        </div>
    );
}
