import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import
{
  Button,
  TextField,
  Grid,
  Container,
  Typography,
  Link
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from '../../services/AuthService';
import Alert from '@material-ui/lab/Alert';

const submissionValidationSchema = Yup.object().shape( {
    email: Yup.string().email( "Please enter your email address to reset password" ).required( "Please enter your email address you registered with" ),
  } );

const stylesFunc = makeStyles( ( theme ) => ( {
  wrapper: {
    marginTop: "3rem",
    height: "calc(200vh - 19.0625rem)",
    textAlign: 'center',
    marginBottom: "6rem",
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: ''
  },
  root: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 1,
    },
    '& input:invalid:focus + fieldset': {
      borderColor: 'red',
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      borderColor: 'green',
      padding: '4px !important', // override inline-style
    },
  },
  formLogo: {
    maxWidth: 150,
    marginBottom: 20
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  submitButton: {
    marginBottom: '1em',
    background: '#15678D',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#15678D'
    }
  },
  signUpLink: {
    color: '#15678D',
    justifyContent:'center'
  
  }
} ) );

const ForgotPassword = ( props ) =>
{
  const [ isSuccess, setIsSuccess ] = useState( false );
  const [ isFailure, setIsFailure ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ message, setMessage ] = useState( "" );
  const [ isDisabled, setIsDisabled ] = useState(false)

  const handleCheckAccount = async ( values ) =>
  {
    // const g_recaptcha_response = await reRef.current.executeAsync();
    // console.log("captcha_response: ", g_recaptcha_response)
    AuthService.checkAccount( values.email).then(//g_recaptcha_response ).then(
      ( response ) =>
      {
        setIsSuccess( true )
        setIsDisabled( true )
      },
      ( error ) =>
      {
        const resMessage =
        ( error.response &&
          error.response.data &&
          error.response.data.message ) ||
          error.message ||
          error.toString();
        setIsFailure( true )
        setIsDisabled( true )
      }
    );
    //added below line to remove the preventDefault function
    return false;
  };

  const formStyles = stylesFunc();
  return (

    <Container className={ formStyles.wrapper } maxWidth="sm">
      <div style={ { display: 'flex', justifyContent: 'flex-end' } }>  </div>
      <div className={ formStyles.header }> 
      {/* { UserService.getUserBoard() } */}
        <i className="fas fa-graduation-cap fa-5x" style={ { color: "#15678D" } }></i>
        <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h4">
          Scholarship Program
              </Typography>
        <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h5">
          Forgot your password?
        </Typography>
        <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="subtitle1">
          Enter your email address below to reset your password.
        </Typography>
      </div>
      <Formik
        initialValues={ {
          email: '',
        } }
        validationSchema={ submissionValidationSchema }
        onSubmit={ handleCheckAccount }
      >
        { ( { handleSubmit, handleChange, values, errors, touched } ) => (
          <form onSubmit={ handleSubmit } className={ formStyles.root }>
            <Grid container spacing={ 3 } justify="center">
              <Grid item xs={ 10 }>
                <TextField
                  required
                  name="email"
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  value={ values.email }
                  onChange={ handleChange }
                  error={ touched.email && Boolean( errors.email ) }
                  helperText={ errors.email }
                />
              </Grid>
              <Grid item xs={ 6 } >
                <Button
                  disabled={ isDisabled }
                  className={ formStyles.submitButton }
                  type="submit"
                  variant="contained"
                  fullWidth
                  onSubmit={ handleCheckAccount }
                > Send Link
                </Button>
                  { isSuccess && ( <Alert variant="filled" severity="success">
                    An email to reset your password has been sent to your email address. Please check your inbox.</Alert>
                  ) }
                  { isFailure && ( <Alert variant="filled" severity="error">
                    The email address you typed doesn't match with our records</Alert> ) }
              </Grid>
              <Grid container spacing={3} justify="center">
                <Grid item xs={ 10 } className={ formStyles.signUpLink }>
                  <Link href="/scholarship/signin" >Sign in with another account</Link>
                </Grid>
              </Grid>
            </Grid>
          </form>
        ) }
      </Formik>
    </Container>
  );
}

export default ForgotPassword;