import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import
{
  Button,
  TextField,
  Grid,
  Container,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from '../../services/AuthService';
import Alert from '@material-ui/lab/Alert';
import Signin from './Signin';

const submissionValidationSchema = Yup.object().shape( {
  email: Yup.string().email( "Please enter a valid email address" ).required( "Please enter your email address" ),
  code: Yup.string().required( "Please enter one time password that we have just sent to your phone number you entered" )
} );

const stylesFunc = makeStyles( ( theme ) => ( {
  wrapper: {
    marginTop: "3rem",
    height: "calc(200vh - 19.0625rem)",
    textAlign: 'start',
    marginBottom: "6rem",
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: '',
    marginBottom: '2rem'
  },
  root: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 1,
    },
    '& input:invalid:focus + fieldset': {
      borderColor: 'red',
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      borderColor: 'green',
      padding: '4px !important',
    },
  },
  formLogo: {
    maxWidth: 150,
    marginBottom: 20
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  submitButton: {
    marginBottom: '1em',
    background: '#1D58A8',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#1D58A8'
    }
  },
  signUpLink: {
    color: '#34455E'
  }
} ) );

const OneTimePassword = ( props ) =>
{
  const [ isSuccess, setIsSuccess ] = useState( false );
  const [ isFailure, setIsFailure ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ message, setMessage ] = useState( "" );

  const handleOtp = ( values ) =>
  {
    console.log( "props.email", AuthService.verifyOtp( values.email, values.code ) )
    setMessage( "" );
    setLoading( true );
    AuthService.verifyOtp( values.email, values.code ).then(
      ( response ) =>
      {
        setIsSuccess( true )
        props.history.push( "/scholarship/form" );
        window.location.reload();
        console.log( "response: ", response )

      },
      ( error ) =>
      {
        const resMessage =
          ( error.response &&
            error.response.data &&
            error.response.data.message ) ||
          error.message ||
          error.toString();
        setIsFailure( true )
        setLoading( false );
        setMessage( resMessage );
      }
    );
    setLoading( false );
  };

  const formStyles = stylesFunc();

  return (

    <Container className={ formStyles.wrapper } maxWidth="sm">
      <div style={ { display: 'flex', justifyContent: 'flex-end' } }></div>
      <div className={ formStyles.header }>
        <i className="fas fa-graduation-cap fa-5x" style={ { color: "#1D58A8" } }></i>
        <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h4">
          Scholarship Program
              </Typography>
        <Alert variant="filled" severity="info" >
          Please enter the one time password we've just sent to your mobile phone</Alert>
      </div>
      <Formik
        initialValues={ {
          email:'',
          code: '',
        } }
        validationSchema={ submissionValidationSchema }
        onSubmit={ handleOtp }
      >
        { ( { handleSubmit, handleChange, values, errors, touched } ) => (
          <form onSubmit={ handleSubmit } className={ formStyles.root }>
            <Grid container spacing={ 3 } justify="center">
              <Grid item xs={ 10 }>
                <TextField
                  required
                  name="email"
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  value={ values.email }
                  onChange={ handleChange }
                  error={ !!errors.email }
                  helperText={ errors.email }
                />
              </Grid>
              <Grid item xs={ 10 }>
                <TextField
                  required
                  name="code"
                  type="text"
                  label="One Time Password"
                  inputMode='numeric'
                  autoComplete='one-time-code'
                  variant="outlined"
                  fullWidth
                  value={ values.code }
                  onChange={ handleChange }
                  error={ !!errors.code }
                  helperText={ errors.code }
                />
              </Grid>
              <Grid item xs={ 6 } >
                <Button
                  disabled={ loading }
                  { ...loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) }
                  className={ formStyles.submitButton }
                  type="submit"
                  variant="contained"
                  fullWidth
                  onSubmit={ handleOtp }
                > Verify
                </Button>
                { isSuccess && !isFailure && ( <Alert variant="filled" severity="success">
                  One time Password is accepted</Alert>
                ) }
                { isFailure && !isSuccess && ( <Alert variant="filled" severity="error">
                The email address or one time password you typed in doesn't match with our records</Alert> ) }
              </Grid>
            </Grid>
          </form>
        ) }
      </Formik>
    </Container>
  );
}

export default OneTimePassword;