import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import './Focus.css';
import ReactCardFlip from 'react-card-flip';
import moment from "moment";

const useStyles = makeStyles( ( theme ) => ( {
    details: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 12,
        color: 'white',
        fontWeight: 600,
        textShadow: '10',
        fontFamily: 'Arial, Helvetica, sans-serif'
    },
    frontMedia: {
        minHeight: '13rem',
        height: '18rem',
        backgroundSize: '100% 100%',
        position: 'relative',
        '@media screen and (max-width: 1024px)': {
            height: '10rem',
        },
        boxShadow: "0 5px 10px grey",
    },
    backMedia: {
        minHeight: '13rem',
        height: '18rem',
        backgroundSize: '100% 100%',
        position: 'relative',
        alignContent: 'center',
        '@media screen and (max-width: 1024px)': {
            height: '13rem',
        },
        boxShadow: "0 5px 10px grey",
    },
    summary: {
        position: "absolute",
        display: "inline",
        top: "0%",
        left: "0%",
        right: '0%',
        bottom: '-2%',
        width: "100%",
        textAlign: "start",
        color: "white",
        backgroundColor: 'rgba(32, 31, 32, 0.9)',
        padding: '10% 10%',
        fontWeight: 400,
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: '1.2rem',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.8rem',
            paddingTop: '3rem'
        },
        '@media screen and (max-width: 700px)': {
            fontSize: '0.7rem',
            paddingTop: '3rem'
        },
    },
    title: {
        position: "absolute",
        bottom: "-3%",
        right: '0.001%',
        width: "100%",
        height: '27%',
        textAlign: "start",
        textShadow: '0 0.1px 1px black',
        color: "white",
        backgroundColor: "rgba(32, 31, 32, 0.95)",
        padding: "0.3rem 0.5rem",
        fontWeight: 400,
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: '1rem',
        '@media screen and (max-width: 1024px)': {
            fontSize: '0.8rem',
        },
        '@media screen and (max-width: 700px)': {
            fontSize: '0.7rem',
        },
    },
    date: {
        position: "absolute",
        zIndex: 1,
        display: "inline",
        top: "5%",
        right: "1.6%",
        maxWidth: "50%",
        textAlign: "start",
        color: "black",
        backgroundColor: "white",
        padding: "2px 2px",
        fontWeight: 400,
        boxShadow: "10px 0 0 white",
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 16,
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 10,
        },
    },
    tags: {
        position: "absolute",
        display: "inline",
        top: "5%",
        left: "0%",
        maxWidth: "50%",
        textAlign: "start",
        color: "white",
        backgroundColor: "green",
        padding: "0.2rem 0.4rem",
        fontWeight: 400,
        // boxShadow: "10px 0 0 white",
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 16,
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 9,
        },
    },
    hr: {
        position: 'absolute',
        borderColor: 'white',
        zIndex: 1,
        width: '60%',
        margin: 'auto',
        top: '15%',
        left: '10%'
    },
    author: {
        position: "absolute",
        zIndex: 1,
        display: "inline",
        top: "5%",
        left: "10%",
        maxWidth: "50%",
        textAlign: "start",
        color: "white",
        fontWeight: 600,
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize: 16,
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 9,
        },
    }
} ) )

export default function FocusElement ( { id, image, date, author, title, summary, body, link, tags } )
{

    const [ isFlipped, setIsFlipped ] = useState( false );
    const [ tagColor, setTagColor ] = useState( "green" )
    let tag = "";
    const classes = useStyles();

    const handleClick = () =>
    {
        setIsFlipped( !isFlipped );
    }

    const formattedDate = moment( date ).format( 'MMM DD, YYYY' )

    useEffect( () =>
    {
        function getTagColor ()
        {
            if ( tags !== undefined ) {
                tag = tags[ 0 ]
            }
            switch ( tag ) {
                case 'FREEDOM':
                    setTagColor( "#4d5bd1" );
                    break;
                case 'NEWS':
                    setTagColor( "#ef233c" );
                    break;
                case 'HUMAN RIGHTS':
                    setTagColor( "#4bb98d" );
                    break;
                case 'HEALTH':
                    setTagColor( "#d88d1c" );
                    break;
                case 'EVENT':
                    setTagColor( "#2667ff" );
                    break;
                case 'POLITICS':
                    setTagColor( "#33312e" );
                    break;
                case 'REFUGEE':
                    setTagColor( "#000000" );
                    break;
                default:
                    setTagColor( "#d21cff" )
                    break;
            }
            return tagColor;
        }
        getTagColor()
    }, [ tags ] );


    return (
        <div >
            <ReactCardFlip isFlipped={ isFlipped } flipDirection="horizontal">
                <CardMedia
                    className={ classes.frontMedia }
                    component="div"
                    image={ `data:image/jpeg;base64, ${ image }` }
                    onClick={ handleClick }
                >
                    <div>
                        <CardContent >
                            <Typography gutterBottom component="h6" className={ classes.title } >
                                { title.toUpperCase() }
                            </Typography>
                            <Typography gutterBottom variant="subtitle2" className={ classes.tags } style={ { backgroundColor: tagColor } }>
                                { tags.length > 1 ? tags[ 0 ] : tags }
                            </Typography>
                        </CardContent>
                    </div>
                </CardMedia>
                <CardMedia
                    className={ classes.backMedia }
                    component="div"
                    image={ `data:image/jpeg;base64, ${ image }` }
                >
                    <div>
                        <CardContent >
                            <Typography gutterBottom variant="subtitle2" className={ classes.author } >
                                { `By ${ author } | ${ formattedDate }` }
                            </Typography>
                            <hr className={ classes.hr }></hr>
                            <Typography gutterBottom variant="subtitle2" className={ classes.summary } >
                                { summary }
                            </Typography>
                            <a href={ `/news/${ id }` } >
                                <div className="learnMore">
                                    <div className="centers">
                                        <button className="btns" >
                                            <svg width="6rem" height="1.5rem" viewBox="0 0 180 110" className="border"></svg>
                                            <span>READ MORE</span>
                                        </button>
                                    </div>
                                </div>
                            </a>
                            <div className="back">
                                <div className="centers">
                                    <button className="btns" onClick={ handleClick }>
                                        <svg width="6rem" height="3.5rem" viewBox="0 0 180 110" className="border"></svg>
                                        <span>BACK</span>
                                    </button>
                                </div>
                            </div>
                        </CardContent>
                    </div>
                </CardMedia>
            </ReactCardFlip>
        </div>
    )
};