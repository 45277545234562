import React, { useState, useEffect } from "react";
import Aos from 'aos';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles( ( theme ) => ( {
    root: {
        marginTop:'2rem',
    },
howInfo:{
    marginTop:'5rem',
    marginBottom:'5rem',
    fontSize:'1.6rem',
    display:'flex',
 },

 howInfoSmall:{
     marginTop:'3rem',
     fontSize:'1.2rem',
     display:'flex',
     flexWrap:'wrap',
     color:'#15678D'


  },
  explanation:{
    //  borderLeft:'4px solid #15678D',
     padding:'2rem 2rem',
     height:'100%',
     lineHeight:'2.5rem',
     flex:1,
     color:'#15678D',
  },

  explanationSmall:{
    borderTop:'4px solid #15678D',
    width:'100%',
    margin:'0rem 1.5rem',
    padding:'2rem 2rem',
    height:'100%',
    lineHeight:'2.5rem',
    
 },

 icon:{
     fontSize:'5rem',
     margin:'2rem 2rem',
     color:'#15678D'
 },

 iconSmall:{
     fontSize:'3rem',
     margin:'2rem 2rem',
     color:'#15678D'


 },
} ) );
export default function How ()
{
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    useEffect(() => {
        Aos.init({ duration: 3000});
    }, []);
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        // return () =>
        // {
        //     window.removeEventListener( 'resize', handleWindowResize );
        // }
    }, [] );
    return (
           <div >
            <div className={(windowWidth > 1024 ? classes.howInfo : classes.howInfoSmall)}> 
                <div data-aos="fade-up" className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                    <i className="far fa-bell"></i>
                </div> 
                <div data-aos="fade-up" className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    We increase public awareness for silenced and isolated women and children. As in all human tragedies, 
                    the ultimate victims are the women and children. They are deeply suffering socially and economically.
                </div>
                <div data-aos="fade-up" className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                    <i className="fas fa-hands-helping"></i>
                </div>
                <div data-aos="fade-up" className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    We help the communities in identifying silenced and isolated women and children. 
                    We are a group of volunteers. All donations are 100% used for the mission of Blue Tulip HRC.
                </div>
            </div>
            <div className={(windowWidth > 1024 ? classes.howInfo : classes.howInfoSmall)}>
                <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                    <i className="fas fa-balance-scale"></i>
                </div> 
                <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    Our mission is increasing local and global awareness of sufferings of isolated and silenced women and children, 
                    sending ambassadors to raise social consciousness, fundraising to provide relief to those 
                    suffering from unjust treatment, and collaborating with organizations worldwide
                </div>
                <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                    <i className="fas fa-building"></i>
                </div>
                <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    As in all human tragedies, the ultimate victims are the women and children. 
                    They are deeply suffering socially and economically. Collaboration with other organizations matters.
                    100% of our workforce is based on volunteer.  
                </div>
            </div>   
            <div className={(windowWidth > 1024 ? classes.howInfo : classes.howInfoSmall)}>
                <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                    <i className="fas fa-graduation-cap"></i>
                </div> 
                <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    We provide a scholarship for students resided in the US who cannot access proper education due to arbitrary practices to have
                        education in other countries.
                </div>
                <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                    <i className="fas fa-globe-americas"></i>
                </div>
                <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    We engage with international organizations to monitor and heal the conditions for children in prison.
                </div>
            </div>   
        </div>
    );
}
