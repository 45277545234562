import AppRouter from './router/Router';
// import AppRouterScholarship from './router/RouterScholarship';
import './App.css';

function App() {
  return (
    <AppRouter />
    // <AppRouterScholarship /> 

  );
}

export default App;
