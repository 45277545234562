import React, { useState } from 'react';
import { TakeActionItems } from './TakeActionItems';
import '../Dropdown.css';
import { Link } from 'react-router-dom';

function DropdownTakeAction ()
{
  const [ click, setClick ] = useState( false );

  const handleClick = () => setClick( !click );

  return (
    <div>
      <ul
        onClick={ handleClick }
        className={ click ? 'dropdown-menu clicked' : 'dropdown-menu' }
      >
        { TakeActionItems.map( ( item, index ) =>
        {
          return (
            <li key={ index }>
              <Link
                className={ item.cName }
                to={ item.path }
                // target={ item.title === 'Donate' ? "_blank" : null }
                onClick={ () => setClick( false ) }
              >
                { item.title }
              </Link>
            </li>
          );
        } ) }
      </ul>
    </div>
  );
}

export default DropdownTakeAction;