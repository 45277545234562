import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { SideDrawerData } from './SideDrawerData';
import SubMenu from './SubMenu';
import * as FaIcons from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import './SideDrawer.css'
import onClickOutside from 'react-onclickoutside';
import logo from '../../assets/images/all_logo.svg';

// const Nav = styled.div`
//     background: #114455;
//     height: 50px;
//     display: flex;
//     justify-content: flex-start;
//     align-items:start
// `;

// const NavIcon = styled(Link)`
//   margin-left: 1rem;
//   font-size: 3rem;
//   height: 4rem;
// `;

const SideDrawerNav = styled.nav`
    background: #11668B;
    width: 35vh;
    height: 110vh;
    display: flex;
    justify-content: start;
    position: fixed;
    top: 0;
    left: ${({isOpen}) => (isOpen ? '0' : '-100%')};
    transition: 750ms;
    z-index:1;
`;

const SideDrawerWrap = styled.div`
    width:50%
`;

function SideDrawer( {item} ){
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    SideDrawer.handleClickOutside = () => setIsOpen(false);
    const handleSubMenuClick = () => {
            setIsOpen(false)
    }
    return(
        <div className="navbar-small-container">
            <div className="donate-small" >
                <a href="/donate" id="btn-drawer"> <button className="btn-drawer donateButton"> DONATE 
                    </button>
                </a>
            </div>
            <div>
                <Link className="logoSet" to="/" >
                    <img className="logo-side-drawer" src={ logo } alt="logo"></img>
                </Link>
            </div>
            <div>
                <IconContext.Provider value={{ style: { color: '#11668B' }}} >
                    <SideDrawerNav isOpen={isOpen}>
                        <SideDrawerWrap >
                            {SideDrawerData.map((item, index) => {
                            return <SubMenu item={item} key={index} 
                                onClick={handleSubMenuClick} isOpen={isOpen}/>;
                            })}
                        </SideDrawerWrap>
                    </SideDrawerNav>
                </IconContext.Provider>
            </div>
            <div className="hamburger-small">
                <FaIcons.FaBars onClick={ toggle } className='side-drawer' />
            </div>
    </div>
    )
};
const clickOutsideConfig = {
    handleClickOutside: () => SideDrawer.handleClickOutside,
};

export default onClickOutside(SideDrawer, clickOutsideConfig);