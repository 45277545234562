import React from "react";
import { Formik } from "formik";
import
{
  Button,
  TextField,
  Grid,
  Container,
  Typography,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { submissionValidationSchema } from './Validation'
import { stateNameList } from '../../assets/stateNameList'
import ReactTooltip from 'react-tooltip'
import Modal from './Modal'


const stylesFunc = makeStyles( () => ( {
  root: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 1,
    },
    '& input:invalid:focus + fieldset': {
      borderColor: 'red',
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      borderColor: 'green',
      padding: '4px !important',
    },
  },
  wrapper: {
    marginTop: "3rem",
    height: "calc(200vh - 19.0625rem)",
    textAlign: 'start',
    marginBottom: "9rem",
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: ''

  },
  scholarshipAlert: {
    position: 'relative',
    marginTop:'0.15rem',
    height:'4.5em',
    color: '#FF9800',
    textAlign:'center',
    border: '1px solid #FF9800',
    borderRadius:'5px'
  },
  warning: {
    padding: '4px !important',
    position: 'relative',
  },
  reviewButton: {
    marginTop: '2rem',
    marginBottom: '1em',
    background: '#14688D',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#14688D'
    }
  },
} ) );

const isMilitary = [ { value: 1, opt: 'Yes' }, { value: 2, opt: 'No' } ]

const FormBasics = ( { navigation, inputData, setForm } ) =>
{
  const { firstName, lastName, legalStatus, dob, schoolEmail, personalEmail, phone, address, city, zipcode, state, school, progress, program, studentId, startYear, grade, militaryAffiliation } = inputData;
  const formStyles = stylesFunc();
  const { next } = navigation;

  return (
    <Container className={ formStyles.wrapper } maxWidth="sm">
      <div className={ formStyles.header }>
        <i className="fas fa-user-graduate fa-5x" style={ { color: "#14688D" } }></i>
        <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h6">
          Please reach out in case of any questions <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h5">scholarship@blue-tulip.org</Typography>
        </Typography>
        <Typography>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 10 } className={ formStyles.scholarshipAlert }>
              <Grid className={ formStyles.warning }>
                Please be prepared to upload 2 documents (essay and tuition documents) to complete the application.</Grid>
            </Grid>
            <Grid item xs={ 2 }>
              <Modal />
            </Grid>
          </Grid>
        </Typography><br></br>
      </div>
      <Formik
        initialValues={ {
          firstName: '',
          lastName: '',
          legalStatus: '',
          dob: '',
          schoolEmail: '',
          personalEmail: '',
          phone: '+1',
          address: '',
          city: '',
          zipcode: '',
          state: '',
          school: '',
          progress: '',
          program: '',
          studentId: '',
          startYear: '',
          grade: '',
          militaryAffiliation: ''
        } }
        validationSchema={ submissionValidationSchema }
        onChange={ setForm }
      >
        { ( { handleSubmit, touched } ) => (
          <form onSubmit={ handleSubmit } className={ formStyles.root }>
            <Grid container spacing={ 3 } justify="center">
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="firstName"
                  type="text"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  value={ firstName }
                  onChange={ setForm }
                  error={ touched.firstName && firstName === '' ? true : false }
                  helperText={ firstName === "" ? "Your first name" : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="lastName"
                  type="text"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  value={ lastName }
                  onChange={ setForm }
                  error={ touched.lastName && lastName === '' ? true : false }
                  helperText={ lastName === "" ? "Your last name" : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="legalStatus"
                  type="text"
                  label="Legal Status"
                  variant="outlined"
                  fullWidth
                  value={ legalStatus }
                  onChange={ setForm }
                  error={ touched.legalStatus && dob === '' ? true : false }
                  helperText={ legalStatus === "" ? "Your legal status in the US" : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="dob"
                  label="Date of Birth"
                  type="date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={ { shrink: true, } }
                  value={ dob }
                  onChange={ setForm }
                  error={ touched.dob && dob === '' ? true : false }
                  helperText={ dob === "" ? 'Your date of birth' : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="schoolEmail"
                  type="email"
                  label="School Email Address"
                  variant="outlined"
                  fullWidth
                  value={ schoolEmail }
                  onChange={ setForm }
                  error={ touched.schoolEmail && schoolEmail === '' ? true : false }
                  helperText={ schoolEmail === "" ? 'Your school email address' : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="personalEmail"
                  type="email"
                  label="Personal Email Address"
                  variant="outlined"
                  fullWidth
                  value={ personalEmail }
                  onChange={ setForm }
                  error={ touched.personalEmail && personalEmail === '' ? true : false }
                  helperText={ personalEmail === "" ? 'Your personal email address' : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  name="phone"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={ phone }
                  required
                  onChange={ setForm }
                  error={ touched.phone && phone === '' ? true : false }
                  helperText={ phone === "" ? 'Should be like +17034567890' : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="address"
                  type="text"
                  label="Street Address"
                  variant="outlined"
                  fullWidth
                  value={ address }
                  onChange={ setForm }
                  error={ touched.address ? true : false }
                  helperText={ address === "" ? 'Your street address here in the US' : null }
                />
              </Grid>
              <Grid item xs={ 3 }>
                <TextField
                  required
                  name="city"
                  type="text"
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={ city }
                  onChange={ setForm }
                  error={ touched.city ? true : false }
                />
              </Grid>
              <Grid item xs={ 3 }>
                <TextField
                  required
                  name="zipcode"
                  type="number"
                  label="Zip Code"
                  variant="outlined"
                  fullWidth
                  value={ zipcode }
                  onChange={ setForm }
                  error={ touched.zipcode ? true : false }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  className={ formStyles.root }
                  select
                  required
                  name="state"
                  label="State"
                  variant="outlined"
                  fullWidth
                  value={ state }
                  onChange={ setForm }
                  error={ touched.state && state === '' ? true : false }
                  helperText={ state === "" ? 'The state you are living in the US' : null }
                >
                  { stateNameList.map( ( option ) => (
                    <MenuItem key={ option.value } value={ option.stateName }>
                      {option.stateName }
                    </MenuItem>
                  ) ) }
                </TextField>
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="school"
                  type="text"
                  label="Current University / College"
                  variant="outlined"
                  fullWidth
                  value={ school }
                  onChange={ setForm }
                  error={ touched.school && school === '' ? true : false }
                  helperText={ school === "" ? 'Your current university or college name' : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="program"
                  type="text"
                  label="Current Program"
                  variant="outlined"
                  fullWidth
                  value={ program }
                  onChange={ setForm }
                  error={ touched.program && program === '' ? true : false }
                  helperText={ program === "" ? 'Your current program name' : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="progress"
                  type="text"
                  label="So far progress in the program"
                  variant="outlined"
                  fullWidth
                  value={ progress }
                  onChange={ setForm }
                  error={ touched.progress && progress === '' ? true : false }
                  helperText={ progress === "" ? 'Your progress so far' : null }
                />
              </Grid>
              <Grid item xs={ 6 }>
                <TextField
                  required
                  name="studentId"
                  type="number"
                  label="University ID Number"
                  variant="outlined"
                  fullWidth
                  value={ studentId }
                  onChange={ setForm }
                  error={ touched.studentId && studentId === '' ? true : false }
                  helperText={ studentId === "" ? 'Your university id number' : null }
                />
              </Grid>
              <Grid item xs={ 4 }>
                <TextField
                  required
                  name="startYear"
                  type="number"
                  label="Year Started"
                  variant="outlined"
                  fullWidth
                  value={ startYear }
                  onChange={ setForm }
                  error={ startYear === '' && startYear.touched ? true : false }
                  helperText={ startYear === "" ? 'Your program start date' : null }
                />
              </Grid>
              <Grid item xs={ 4 }>
                <TextField
                  required
                  name="grade"
                  type="text"
                  label="Average Grade"
                  variant="outlined"
                  fullWidth
                  value={ grade }
                  onChange={ setForm }
                  error={ grade === '' && grade.touched ? true : false }
                  helperText={ grade === "" ? 'Average Grade of Previous Semester' : null }
                />
              </Grid>
              <Grid item xs={ 4 } data-tip data-for='global'>
                <TextField
                  className={ formStyles.root }
                  select
                  required
                  name="militaryAffiliation"
                  label="Military Affiliation"
                  variant="outlined"
                  fullWidth
                  value={ militaryAffiliation }
                  onChange={ setForm }
                  error={ touched.militaryAffiliation && militaryAffiliation === '' ? true : false }
                  helperText={ militaryAffiliation === "" ? 'Former military or immediate relative ?' : null }
                  >
                  { isMilitary.map( ( option ) => (
                    <MenuItem key={ option.value } value={ option.opt }>
                      {option.opt }
                    </MenuItem>
                  ) ) }
                </TextField>
                <ReactTooltip id="global" type='info' place='top' effect='solid'>
                    <p>Refers to individuals that have some type of connection to military service.</p>
                    <p>Personal service (prior service in Turkish Armed Forces (TAF)),
                    and family member service (a spouse or parent who has personally served) are included regardless of 
                    length of time, branch, or type. </p>
                    <p>Also all ex-cadets in TAF are considered Military-Affiliated due 
                    to their past service as a student.</p>
                </ReactTooltip>
              </Grid>
              <Grid item xs={ 4 }>
                <Button
                  className={ formStyles.reviewButton }
                  type="submit"
                  variant="contained"
                  fullWidth
                  onSubmit={ setForm }
                  onClick={
                    firstName === '' ||
                    lastName === '' ||
                    legalStatus === '' ||
                    dob === '' ||
                    schoolEmail === '' ||
                    personalEmail === '' ||
                    phone === '' ||
                    address === '' ||
                    city === '' ||
                    zipcode === '' ||
                    state === '' ||
                    school === '' ||
                    progress === '' ||
                    program === '' ||
                    studentId === '' ||
                    startYear === '' ||
                    grade === '' ||
                    militaryAffiliation === ''
                    ? null : next }
                > Next
                </Button>
              </Grid>
            </Grid>
          </form> ) }
      </Formik>
    </Container>
  );
}
export default FormBasics;
