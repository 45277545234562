import React, { useState, useEffect } from "react";
import '../testimonials/Testimonials.css'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CardMedia } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import pic1 from '../../../assets/images/melissasm.jpg'
import pic2 from '../../../assets/images/sara.jpeg'
import pic3 from '../../../assets/images/Cathy.jpg'
import pic4 from '../../../assets/images/Jan_Headshot.jpg'
const useStyles = makeStyles( ( theme ) => ( {
    root: {
        marginTop:'2rem',
    },
    media: {
        boxShadow: "0 5px 10px grey",
        marginLeft:'3.5rem',
        marginTop:'2rem'
    },
    who: {
        margin: '3em 2em',
        color: '#15678D',
    },
    paper: {
        padding: theme.spacing( 10 ),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        // height: '16rem',
        alignItems:'center',
        justifyContent:'space-between'
    },
    
    details: {
        color: '#15678D',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'1.2rem',
        textDecoration: 'none',
    },

    detailsSmall:{
        color: '#34455E',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'0.8rem',
        textDecoration: 'none',
    },

    whoInfoTop:{
        margin:'2rem 3rem -1rem 3rem',
        fontSize:'1rem',
        display:'flex',
     },

    whoInfo:{
       margin:'1rem 3rem',
       fontSize:'1rem',
       display:'flex',
    },

    whoInfoSmall:{
        marginTop:'2rem',
        fontSize:'1.2rem',
        display:'flex',
        flexWrap:'wrap',
        textAlign:'center',
        margin:'1rem'
     },


     explanation:{
        borderLeft:'4px solid #15678D',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
        flex:1,
     color:'#15678D'

     },
     explanationSmall:{
        borderTop:'4px solid #15678D',
        width:'100%',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
     },

    icon:{
        fontSize:'5rem',
        margin:'2rem 2rem',
    },

    iconSmall:{
        fontSize:'3rem',
        margin:'2rem 2rem',

    },
    whoBtn: {
        // position: "relative",
        width: '8rem',
        height: '3rem',
        marginTop:'5rem',
        fontSize: 'medium',
        backgroundColor: '#fff',
        border: '1px solid #15678D',
        color: '#15678D',
        boxShadow: '0 0 3px 1px #fff',
        textDecoration: 'none',
        borderRadius:'0.2rem',
            '&:hover': {
                transition: '0.7s ease-in-out',
                background: '#15678D',
                borderStyle: 'none',
                color: '#fff',
                cursor: 'pointer',
            },
    },

    whoName:{
        color:'#15678D',
        fontSize:'1.2rem',
        lineHeight:'1.8rem',
    },

    whoNameBottom:{
        color:'#15678D',
        fontSize:'1.2rem',
        lineHeight:'1.8rem',
        marginTop:'5rem'
    },
    melissaName:{
        fontSize:'1rem'
    }

} ) );

export default function WhoMelissa ()
{
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );
    return (
        <div className={ classes.who }>
            <Grid container spacing={ 3 } >
                <Grid item xs={ 12 } sm={ 12 } md={ 12 } >
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 8 } sm={ 6 } md={ 2 }>
                                <CardMedia
                                    component="img"
                                    alt="Focus News"
                                    height="300"
                                    className={ classes.media }
                                    image={ pic2 }
                                />
                            </Grid>
                            <Grid item xs={ 12 } sm={10 } md={ 10 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                    <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>
                                        Sara Elizabeth Dill is a partner at Anethum Global in Washington, D.C. Her work includes legal representation, consulting, advocacy, political strategy, and lobbying services for individuals, corporations, and domestic and foreign governments. She also provides services to non-profits and non-governmental associations, political candidates, and the media. Her primary areas of focus include international law, human rights, foreign affairs, cross-border finance, national security, white collar crime, and immigration.
                                    </span>
                                    <br></br>
                                    <span className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}>
                                        Prior to this, Sara served as the Director of Criminal Justice Standards and Policy for the American Bar Association. In this role, she oversaw the development and revision of the standards for domestic and international criminal justice systems, formulated policy on criminal justice and international human rights issues, and engaged with members of Congress in the development of legislation. She also served as chief counsel of the Criminal Justice Supreme Court Amicus Brief Project, which included drafting amicus briefs based on ABA policy and standards and authoring Supreme Court Case Summaries of decisions.
                                    </span>
                                    
                                    </div>
                                    
                                </div>
                            </Grid>
                            <Grid item xs={ 12 } sm={10 } md={ 12 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                    <span className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}>
                                        For a large part of her career, Sara Elizabeth was a founding partner in the London, Miami and Chicago offices of the Law Offices of Sara Elizabeth Dill. Her practice focused on criminal defense, international law, human rights, and immigration. She represented individuals and corporations in state and federal trial and appellate courts, international tribunals and before government agencies.  Her practice also included representation in pre-indictment and internal investigations involving cross-border financial transactions, securities fraud, terrorism, and OFAC and ITAR compliance. Prior to that, she was a trial and appellate lawyer for a private law firm, a non-profit immigration agency, and the Miami-Dade Public Defender’s Office.
                                    </span>
                                    <span className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}>
                                        Sara was previously the co-chair of the American Bar Association Criminal Justice Section’s Immigration Committee and as a Commissioner for the ABA Commission on Immigration.  She served multiple terms on the ABA Criminal Justice Council as well as serving as vice-chair for the International Law Section’s International Criminal Law, National Security, and Corporate Social Responsibility committees. In these roles, Sara drafted policy on criminal justice and immigration issues, including a comprehensive report on immigration reform in February 2010 and the Civil Immigration Detention Standards.
                                    </span>
                                    <span className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}>
                                        Sara has published numerous articles in recent years on international law, criminal defense, immigration, terrorism, human trafficking, and free speech. Sara has spoken at international and national legal conferences regarding human rights, immigration and criminal law, human trafficking, refugee determination, and national security/counterterrorism matters. In January 2016, Sara presented her publication, A Bleak House in Cuba: A Call for Reform and the Closure of Guantanamo, at the Legal Challenges of Modern Warfare Conference at The Hague. In 2014, Sara served as a rapporteur to the UN Commission Against Torture.
                                    </span>
                                    <span className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}>
                                        Sara also serves as a regular voice on national and international media outlets, including CNN, Al Jazeera, ABC News, NBC News, NPR, BBC, LBC, and other major print, radio, and television outlets. Her human rights work was also covered in Vanity Fair and SELF magazines.
                                    </span>
                                    <span className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}>
                                        Sara had an extensive pro bono practice, representing victims of human trafficking and domestic violence, as well as successfully litigating asylum and convention against torture cases. She also successfully defended three individuals accused of war crimes. Most recently Sara was a founding member of the Dulles Justice Coalition, an organization of lawyers quickly mobilized to provide legal assistance in response to the Executive Orders and Muslim Ban. Sara continues to serve on this board, mobilizing lawyers to provide rapid responses to legal crises.
                                    </span>
                                    <span className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}>
                                        Sara has an LLM in international human rights with highest honors from Northwestern Law School. Prior to that, Sara attended Marquette University, where she majored in political science, with an emphasis in economics, criminology, and international affairs.  She then continued her legal education at Marquette Law School.
                                    </span>
                                    </div>
                                    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 12}>
                    <h1 style={ { color: '#15678D', textShadow: '1px 1px #fff', textAlign:'center', marginTop: '2rem', marginBottom: '1em' } }>Other Senior Board of Directors</h1>
                </Grid>
                    <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                            <Card className={ classes.root }>
                                <Grid container direction='row' spacing={ 3 } >
                                    <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                        <CardMedia
                                            component="img"
                                            alt="Focus News"
                                            height="300"
                                            image={ pic1 }
                                        />
                                    </Grid>
                                    <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                        <div className={classes.info}>
                                            <div className={classes.whoNameBottom}> 
                                                <b className={ classes.melissaName }>Melissa Page Deutsch</b>
                                            </div>
                                            <a href='/who-melissa'>
                                                <button className={classes.whoBtn}>
                                                    About Melissa
                                                </button>
                                            </a>    
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>
                    </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Focus News"
                                    height="300"
                                    image={ pic3 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                            <div className={classes.info}>
                                <div className={classes.whoNameBottom}> 
                                    <b>Cathy Lewis</b>
                                </div >
                                    <a href='/who-cathy'>
                                        <button className={classes.whoBtn}>
                                            About Cathy
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Jan's picture"
                                    height="300"
                                    image={ pic4 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <div className={classes.info}>
                                    <div className={classes.whoNameBottom}> 
                                        <b>Jan Pederson</b>
                                    </div >
                                    <a href='/who-jan'>
                                        <button className={classes.whoBtn}>
                                            About Jan
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

            </Grid>
        </div>)}