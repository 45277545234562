const Skillset = [
    { value: 1, skill: 'Strong communication' },
    { value: 2, skill: 'Teamwork' },
    { value: 3, skill: 'Problem solver' },
    { value: 4, skill: 'Ability to interact with others' },
    { value: 5, skill: 'Ability to work under pressure' },
    { value: 6, skill: 'IT Geek' },
    { value: 7, skill: 'Coordination' },
    { value: 8, skill: 'Mentoring' },
    { value: 9, skill: 'Negotiation' },
    { value: 10, skill: 'Linguistic' },
    { value: 11, skill: 'Flexibility' },
    { value: 12, skill: 'Critical thinking' },

]
export { Skillset };