import React, { useState, useEffect } from "react";
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import FocusElement from "./FocusElement"
import { makeStyles } from "@material-ui/core/styles";
import './Focus.css'
import LoadSpinner from '../LoadSpinner/LoadSpinner';

const useStyles = makeStyles( ( theme ) => ( {
    focusContainer: {
        paddingTop: "4.25%", 
    },
    focusDivisionLarge: {margin:'0 22%'},
    focusDivision2200: {margin:'0 20%'},
    focusDivision2000: {margin:'0 16%'},
    focusDivision1800: {margin:'0 11%'},
    focusDivision1700: {margin:'0 10%'},
    focusDivision1600: {margin:'0 8%'},
    focusDivision1550: {margin:'0 6%'},
    focusDivision1500: {margin:'0 5%'},
    focusDivision1450: {margin:'0 4%'},
    focusDivision1400: {margin:'0 2%'},
    focusDivision1024: {margin:'0 15%'},
} ) );

export default function Focus ()
{
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    useEffect( () => {
        const handleWindowResize = () => {
            setWindowWidth( window.innerWidth );
        };
        window.addEventListener( 'resize', handleWindowResize );
    }, [] );
    const [isLoading, setIsLoading] = useState(false);
    const [ newsList, setNewsList ] = useState( [] );
    const [ newsImage, setNewsImage ] = useState( "" );
    const { REACT_APP_NEWS_API_BASE_URL } = process.env;
    const classes = useStyles();
    let focusLimit = 0;
    useEffect( () => {
        const fetchData = async () => {
            setIsLoading(true)
            const respGlobal = await axios.get( REACT_APP_NEWS_API_BASE_URL + 'news' )
            setNewsList( respGlobal.data )
            const respImage = await axios.get( REACT_APP_NEWS_API_BASE_URL + 'news', { responseType: 'arraybuffer' } )
            .then( respImage => Buffer.from( respImage.data, 'binary' ).toString( 'base64' ) )
            setNewsImage( respImage )
            setIsLoading(false)
        };
        fetchData();
    }, [] );
    return ( 
    <div className={(windowWidth > 2400 ? classes.focusDivisionLarge : 
         windowWidth > 2200 ? classes.focusDivision2200 : 
         windowWidth > 2000 ? classes.focusDivision2000 :
         windowWidth > 1800 ? classes.focusDivision1800 :
         windowWidth > 1700 ? classes.focusDivision1700 :
         windowWidth > 1600 ? classes.focusDivision1600 :
         windowWidth > 1550 ? classes.focusDivision1550 :
         windowWidth > 1500 ? classes.focusDivision1500 :
         windowWidth > 1450 ? classes.focusDivision1450 :
         windowWidth > 1400 ? classes.focusDivision1400 :
         windowWidth < 1024 && windowWidth > 600 ? classes.focusDivision1024 :
         null)}>
        { isLoading ? <LoadSpinner/> :
            <Grid container spacing={ 10 } alignItems="center" justify="center" className={ classes.focusContainer }>
                { newsList?.map( ( news ) => {
                    focusLimit += 1;
                    if (focusLimit > 4) {
                        return null;
                    }
                    return (
                        <Grid item xs={ 10 } sm={ 8 } md={ 5 }> 
                            <FocusElement
                                id={ news?.id }
                                image={ news?.image }
                                date={ news?.date }
                                author={ news?.author }
                                title={ news?.title }
                                summary={ news?.summary }
                                body={ news?.body }
                                link={ news?.link }
                                tags={ news?.tags }
                            />
                        </Grid>
                ) } ) }
            </Grid>
        }
    </div>
    );
}
