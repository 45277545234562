import React, { useState, useEffect } from "react";
import '../testimonials/Testimonials.css'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CardMedia } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import pic1 from '../../../assets/images/melissasm.jpg'
import pic2 from '../../../assets/images/sara.jpeg'
import pic3 from '../../../assets/images/Cathy.jpg'
import pic4 from '../../../assets/images/Jan_Headshot.jpg'

const useStyles = makeStyles( ( theme ) => ( {
    root: {
        marginTop:'2rem',
    },
    media: {
        boxShadow: "0 5px 10px grey",
        marginLeft:'3.5rem',
        marginTop:'2rem'
    },
    who: {
        margin: '3em 2em',
        color: '#15678D',
    },
    paper: {
        padding: theme.spacing( 10 ),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        // height: '16rem',
        alignItems:'center',
        justifyContent:'space-between'
    },
    
    details: {
        color: '#15678D',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'1.2rem',
        textDecoration: 'none',
    },

    detailsSmall:{
        color: '#34455E',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'0.8rem',
        textDecoration: 'none',
    },
    whoInfoTop:{
        margin:'2rem 3rem -1rem 3rem',
        fontSize:'1rem',
        display:'flex',
     },
    whoInfo:{
       marginTop:'5rem',
       marginBottom:'5rem',
       fontSize:'1.6rem',
       display:'flex',
    },

    whoInfoSmall:{
        marginTop:'2rem',
        fontSize:'1.2rem',
        display:'flex',
        flexWrap:'wrap',
        textAlign:'center',
        margin:'1rem'

     },
     explanation:{
        borderLeft:'4px solid #15678D',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
        flex:1,
     color:'#15678D'

     },
     explanationSmall:{
        borderTop:'4px solid #15678D',
        width:'100%',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
        textAlign:'center'

     },

    icon:{
        fontSize:'5rem',
        margin:'2rem 2rem',
    },

    iconSmall:{
        fontSize:'3rem',
        margin:'2rem 2rem',

    },
    whoBtn: {
        // position: "relative",
        width: '8rem',
        height: '3rem',
        marginTop:'5rem',
        fontSize: 'medium',
        backgroundColor: '#fff',
        border: '1px solid #15678D',
        color: '#15678D',
        boxShadow: '0 0 3px 1px #fff',
        textDecoration: 'none',
        borderRadius:'0.2rem',
            '&:hover': {
                transition: '0.7s ease-in-out',
                background: '#15678D',
                borderStyle: 'none',
                color: '#fff',
                cursor: 'pointer',
            },
    },

    whoName:{
        color:'#15678D',
        fontSize:'1.2rem',
        lineHeight:'1.8rem',
        paddingBottom:'1rem'
    },

    whoNameBottom:{
        color:'#15678D',
        fontSize:'1.2rem',
        lineHeight:'1.8rem',
        marginTop:'5rem'
    },
    melissaName:{
        fontSize:'1rem'
    }


} ) );

export default function WhoJan ()
{
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );
    return (
        <div className={ classes.who }>
            <Grid container spacing={ 3 } >
                <Grid item xs={ 12 } sm={ 12 } md={ 12 } >
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 8 } sm={ 6 } md={ 2 }>
                                <CardMedia
                                    component="img"
                                    alt="Jan's picture"
                                    height="300"
                                    className={ classes.media }
                                    image={ pic4 }
                                />
                            </Grid>
                            <Grid item xs={ 12 } sm={10 } md={ 10 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                        <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>
                                        Jan Pederson is a partner at Wright, Constable & Skeen in the firm’s Immigration Law group. 
                                        She has been recognized by The Washington Post as one of “seven leading lawyers” in Washington, D.C., 
                                        due to her dedication to the practice of immigration and national law for over twenty years. 
                                        Her Immigration Law specialties include: Foreign Medical Graduates, J-1 Physicians, 0-1 Visas, 
                                        Consular Practice, National Interest Waivers, and Investor Visas. Ms. Pederson is masterful at 
                                        resolving complex immigration issues and she has successfully represented thousands of clients, 
                                        including renowned physicians, Fortune 500 companies, television networks, entertainers, and healthcare 
                                        providers. Ms. Pederson is fluent in English and Spanish. In 1997, Jan was honored to receive the most 
                                        prestigious national award for excellence in the advancement of immigration law, The Edith Lowenstein Award.
                                       </span>
                                       <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>
                                        Ms. Pederson is a leading advocate for the rights of J-1 Physicians in the United States and has been key to the 
                                        passage of legislation to benefit them. She is the publisher of J-1 Physician News and lectures nationally and 
                                        internationally to foreign physicians. Ms. Pederson seeks solutions for her clients beyond the immigration laws 
                                        and does not hesitate to seek assistance from the White House and Congress if necessary. She has been a key advocate 
                                        on the restoration of the National Interest Waivers for J-1 Physicians and in increasing the Conrad State 20 J-1 
                                        Waiver Program within the Conrad State 30 Program.</span>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={ 12 } sm={10 } md={ 12 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                    
                                        <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>
                                        Ms. Pederson served as president of the Washington, D.C. Chapter of the American Immigration Lawyers Association (AILA); 
                                        and as an elected director of the national Board of Governors of AILA for 18 years. She has served as a chairperson of 
                                        the Physicians Task Force of AILA for many years and has also served as chairperson of the AILA Visa Office Liaison 
                                        Committee and Refugee Committee.</span>
                                        <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>
                                        The national media has recognized Ms. Pederson’s expertise in immigration law and called upon her to comment on 
                                        important issues and legislation. Ms. Pederson has been interviewed by CNN, ABC News, The Washington Post, 
                                        The New York Times, The Washington Times and The Legal Times of Washington, D.C., to name a few.</span> </div>
                                    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 12}>
                    <h1 style={ { color: '#15678D', textShadow: '1px 1px #fff', textAlign:'center', marginTop: '2rem', marginBottom: '1em' } }>Other Senior Board of Directors</h1>
                </Grid>
                    <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                            <Card className={ classes.root }>
                                <Grid container direction='row' spacing={ 3 } >
                                    <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                        <CardMedia
                                            component="img"
                                            alt="Focus News"
                                            height="300"
                                            image={ pic1 }
                                        />
                                    </Grid>
                                    <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                        <div className={classes.info}>
                                            <div className={classes.whoNameBottom}> 
                                                <b className={classes.melissaName}>Melissa Page Deutsch</b>
                                            </div>
                                            <a href='/who-melissa'>
                                                <button className={classes.whoBtn}>
                                                    About Melissa
                                                </button>
                                            </a>    
                                        </div>
                                    </Grid>
                                </Grid>
                            </Card>
                    </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Sara's picture"
                                    height="300"
                                    image={ pic2 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                            <div className={classes.info}>
                                <div className={classes.whoNameBottom}> 
                                    <b>Sara Elizabeth Dill</b>
                                </div >
                                    <a href='/who-sara'>
                                        <button className={classes.whoBtn}>
                                            About Sara
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Focus News"
                                    height="300"
                                    image={ pic3 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                            <div className={classes.info}>
                                <div className={classes.whoNameBottom}> 
                                    <b>Cathy Lewis</b>
                                </div >
                                    <a href='/who-cathy'>
                                        <button className={classes.whoBtn}>
                                            About Cathy
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

            </Grid>
        </div>)}