import React from 'react'

 
const Letters = () => {
    return (
       <div>
       <h1 style={{backgroundColor: 'orange', color:'white', textAlign:'center', padding :50}}>IN PROGRESS</h1>

       <h2>Letters</h2>
            <div>Support campaign towards temporary release of women, children and those in risk group from Turkish prisons.
            Dear Sir/Madam,
            
            I am contacting you for an urgent issue that could save the lives of tens of thousands in the upcoming weeks. COVID-19 global outbreak has shown that virus spreads extremely fast among those that are in close proximity. As such many countries has taken measures to assure minimum contact among individuals, with recent study by UK Imperial college showing that up to eighty-fold increase can happen in casualties if proper measures are not taken.
            
            Overcrowded prisons are a ticking time bomb for the virus and can lead to widespread deaths. The concentration of people living in tight quarters combined with already dismal health conditions constitutes extreme risk for the incarcerated. Italy, China, Iran, Bahrain and some US states have employed partial releasing of prisoners in order to combat with the virus while other countries such as UK has kept it under consideration.
            
            In this gloomy reality, conditions at Turkish prisons can lead to catastrophic results. Due to Erdogan regimes crackdown on opposition, the prison population has increased 43%, in the last 4 year alone, leading to dismal health conditions and overcrowding. Unfortunately, COVID-19 infections have already been detected in Edirne and Balikesir prisons and it is only a matter of time for others. It is clear that the health of the incarcerated is under great risk.
            
            Thirty-Two Bar Associations in Turkey, led by Ankara Bar, have already pointed out the danger and called for release of prisoners.
            
            Please support this campaign towards temporary release of at least women, children and those in risk group from Turkish prisons. You can contact to Minister of Justice from the email address: info@adalet.gov.tr and write a letter supporting temporary release of prisoners in order to avoid a tragedy irrespective of the crime.
            
            Thank you for your support for this issue that literally can make the difference between life and death…
            
            Sincerely
            
            Email Addresses :
            nationalinstitutions@ohchr.org;
            ankara.office@coe.int;
            rebeccaharms.ep@googlemail.com;
            info@freedomhouse.org;
            ti@transparency.org;
            aosterhaus@transparency.org;
            cabel@transparency.org;
            akoch@transparency.org;
            paris@hrw.org;
            info@dgvn.de;
            info@dgvn-hessen.de;
            info@grundrechtekomitee.de;
            bgs@jdjl.org;
            vorstand@ilmr.org;
            hcagermany@aol.com;
            info@amnesty.de;
            SRindependenceJL@ohchr.org;
            cab-hahn-contacts@ec.europa.eu;
            cepej@coe.int;
            stephane.leyenberger@coe.int;
            ccpe@coe.int;
            ccje@coe.int;
            contact@amue-ejpa.org;
            cab-hahn-contacts@ec.europa.eu;
            venice@coe.int;
            tatiana.mychelova@coe.int;
            panos.kakaviatos@coe.int;
            thomas.markert@coe.int;
            Judicial.Independence.wp@gmail.com;
            contactus@amnesty.org;
            om@iap-association.org;
            sg@iap-association.org;
            elizabeth.howe@cps.gsi.gov.uk;
            ED@iap-association.org;
            secretariat@iaj-uim.org;
            commissioner@coe.int;
            hasan.bermek@coe.int;
            bjorn.berge@coe.int;
            leyla.kayacik@coe.int;
            info@humanrightsfirst.org;
            info@icj.org;
            international.secretariat.brussels@hrwf.org;
            information@fra.europa.eu;
            media@fra.europa.eu;
            info@aedh.eu;
            cptdoc@coe.int;
            omct@omct.org;
            omcteurope@omct.org;
            InfoDesk@ohchr.org;
            unvfvt@ohchr.org;
            nationalinstitutions@ohchr.org;
            thomas.demaiziere@bundestag.de;
            poststelle@mdi.rlp.de;
            poststelle@mffjiv.rlp.de;
            poststelle@jm.rlp.de;
            smcCammon@npr.org;</div>
           
       </div>
    );
}
 
export default Letters;