import React, { useEffect, useState, createRef  } from 'react';
import { Grid, Button, TextField, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import Alert from '@material-ui/lab/Alert';
import { Formik } from "formik";
import axios from 'axios';
import * as Yup from "yup";

const submissionValidationSchema = Yup.object().shape({
    emails: Yup.string().email("Please enter a valid email address").required("Please enter your email address"),
  });

const useStyles = makeStyles( ( theme ) => ( {
    subscribeButton: {
        backgroundColor: '#16668D',
        color: '#fff',
        marginLeft: '1rem',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#16668D',
            border: '1px solid #16668D',
        }
    },
    subscribeText: {
        fontFamily: 'Arial, Helvetica, sans-serif',
    },
    subscribeTitle: {
        fontFamily: 'Arial, Helvetica, sans-serif',
        color: '#16668D'
    },
    card: {
        // backgroundColor:'red',
        borderRadius: '0.3rem',
        display: 'flex'
    }
} ) )
const Newsletter = () =>
{
    const [ isSuccess, setIsSuccess ] = useState( false );
    const [ isFailure, setIsFailure ] = useState( false );
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    const reRef = createRef();
    const { REACT_APP_NEWSLETTER_API } = process.env;
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );


        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );

    const handleSubmit = async ( values ) =>
    {
        console.log( "values: ", values )
        const g_recaptcha_response = await reRef.current.executeAsync();
        axios.post( REACT_APP_NEWSLETTER_API, values, {
            headers: { g_recaptcha_response, 'Content-Type': 'application/json' }
        } )
            .then( ( response ) =>
            {
                console.log( "response: ", response )
                setIsSuccess( true )
            } )
            .catch( ( error ) =>
            {
                console.log( "error message: ", error )
                setIsFailure( true )
            } )
    };
    return (

                <Grid container alignItems="center" justify="center" >
                    <Grid item xs={ 11 } sm={ 11 } md={ 11 } className={ classes.subscribeTitle }>
                        <Typography>Subscribe to our Newsletter</Typography>
                    </Grid>
                    {/* <Formik
                        initialValues={ {
                            email: ''
                        } }
                        validationSchema={ submissionValidationSchema }
                        onSubmit={ handleSubmit }
                        onChange={ handleChange }
                    > */}
                        <br></br>
                        <br></br>
                        { ( { handleSubmit, handleChange, values } ) => (
                            <form onSubmit={ handleSubmit }>
                                <Grid item xs={ 7 } sm={ 7 } md={ 7 } className={ classes.subscribeText }>
                                    <TextField
                                        required
                                        name="email"
                                        type="email"
                                        label="Enter Your Email"
                                        variant="outlined"
                                        fullWidth
                                        value={ values.emails }
                                        onChange={ handleChange }
                                    />
                                </Grid>
                                <Grid item xs={ 4 } sm={ 4 } md={ 4 } >
                                    <Button className={ classes.subscribeButton } type="submit" >
                                        Subscribe
                                    </Button>
                                    { isSuccess && ( <Alert variant="filled" severity="success">
                                        Your email address has been added to our list successfully! </Alert>
                                    ) }
                                    { isFailure && ( <Alert variant="filled" severity="error">
                                        Oops! Something went wrong, please try again later.</Alert> ) }
                                </Grid>
                            </form> 
                        ) }
                    {/* </Formik> */}
                </Grid>
  
    )
}
export default Newsletter;