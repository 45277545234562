import React, { useState, createRef } from "react";
import AuthService from '../../services/AuthService';
import { Formik } from "formik";
import * as Yup from "yup";
import
{
  Button,
  TextField,
  Grid,
  Container,
  Typography,
  Link
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import ReCAPTCHA from 'react-google-recaptcha';

let phoneRegExp = new RegExp( '^\\+[1-9]{1}[0-9]{1,12}$' )
let passwordRegExp = new RegExp( "[ 0 - 9A - Za - z ] *" )

// Yup.string().matches(regExp, {message: 'Must be exactly 5 numbers', excludeEmptyString: true})
const submissionValidationSchema = Yup.object().shape( {
  firstName: Yup.string().required( "Please enter your first name" ),
  lastName: Yup.string().required( "Please enter your last name" ),
  email: Yup.string().email( "Please enter a valid email address" ).required( "Please enter your email address" ),
  phoneNumber: Yup.string().matches( phoneRegExp, "This should be like +17031234567" )
    .test( 'len', 'Must be a US number with exactly 12 digits including + sign', val => !val || ( val && val.length === 12 ) ),
  password: Yup.string().required( 'Please enter your new password with letters and numbers.' )
    .min( 8, 'Password is too short - should be 8 chars minimum.' )
    .matches( passwordRegExp ),
  passwordConfirm: Yup.string().oneOf( [ Yup.ref( 'password' ), null ], 'Passwords must match' )
    .required( 'Please confirm your password' ),
} );

const stylesFunc = makeStyles( ( theme ) => ( {
  wrapper: {
    marginTop: "2rem",
    height: "calc(200vh - 19.0625rem)",
    textAlign: 'start',
    marginBottom: "6rem",
  },
  root: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 1,
    },
    '& input:invalid:focus + fieldset': {
      borderColor: 'red',
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      borderColor: '#1D58A8',
      padding: '4px !important',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
    color: "#34455E"
  },
  submitButton: {
    background: '#15678D',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#1D58A8'
    }
  },
  signInLink: {
    color: '#15678D'
  }
} ) );

const Signup = ( props ) =>
{
  const [ isSuccess, setIsSuccess ] = useState( false );
  const [ isFailure, setIsFailure ] = useState( false );
  const [ isButtonDisabled, setIsButtonDisabled ] = useState( false );
  const reRef = createRef();

  const handleRegister = async ( values ) =>
  {
    const g_recaptcha_response = await reRef.current.executeAsync();
    // console.log("captcha_response: ", g_recaptcha_response)
    AuthService.register( values.firstName, values.lastName, values.email, values.phoneNumber, values.password, g_recaptcha_response ).then(
      ( response ) =>
      {
        console.log( "response: ", response )
        setIsSuccess( true )
        setIsButtonDisabled( true )
      },
      ( error ) =>
      {
        const resMessage =
          ( error.response &&
            error.response.data &&
            error.response.data.message ) ||
          error.message ||
          error.toString();
        setIsFailure( true )
        setIsButtonDisabled( true )
      }
    );
    //added below line to remove the preventDefault function
    return false;
  };

  const formStyles = stylesFunc();
  
  return (
    <Container className={ formStyles.wrapper } maxWidth="sm">
      <div className={ formStyles.header }>
        <i className="fas fa-graduation-cap fa-5x" style={ { color: "#15678D" } }></i>
        <Typography className={ formStyles.formHeader } variant="h4">
          Scholarship Program
                    </Typography>
        <Typography className={ formStyles.formHeader } variant="h5">
          Sign Up
                    </Typography>
      </div>
      <Formik 
        initialValues={ {
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          password: '',
          passwordConfirm: ''
        } }
        validationSchema={ submissionValidationSchema }
        onSubmit={ handleRegister }
        
      >
        { ( { handleSubmit, handleChange, values, errors } ) => (
          <form 
            onSubmit={ (handleSubmit) }
            className={ formStyles.root }
          >
            <Grid container spacing={ 2 } justify="center">
              <Grid item xs={ 5 }>
                <TextField
                  required
                  type="text"
                  name="firstName"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  value={ values.firstName }
                  onChange={ handleChange }
                  error={ !!errors.firstName }
                  helperText={ errors.firstName }
                />
              </Grid>
              <Grid item xs={ 5 }>
                <TextField
                  required
                  type="text"
                  name="lastName"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  value={ values.lastName }
                  onChange={ handleChange }
                  error={ !!errors.lastName }
                  helperText={ errors.lastName }
                />
              </Grid>
              <Grid item xs={ 10 }>
                <TextField
                  required
                  name="email"
                  label="Email Address"
                  variant="outlined"
                  fullWidth
                  value={ values.email }
                  onChange={ handleChange }
                  error={ !!errors.email }
                  helperText={ errors.email }
                />
              </Grid>
              <Grid item xs={ 10 }>
                <TextField
                  required
                  name="phoneNumber"
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={ values.phoneNumber }
                  onChange={ handleChange }
                  error={ !!errors.phoneNumber }
                  helperText={ errors.phoneNumber }
                />
              </Grid>
              <Grid item xs={ 5 }>
                <TextField
                  required
                  name="password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  fullWidth
                  value={ values.password }
                  onChange={ handleChange }
                  error={ !!errors.password }
                  helperText={ errors.password }
                />
              </Grid>
              <Grid item xs={ 5 }>
                <TextField
                  required
                  name="passwordConfirm"
                  type="password"
                  label="Confirm"
                  variant="outlined"
                  fullWidth
                  value={ values.passwordConfirm }
                  onChange={ handleChange }
                  error={ !!errors.passwordConfirm }
                  helperText={ errors.passwordConfirm }
                />
              </Grid>
              <ReCAPTCHA
                sitekey={ process.env.REACT_APP_RECAPTCHA_SITE_KEY }
                size="invisible"
                ref={ reRef }
              />
              <Grid item xs={ 6 } >
                <Button
                  className={ formStyles.submitButton }
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={ isButtonDisabled }
                  onSubmit={ handleRegister }
                > Sign Up
                      </Button>
              </Grid>
              <Grid item xs={ 10 } >
                { isSuccess && ( <Alert variant="filled" severity="success">
                  Please check your inbox to verify your email address (Please check the spam folder, as well)</Alert>
                ) }
                { isFailure && ( <Alert variant="filled" severity="error">
                  Oops! Looks like we already have this email address in our records</Alert> ) }

              </Grid>
              <Grid container justify="center">
                <Grid item className={ formStyles.signInLink }>Already have an account?
                                  <Link href="/scholarship/signin" variant="body2">
                    Sign in
                                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </form>
        ) }
      </Formik>
    </Container>
  );
}

export default Signup;