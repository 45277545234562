import React from "react";
import { Formik } from "formik";
import { submissionValidationSchema } from './Validation'
import
{
    Button,
    TextField,
    Grid,
    Container,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";


const stylesFunc = makeStyles( ( theme ) => ( {
    root: {
        '& input:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 1,
        },
        '& input:invalid:focus + fieldset': {
            borderColor: 'red',
            borderWidth: 1,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important',
        },
    },
    wrapper: {
        marginTop: "3rem",
        height: "calc(200vh - 19.0625rem)",
        textAlign: 'start',
        marginBottom: "9rem",
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: ''

    },
    reviewButton: {
        marginTop: '2rem',
        marginBottom: '1em',
        background: '#14688D',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#14688D'
        }
    },
} ) );

function NumberFormatCustom ( props )
{
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            { ...other }
            getInputRef={ inputRef }
            onValueChange={ values =>
            {
                onChange( {
                    target: {
                        name: props.name,
                        value: values.value,
                    }
                } );
                console.log( values.value )
            } }
            prefix={ '$ ' }
            // thousandSeparator
        />
    );
}
const FormIncome = ( { setForm, inputData, navigation } ) =>
{
    const { salary, stipend, federal, incomeFromstate, university, other, zelle, bankRouting, bankAccount, acknowledge } = inputData;
    const formStyles = stylesFunc();
    const { previous, next } = navigation;

    return (
        <Container className={ formStyles.wrapper } maxWidth="sm">
            <div className={ formStyles.header }>
                <i className="fas fa-user-graduate fa-5x" style={ { color: "#14688D" } }></i>
                <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h4">
                    Scholarship Program Application
              </Typography>
                <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h5">
                    Income ($)
              </Typography>
            </div>
            <Formik
                initialValues={ {
                    salary: '',
                    stipend: '',
                    federal: '',
                    incomeFromstate: '',
                    university: '',
                    other: '',
                    zelle:'',
                    bankRouting:'',
                    bankAccount:'',
                    acknowledge:''
                } }
                validationSchema={ submissionValidationSchema }
            >
                { ( { handleSubmit, errors, touched } ) => (
                    <form onSubmit={ handleSubmit } className={ formStyles.root }>
                        <Grid container spacing={ 2 } justify="center">
                            <Grid item xs={ 12 }>
                                <Typography variant="subtitle1" display='block' component="h1">
                                    Wage (Numbers Only)
                            </Typography>
                            </Grid>
                            <Grid item xs={ 6 }>
                                <TextField
                                    required
                                    type="decimal"
                                    name="salary"
                                    label="Salary"
                                    variant="outlined"
                                    fullWidth
                                    value={ salary }
                                    onChange={ setForm }
                                    InputProps={ { inputComponent: NumberFormatCustom } }
                                    error={ touched.salary && salary === '' ? true : false }
                                    helperText={ salary === "" ? 'Monthly salary' : null }
                                />
                            </Grid>
                            <Grid item xs={ 6 }>
                                <TextField
                                    required
                                    type="decimal"
                                    name="stipend"
                                    label="Stipend"
                                    variant="outlined"
                                    fullWidth
                                    value={ stipend }
                                    onChange={ setForm }
                                    InputProps={ { inputComponent: NumberFormatCustom } }
                                    error={ touched.stipend && stipend === '' ? true : false }
                                    helperText={ stipend === "" ? 'Stipend for the semester' : null }
                                />
                            </Grid>
                            <Grid item xs={ 12 }>
                                <Typography variant="subtitle1" display='block' component="h1">
                                    Grants (Numbers Only)
                            </Typography>
                            </Grid>
                            <Grid item xs={ 4 }>
                                <TextField
                                    required
                                    type="decimal"
                                    name="federal"
                                    label="Federal"
                                    variant="outlined"
                                    fullWidth
                                    value={ federal }
                                    onChange={ setForm }
                                    InputProps={ { inputComponent: NumberFormatCustom } }
                                    error={ touched.federal && federal === '' ? true : false }
                                    helperText={ federal === "" ? 'Federal grant(s) for the semester' : null }
                                />
                            </Grid>
                            <Grid item xs={ 4 }>
                                <TextField
                                    required
                                    type="decimal"
                                    name="incomeFromstate"
                                    label="State"
                                    variant="outlined"
                                    fullWidth
                                    value={ incomeFromstate }
                                    onChange={ setForm }
                                    InputProps={ { inputComponent: NumberFormatCustom } }
                                    error={ touched.incomeFromstate && incomeFromstate === '' ? true : false }
                                    helperText={ incomeFromstate === "" ? 'State grant(s) for the semester' : null }
                                />
                            </Grid>
                            <Grid item xs={ 4 }>
                                <TextField
                                    required
                                    type="decimal"
                                    name="university"
                                    label="University"
                                    variant="outlined"
                                    fullWidth
                                    value={ university }
                                    onChange={ setForm }
                                    InputProps={ { inputComponent: NumberFormatCustom } }
                                    error={ touched.university && university === '' ? true : false }
                                    helperText={ university === "" ? 'University grant(s) for the semester' : null }
                                />
                            </Grid>
                            <Grid item xs={ 12 }>
                                <Typography variant="subtitle1" display='block' component="h1">
                                    Other Income (Numbers Only)
                            </Typography>
                            </Grid>
                            <Grid item xs={ 12 }>
                                <TextField
                                    required
                                    type="decimal"
                                    name="other"
                                    label="Other"
                                    variant="outlined"
                                    fullWidth
                                    value={ other }
                                    onChange={ setForm }
                                    InputProps={ { inputComponent: NumberFormatCustom } }
                                    error={ touched.other && other === '' ? true : false }
                                    helperText={ other === "" ? 'Monthly other income(s)' : null }
                                />
                            </Grid>
                            <Grid item xs={ 12 }>
                            <Typography variant="subtitle1" display='block' component="h1">
                            Please write your full name in below box to acknowledge the terms and conditions of the Blue Tulip Scholarship grant. 
                            </Typography>
                                <TextField
                                    required
                                    type="text"
                                    name="acknowledge"
                                    label="First Name and Last Name"
                                    variant="outlined"
                                    fullWidth
                                    value={ acknowledge }
                                    onChange={ setForm }
                                    error={ touched.acknowledge && acknowledge === '' ? true : false }
                                    helperText={ acknowledge === "" ? 'Enter your fist name and last name' : null }
                                />
                            </Grid>
                           <Grid item xs={ 12 }></Grid>
                           <Grid item xs={ 12 }></Grid>
                           <Grid item xs={ 12 }>
                            <Typography variant="h6" display='block' component="h3">
                            Enter your information below for one of the transfer options to get the scholarship with a seamless experience in case your application accepted.
                            
                            </Typography></Grid>
                            <Grid item xs={ 12 }>
                            <Typography variant="body2" display='block' component="h3">
                           Zelle Account Details
                            </Typography>
                                <TextField
                                    type="text"
                                    name="zelle"
                                    label="Phone number or e-mail address registered to Zelle"
                                    variant="outlined"
                                    fullWidth
                                    value={ zelle }
                                    onChange={ setForm }
                                    error={ touched.zelle && zelle === '' ? true : false }
                                    helperText={ zelle === "" ? '' : null }
                                />
                            </Grid>
                            <Typography variant="subtitle1" display='block' component="h1">
                            OR
                            </Typography>
                            <Grid item xs={ 12 }>
                            <Typography variant="body2" display='block' component="h3">
                            Banking information  
                            </Typography>
                                <TextField
                                    type="text"
                                    name="bankRouting"
                                    label="Routing Number"
                                    variant="outlined"
                                    fullWidth
                                    value={ bankRouting }
                                    onChange={ setForm }
                                    error={ touched.bankRouting && bankRouting === '' ? true : false }
                                    helperText={ bankRouting === "" ? '' : null }
                                />
                            </Grid>
                            <Grid item xs={ 12 }>
                                <TextField
                                    type="text"
                                    name="bankAccount"
                                    label="Bank Account Number"
                                    variant="outlined"
                                    fullWidth
                                    value={ bankAccount }
                                    onChange={ setForm }
                                    error={ touched.bankAccount && bankAccount === '' ? true : false }
                                    helperText={ bankAccount === "" ? '' : null }
                                />
                            </Grid>
                            <Grid item xs={ 4 }>
                                <Button
                                    className={ formStyles.reviewButton }
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    onClick={ previous }
                                >   Back
                            </Button>
                            </Grid>
                            <Grid item xs={ 4 }>
                                <Button
                                    className={ formStyles.reviewButton }
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    onClick={
                                            salary === '' ||
                                            stipend === '' ||
                                            federal === '' ||
                                            incomeFromstate === '' ||
                                            university === '' ||
                                            acknowledge === '' ||
                                            other === '' 
                                            ? null : next }
                                >   Review
                            </Button>
                            </Grid>
                        </Grid>
                    </form> ) }
            </Formik>
        </Container >
    );
}

export default FormIncome;