import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import amazon from "../../assets/images/amazon-smile-white.png";
import mediumLogoWhite from "../../assets/images/mediumLogoWhite.svg";
import "./Footer.css";
import logo from "../../assets/images/leaves-white.svg";
import xLogo from '../../assets/images/x-logo-white.png';

function Copyright ()
{
    return (
        <React.Fragment>
            <Typography className="copyright">
                { "© " } Blue Tulip Human Rights Center { new Date().getFullYear() }
            </Typography>
        </React.Fragment>
    );
}

export default function Footer ()
{
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( windowWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );
    console.log(" window size:---> " + windowWidth)
    return (
        <Typography component="footer" className="footerRoot">
        { windowWidth > 480 ?
            <Container className="container">
                <Grid container spacing={ 3 } className="footerObjects">
                    <Grid item xs={ 5 } sm={ 5 } md={ 2 } className="logoFooter">
                        <a href="/">
                            <img className="logo-footer" src={ logo } alt="logo"></img>{ " " }
                        </a>
                    </Grid>
                    <Grid item xs={ 5 } sm={ 5 } md={ 2 }>
                        <Typography variant="h6" gutterBottom >
                            Contact Us
                        </Typography>
                        <ul className="list">
                            <li className="listItem">
                                <Typography className="contactus">
                                    PO Box 4866 Virginia Beach, VA 23454
                                </Typography>
                            </li>
                            <li className="listItem">
                                <Typography className="contactus">
                                    blue-tulip@blue-tulip.org
                                </Typography>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={ 5 } sm={ 5 } md={ 2 } >
                        <Typography variant="h6" gutterBottom>
                            Take Action
                        </Typography>
                        <div className="donate-footer">
                            <ul>
                                <li className="aboutus-listItem" >
                                    <a className="listLink" href="/join">Join Us</a>
                                </li>
                                <li className="aboutus-listItem">
                                    <a className="listLink" href="/donate">Donate</a>
                                </li>
                            </ul>
                            {/* <a
                                href="https://www.aboutamazon.com/news/community/how-to-sign-up-for-amazonsmile"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img
                                    className="amazon-logo-footer"
                                    src={ amazon }
                                    alt="Amazon Smile Logo"
                                ></img>
                            </a> */}

                        </div>
                    </Grid>
                    <Grid item xs={ 5 } sm={ 5 } md={ 2 } >
                        <Typography variant="h6" gutterBottom>
                            About Us
                        </Typography>
                        <div >
                            <ul>
                                <li className="aboutus-listItem" >
                                    <a href="/who" className="listLink">Who we are</a>
                                </li>
                                <li className="aboutus-listItem">
                                    <a href="/how" className="listLink">How we help</a>
                                </li>
                                <li className="aboutus-listItem">
                                    <a href="/meaning-of-bt" className="listLink">Meaning of Blue Tulip</a>
                                </li>
                                <li className="aboutus-listItem">
                                    <a href="/annual-reports" className="listLink">Annual Reports</a>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={ 5 } sm={ 5 } md={ 2 } >
                        <Typography variant="h6" gutterBottom>
                            Projects
                        </Typography>
                        <div >
                            <ul>
                                <li className="aboutus-listItem">
                                    <a href="/scholarship" className="listLink">Scholarship</a>
                                </li>
                                <li className="aboutus-listItem">
                                    <a href="/women-in-tech" className="listLink">Women in Tech</a>
                                </li>   
                                <li className="aboutus-listItem">
                                    <a href="/psychological-support" className="listLink">Psychological Support</a>
                                </li>
                                <li className="aboutus-listItem" >
                                    <a href="/awareness" className="listLink">Human Rights Awareness</a>
                                </li>
                                <li className="aboutus-listItem" >
                                    <a href="/research" className="listLink">Scientific Research</a>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                    <Grid item xs={ 5 } sm={ 5 } md={ 2 } >
                        <Grid
                            container
                            direction="column"
                            justify="flex-start"
                            spacing={ 2 }
                        >
                            <Grid item>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                >
                                    Follow Us
                                </Typography>
                                <div className="social">
                                <a
                                        className="fab fa-facebook-square fa-2x"
                                        id="footer-facebook"
                                        href="https://www.facebook.com/bluetulip.org/"
                                        rel="noreferrer"
                                        target="_blank"
                                    />
                                    <a
                                        className="fab fa-linkedin fa-2x"
                                        id="footer-linkedin"
                                        href="https://www.linkedin.com/in/blue-tulip-human-rights-center"
                                        rel="noreferrer"
                                        target="_blank"
                                    />
                                    {/* <a
                                        className="fab fa-twitter fa-2x footer-social"
                                        id="footer-twitter"
                                        href="https://twitter.com/BlueTulipHRC"
                                        target="_blank"
                                        rel="noreferrer"
                                    /> */}
                                    <a 
                                    href='https://twitter.com/BlueTulipHRC' 
                                    target="_blank" 
                                    rel="noreferrer">
                                    <img className="xLogo" 
                                    src={xLogo} alt="x platform" 
                                    />
                                    </a>
                                    <a
                                        id="footer-medium"
                                        href="https://medium.com/@BlueTulipHRC"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img src={ mediumLogoWhite }></img>
                                    </a>
                                       
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={ 12 } sm={ 12 } md={ 12 } >
                        <Copyright />
                        <br></br>
                        <Typography variant="body1" className="non-profit">
                            { "Blue Tulip Human Rights Center is " }
                            <Link
                                href="https://www.irs.gov/charities-non-profits/charitable-organizations"
                                rel="sponsored"
                                title="Freepik"
                                className="irsLink"
                            >
                                501 (c) (3)
                            </Link>
                            {
                                " approved Non-Profit Organization and your donations are tax-exempt as permitted by the law. "
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        :
            <Grid item xs={ 12 } sm={ 12 } md={ 12 } >
                            <Copyright />
                            {/* <br></br>
                            <Typography variant="body1" className="non-profit">
                                { "Blue Tulip Human Rights Center is " }
                                <Link
                                    href="https://www.irs.gov/charities-non-profits/charitable-organizations"
                                    rel="sponsored"
                                    title="Freepik"
                                    className="irsLink"
                                >
                                    501 (c) (3)
                                </Link>
                                {
                                    " approved Non-Profit Organization and your donations are tax-exempt as permitted by the law. "
                                }
                            </Typography> */}
            </Grid>
        }
        </Typography>
    );
}
