export const SideDrawerData = [
    {
        title:'About Us',
        path:'#',
        // icon: <AiIcons.AiFillHome />,
        // iconClosed: <RiIcons.RiArrowDownSFill />,
        // iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Who We Are',
                path: '/who'
            },
            {
                title: 'How We Help',
                path: '/how'
            },
            {
                title: 'Meaning of Blue Tulip',
                path: '/meaning-of-bt'
            },
            {
                title: 'Annual Reports',
                path: '/annual-reports'
            },
            // {
            //     title: 'Careers',
            //     path: '/careers'
            // },
        ]
    },
    {
        title:'Projects',
        path:'#',
        // icon: <IoIcons.IoIosPaper />,
        // iconClosed: <RiIcons.RiArrowDownSFill />,
        // iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Scholarship',
                path: '/scholarship'
            },
            {
                title: 'Women in Tech',
                path: '/women-in-tech'
            },
            {
                title: 'Psychological Support',
                path: '/psychological-support'
            },
            {
                title: 'Human Rights Awareness',
                path: '/awareness'
            },
            {
                title: 'Scientific Research',
                path: '/research'
            },
            // {
            //     title: 'Certifications',
            //     path: '/certifications'
            // },
        ]
    },
    {
        title: 'Take Action',
        path: '#',
        // icon: <FaIcons.FaEnvelopeOpenText />,
        // iconClosed: <RiIcons.RiArrowDownSFill />,
        // iconOpened: <RiIcons.RiArrowUpSFill />,
        subNav: [
            {
                title: 'Join Us',
                path: '/join'
            },
            {
                title: 'Donate',
                path: '/donate'
            }
        ]
    },
    {
        title: 'News',
        path: '/news',
        subNav:null
    },
    {
        title: 'Contact Us',
        path: '/contact',
        subNav:null
    },
    {
        title: 'Members',
        path: '/members',
        subNav:null
    },
];
