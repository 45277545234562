import * as Yup from "yup";


export const submissionValidationSchema = Yup.object().shape({
    firstName: Yup.string().when('isEdit', {is: false, then:Yup.string().required("Please enter your first name")}),
    lastName: Yup.string().when('isEdit', {is: false, then:Yup.string().required("Please enter your last name")}),
    legalStatus: Yup.string().required("Please enter your legal status in the US"),
    dob: Yup.string().required("Please enter your date of birth"),
    schoolEmail: Yup.string().email("Please enter a valid email address").required("Please enter your school email address"),
    personalEmail: Yup.string().email("Please enter a valid email address").required("Please enter your personal email address"),
    phone: Yup.string().required("Please enter your phone number"),
    address: Yup.string().required("Please enter your street address in the US"),
    city: Yup.string().required("Please enter your city in the US"),
    zipcode: Yup.string().required("Please enter your zip code in the US"),
    state: Yup.string().required("Please enter your state in the US"),
    school: Yup.string().required("Please enter your current university or college name"),
    progress: Yup.string().required("Please enter your so far progress in your program"),
    program: Yup.string().required("Please enter your current program name"),
    studentId: Yup.number().required("Please enter your university id number"),
    startYear: Yup.string().required("Please enter your program start date"),
    grade: Yup.string().required("Please enter your average grade of previous semester"),
    militaryAffiliation: Yup.string().required("Please enter your military affiliation"),
    salary: Yup.number().required('Monthly salary'),
    stipend: Yup.number().required( 'Stipend for the semester'),
    federal: Yup.number().required( 'Federal grant(s) for the semester'),
    incomeFromstate: Yup.number().required( 'State grant(s) for the semester'),
    university: Yup.number().required('University grant(s) for the semester'),
    // family: Yup.number().required( 'Dollar amount of received from family'),
    other: Yup.number().required( 'Dollar amount of other income(s)'),
    rent: Yup.number().required( 'Monthly rent amount'),
    utilities: Yup.number().required('Monthly average of utility bills'),
    // internet: Yup.number().required( 'Monthly average bill',),
    // electiricityGas: Yup.number().required( 'Monthly average bill',),
    // water: Yup.number().required( 'Monthly average bill',),
    // car: Yup.number().required( 'Monthly insurance bill',),
    // home: Yup.number().required( 'Monthly insurance bill',),
    // medical: Yup.number().required( 'Monthly insurance bill',),
    // food: Yup.number().required( 'Monthly expenses for any'),
    tuition: Yup.number().required( 'Tuition amount for the semester'),
    acknowledge: Yup.string().required( 'Please enter your first name and last name'),
    // textbooks: Yup.number().required( 'Monthly amount'),
    // supplies: Yup.number().required( 'Monthly amount'),
    // creditCard: Yup.number().required( 'Total debt'),
    // carPurchase: Yup.number().required( 'Total debt'),
    // householdPurchase: Yup.number().required( 'Total debt'),
  
  });