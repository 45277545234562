import React, { useState, useEffect } from "react";
import '../testimonials/Testimonials.css'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CardMedia } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import pic1 from '../../../assets/images/melissasm.jpg'
import pic2 from '../../../assets/images/sara.jpeg'
import pic3 from '../../../assets/images/Cathy.jpg'
import pic4 from '../../../assets/images/Jan_Headshot.jpg'
const useStyles = makeStyles( ( theme ) => ( {
    root: {
        marginTop:'2rem',
    },
    media: {
        boxShadow: "0 5px 10px grey",
        marginLeft:'3.5rem',
        marginTop:'2rem'
    },
    who: {
        margin: '3em 2em',
        color: '#15678D',
    },
    paper: {
        padding: theme.spacing( 10 ),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        // height: '16rem',
        alignItems:'center',
        justifyContent:'space-between'
    },
    
    details: {
        color: '#15678D',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'1.2rem',
        textDecoration: 'none',
    },

    detailsSmall:{
        color: '#34455E',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'0.8rem',
        textDecoration: 'none',
    },

    whoInfoTop:{
        margin:'2rem 3rem -1rem 3rem',
        fontSize:'1rem',
        display:'flex',
     },

    whoInfo:{
       margin:'1rem 3rem',
       fontSize:'1rem',
       display:'flex',
    },

    whoInfoSmall:{
        marginTop:'2rem',
        fontSize:'1.2rem',
        display:'flex',
        flexWrap:'wrap',
        textAlign:'center',
        margin:'1rem'
     },

     explanation:{
        borderLeft:'4px solid #15678D',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
        flex:1,
        color:'#15678D',
     },

     explanationSmall:{
        borderTop:'4px solid #15678D',
        width:'100%',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
        textAlign:'center'

     },

    icon:{
        fontSize:'5rem',
        margin:'2rem 2rem',
    },

    iconSmall:{
        fontSize:'3rem',
        margin:'2rem 2rem',

    },
    whoBtn: {
        // position: "relative",
        width: '8rem',
        height: '3rem',
        marginTop:'5rem',
        fontSize: 'medium',
        backgroundColor: '#fff',
        border: '1px solid #15678D',
        color: '#15678D',
        boxShadow: '0 0 3px 1px #fff',
        textDecoration: 'none',
        borderRadius:'0.2rem',
            '&:hover': {
                transition: '0.7s ease-in-out',
                background: '#15678D',
                borderStyle: 'none',
                color: '#fff',
                cursor: 'pointer',
            },
    },

    whoName:{
        color:'#15678D',
        fontSize:'1.2rem',
        lineHeight:'1.8rem',
    },

    whoNameBottom:{
        color:'#15678D',
        fontSize:'1.2rem',
        lineHeight:'1.8rem',
        marginTop:'5rem'
    },

} ) );

export default function WhoMelissa ()
{
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );
    return (
        <div className={ classes.who }>
            <Grid container spacing={ 3 } >
                <Grid item xs={ 12 } sm={ 12 } md={ 12 } >
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 8 } sm={ 6 } md={ 2 }>
                                <CardMedia
                                    component="img"
                                    alt="Melissa's picture"
                                    height="300"
                                    className= { classes.media }
                                    image={ pic1 }
                                />
                            </Grid>
                            <Grid item xs={ 12 } sm={10 } md={ 10 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                    <span className={(windowWidth > 1024 ? classes.whoInfoTop : classes.whoInfoSmall)}>
                                        Melissa Page Deutsch, M.S., CCC-SLP, CPCC, ACC, is a credentialed personal 
                                        development coach and licensed speech-language pathologist. Her work has been 
                                        published in international journals and she has spoken nationally on creative and 
                                        empowering ways to navigate professional transitions, burnout, and the inner critic. 
                                        Her innovative personal development workshops are praised for their creativity, 
                                        practicality, and lasting impact on attendees. 
                                    </span>
                                    <br></br>
                                    <br></br>
                                    <span className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}>
                                        Her work as a speech pathologist has included clinical work with individuals and groups spanning ages and diagnoses in tertiary 
                                        care hospitals, rehabilitation, private practice, and university clinics, as well as 
                                        academic work as a researcher and clinical supervisor of graduate students. Melissa 
                                        draws on her experience and expertise in communication and cognition, as well as 
                                        superb listening skills, in her life’s work to appreciate and encourage the best 
                                        in individuals and groups. She earned a B.A in French from Davidson College and M.S. 
                                        in Speech-Language Pathology from the University of North Carolina at Chapel Hill. 
                                    </span>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 12}>
                    <h1 style={ { color: '#15678D', textShadow: '1px 1px #fff', textAlign:'center', marginTop: '2rem', marginBottom: '1em' } }>Other Senior Board of Directors</h1>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Sara's picture"
                                    height="300"
                                    image={ pic2 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <div className={classes.info}>
                                    <div className={classes.whoNameBottom}> 
                                        <b>Sara Elizabeth Dill</b>
                                    </div>
                                    <a href='/who-sara'>
                                        <button className={classes.whoBtn}>
                                            About Sara
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Cathy's picture"
                                    height="300"
                                    image={ pic3 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <div className={classes.info}>
                                    <div className={classes.whoNameBottom}> 
                                        <b>Cathy Lewis</b>
                                    </div >
                                    <a href='/who-cathy'>
                                        <button className={classes.whoBtn}>
                                            About Cathy
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 4 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 3 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Jan's picture"
                                    height="300"
                                    image={ pic4 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <div className={classes.info}>
                                    <div className={classes.whoNameBottom}> 
                                        <b>Jan Pederson</b>
                                    </div >
                                    <a href='/who-jan'>
                                        <button className={classes.whoBtn}>
                                            About Jan
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </div>)}