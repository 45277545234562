import React, { useState, useEffect } from "react";
import Aos from 'aos';
import image from "../../../assets/images/leaves.svg";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles( ( theme ) => ( {
    containerMeaning: {
        marginBottom:'15rem',
        marginTop:'5rem',
    },
howInfo:{
    marginTop:'3rem',
    marginBottom:'5rem',
    fontSize:'1.6rem',
    display:'flex',
 },

 howInfoSmall:{
     marginTop:'3rem',
     fontSize:'1.2rem',
     display:'flex',
     flexWrap:'wrap',
     color:'#15678D'


  },
  explanation:{
     padding:'2rem 2rem',
     height:'100%',
     flex:1,
     color:'#15678D',
     lineHeight:'2.5rem',
     fontSize:20

  },

  explanationSmall:{
    width:'100%',
    padding:'2rem 2rem',
    color:'#15678D',
    marginBottom:'2rem',
    lineHeight:'2.5rem',
    fontSize:20


 },

 icon:{
     fontSize:'5rem',
     margin:'2rem 2rem',
     color:'#15678D'
 },

 iconSmall:{
     fontSize:'3rem',
     margin:'2rem 2rem',
     color:'#15678D'
 },
 meaningExp:{
     display:'flex',
     alignItems:'center',
     marginTop:'10rem'
 },
 meaningExpSmall:{
    display:'flex',
    flexDirection:'column',
    alignItems:'center'

},
 blueTulipMeaning:{
    height:'16rem',
    width:'10rem',
    flexGrow:0,
    marginRight:'3rem',
    alignItems:'center'
  }
} ) );
export default function Meaning() {
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };
        window.addEventListener( 'resize', handleWindowResize );
    }, [] );
    useEffect(() => {
        Aos.init({ duration: 3000});
    }, []);
    return (
        <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={classes.containerMeaning}>
            <div className={(windowWidth > 1024 ? classes.meaningExp : classes.meaningExpSmall)}>
                <div className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    Tulips are currently accepted and come in a wide variety of
                    colors, except blue. Blue tulips don’t exist at all. The
                    color blue occurs more sparsely in nature than any other
                    kind, and thus far, no one has been able to hybridize a
                    tulip which is <a href='https://en.wikipedia.org/wiki/Species' target="_blank">species</a> Tulip flowers were born in Anatolia.
                </div>
            <img data-aos="zoom-in" data-aos-delay="500" src={image} alt="blue tulip logo" className={classes.blueTulipMeaning}/>
                <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} data-aos-delay="300" className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                        About 75 wild firmly, plainly, ideally blue. Even nobody
                    could create a real blue tulip it symbolizes tranquility and
                    peace because of the blue color. Gives the message of trust
                    and loyalty. And now it became a symbol of hope for
                    silenced, isolated and discriminated children and women.
                </div>
            </div>
        </div>
    );
}
