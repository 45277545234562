import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, TextField } from '@material-ui/core';
import './News.css';
import BulkNewsElement from "./BulkNewsElement";
import RssFeed from "./RssFeed";
import Typography from "@material-ui/core/Typography";
import ReactPaginate from "react-paginate";
import LoadSpinner from '../../components/LoadSpinner/LoadSpinner';
import Newsletter from '../../components/Newsletter.js'


const useStyles = makeStyles( ( theme ) => ( {
    details: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 12,
        color: 'red',
        fontWeight: 600,
        textShadow: '10',
        fontFamily: "Arial, Helvetica, sans-serif"
    },
    sideNewsTopic: {
        position: 'static',
        color: '#34455E',
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 26,
        '@media screen and (max-width: 1024px)': {
            fontSize: 22,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 19,
        },
    },
    header: {
        position: "absolute",
        display: "inline",
        bottom: "10%",
        right: '1.6%',
        maxWidth: "90%",
        textAlign: "start",
        color: "black",
        backgroundColor: "white",
        padding: "4px 0",
        fontWeight: 600,
        boxShadow: "10px 0 0 white",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 16,
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 9,
        },
    },
    sideNews: {
        marginTop: "1rem",
        marginBottom: "2rem"
    },

    subscribeButton:{
        backgroundColor:'#16668D',
        color:'#fff',
    }
} ) )

function BulkNews ()
{
    const [isLoading, setIsLoading] = useState(true);
    const [ newsList, setNewsList ] = useState( [] );
    const [ newsOne, setNewsOne ] = useState( [] );
    const [ pageNumber, setPageNumber ] = useState( 0 );
    const { REACT_APP_NEWS_API_BASE_URL } = process.env;
    const newsPerPage = 5;
    const pageNumberVisited = pageNumber * newsPerPage;

    const displayNews = newsList
        .slice( pageNumberVisited, pageNumberVisited + newsPerPage )
        .map( ( news ) =>
        {
            return (
                <BulkNewsElement
                    id={ news?.id }
                    image={ news?.image }
                    date={ news?.date }
                    title={ news?.title }
                    summary={ news?.summary }
                />
            )
        } )

    let id = useParams();
    const classes = useStyles();

    const pageCount = Math.ceil( newsList.length / newsPerPage )
    const changePage = ( { selected } ) =>
    {
        setPageNumber( selected )
    }

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            const respGlobal = await axios.get( REACT_APP_NEWS_API_BASE_URL + "news" )
            setNewsList( respGlobal.data )
            console.log( "newsList: ", newsList )
            setIsLoading(false)
            // const respNewsOne = await axios.get( REACT_APP_NEWS_API_BASE_URL + `news/${ parseInt(id) }` )
            // setNewsOne( respNewsOne.data )
        };
        fetchData();
    }, [ id ] );
    return (
    <div className='focusCont' >
        { isLoading ? <LoadSpinner/> :
        <Grid container alignItems="flex-start" justify="center" >
            <Grid item xs={ 10 } sm={ 8 } md={ 7 } >
                <div>
                    { displayNews }
                    <ReactPaginate
                        previousLabel={ "<" }
                        nextLabel={ ">" }
                        pageCount={ pageCount }
                        onPageChange={ changePage }
                        pageRangeDisplayed={ newsList.length }
                        marginPagesDisplayed={ pageNumber }
                        containerClassName={"paginationButtons"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />
                </div>
            </Grid>
            <Grid item sm={ 1 } md={ 1 } ></Grid>
            <Grid item xs={ 11 } sm={ 4 } md={ 3 } className={ classes.sideNews }>
                <Grid item xs={ 12 } sm={ 12 } md={ 12 } className={ classes.sideNews }>
                    <Newsletter/>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 12 } className={ classes.sideNews }>
                    <Typography gutterBottom variant="h5" className={ classes.sideNewsTopic } >
                        RSS FEED NEWS
                    </Typography><hr></hr>

                </Grid>
                <Grid>
                    <RssFeed />
                </Grid>
            </Grid>    
        </Grid>}
    </div>
);
}

export default BulkNews;