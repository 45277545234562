import React, {useState, useEffect} from 'react'
import Aos from 'aos';
import Grid from '@material-ui/core/Grid';
import CardMedia from "@material-ui/core/CardMedia";
import PsycologicalSupportImage from '../../assets/images/Psychological_Support.png';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( ( theme ) => ( {
    media: {
        position: "relative",
        paddingBottom: "68.25%", // 16:9
        backgroundSize: "80%",
        marginBottom:'3rem'
    },
    explanationBlock:{
        display:'flex',
        paddingTop:'2rem',
        margin:'2rem 2rem 0rem 2rem ',
        '@media screen and (max-width: 900px)': {
            flexDirection:'column'
        },
    },
    explanation: {
        height:'100%',
        lineHeight:'2.5rem',
        flex:1,
        padding:'1rem',
        color: "#15678D",
        fontWeight: 200,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 20,
        '@media screen and (max-width: 1024px)': {
            fontSize: 20,
            lineHeight:'2rem',

        },
        '@media screen and (max-width: 700px)': {
            fontSize: 20,
            lineHeight:'2rem',

        },
    },
    icon:{
        fontSize:'3rem',
        margin:'1rem',
        color:'#15678D',

    },

    iconSmall:{
        fontSize:'2rem',
        margin:'1rem',
        color:'#15678D',
        justifyContent:'center'

    },
} ) );
const PsychologicalSupport = () =>
{

    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const imageUrl = windowWidth >= 650 ? PsycologicalSupportImage : PsycologicalSupportImage;
    const classes = useStyles();
    useEffect(() => {
        Aos.init({ duration: 3000});
    }, []);
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );
    return (
        <div data-aos="fade-up"className="topImageContainer" >
        <Grid
            container
            spacing={ 0 }
            alignItems="center"
            justify="center"
        >
            <Grid item xs={ 12 } sm={ 12 } md={ 10 } >
                <CardMedia
                    >
                    <div className={ classes.explanationBlock}>
                        <div data-aos="fade-up" className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                            <i className="fas fa-umbrella"></i>
                        </div>
                        <div className={ classes.explanation }>
                        Blue Tulip Human Rights Center cooperates with the Psychological Development Institute to provide support to the traumatized forced migrants to facilitate integration.
Psychological Development Institute is a platform that aims to provide free counseling and share information over the internet to people who have any type of psychological problem including, but not limited to, problems related to traumas such as migration, torture, and war. Scientific, ethical values, being unbiased, and confidentiality are among our important values.
Should there be any question or need assistance, feel free to ask our Blue Tulip Human Rights Center volunteer, <a href='//psychologicaldevelopmentinstitute.com/psychological-support/' target="_blank">here</a>
                        </div>
                            <br/>
                            <br/>
                    </div>  
                </CardMedia>
            </Grid>
        </Grid>
        <Grid
            data-aos="zoom-in"
            container
            spacing={ 0 }
            alignItems="center"
            justify="center"
        >
            <Grid item xs={ 12 } >
                <CardMedia
                    className={ classes.media }
                    style={ { backgroundImage: `url(${ imageUrl })` } }
                    component="h2">
                </CardMedia>
            </Grid>
        </Grid>
    </div>
    )}
export default PsychologicalSupport;