import React from "react";
import { Formik } from "formik";
import
{
    Button,
    TextField,
    Grid,
    Container,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { submissionValidationSchema } from './Validation';
import NumberFormat from "react-number-format";


const stylesFunc = makeStyles( ( theme ) => ( {
    root: {
        '& input:valid + fieldset': {
            borderColor: 'green',
            borderWidth: 1,
        },
        '& input:invalid:focus + fieldset': {
            borderColor: 'red',
            borderWidth: 1,
        },
        '& input:valid:focus + fieldset': {
            borderLeftWidth: 6,
            padding: '4px !important',
        },
    },
    wrapper: {
        marginTop: "3rem",
        height: "calc(200vh - 19.0625rem)",
        textAlign: 'start',
        marginBottom: "9rem",
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: '1rem',
        marginTop: '1rem',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: ''

    },
    reviewButton: {
        marginTop: '2rem',
        marginBottom: '1em',
        background: '#14688D',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#14688D'
        }
    },
} ) );

function NumberFormatCustom ( props )
{
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            { ...other }
            getInputRef={ inputRef }
            onValueChange={ values =>
            {
                onChange( {
                    target: {
                        name: props.name,
                        value: values.value,

                    }
                } );
                console.log( values.value )
            } }
            prefix={ '$ ' }
            thousandSeparator
        />
    );
}

const FormExpense = ( { setForm, inputData, navigation } ) =>
{
    const { rent, utilities, tuition } = inputData;
    const formStyles = stylesFunc();
    const { previous, next } = navigation;

    return (
        <Container className={ formStyles.wrapper } maxWidth="sm">
            <div className={ formStyles.header }>
                <i className="fas fa-user-graduate fa-5x" style={ { color: "#14688D" } }></i>
                <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h4">
                    Scholarship Program Application
              </Typography>
                <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h5">
                    Expenditure ($)
              </Typography>
            </div>
            <Formik
                initialValues={ {
                    rent: '',
                    utilities: '',
                    tuition: ''
                } }
                validationSchema={ submissionValidationSchema }
            >
                { ( { handleSubmit, touched } ) => (
                    <form onSubmit={ handleSubmit } className={ formStyles.root }>
                        <Grid container spacing={ 2 } justify="center">
                            <Grid item xs={ 12 }>
                                <TextField
                                    required
                                    type="decimal"
                                    name="rent"
                                    label="Rent"
                                    variant="outlined"
                                    fullWidth
                                    value={ rent }
                                    onChange={ setForm }
                                    InputProps={ { inputComponent: NumberFormatCustom } }
                                    error={ touched.rent && rent === '' ? true : false }
                                    helperText={ rent === "" ? 'Monthly rent amount' : null }
                                />
                            </Grid>
                            <Grid item xs={ 12 }>
                                <TextField
                                    required
                                    type="decimal"
                                    name="utilities"
                                    label="Utilities"
                                    variant="outlined"
                                    fullWidth
                                    value={ utilities }
                                    onChange={ setForm }
                                    InputProps={ { inputComponent: NumberFormatCustom } }
                                    error={ touched.utilities && utilities === '' ? true : false }
                                    helperText={ utilities === "" ? 'Monthly average cost of utilities' : null }
                                />
                            </Grid>
                            <Grid item xs={ 12 }>
                                <TextField
                                    required
                                    type="decimal"
                                    name="tuition"
                                    label="Tuition"
                                    variant="outlined"
                                    fullWidth
                                    value={ tuition }
                                    onChange={ setForm }
                                    InputProps={ { inputComponent: NumberFormatCustom } }
                                    error={ touched.rent && rent === '' ? true : false }
                                    helperText={ tuition === "" ? 'Tuition amount for the semester' : null }
                                />
                            </Grid>
                            <Grid item xs={ 4 }>
                                <Button
                                    className={ formStyles.reviewButton }
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    onClick={ previous }
                                >   Back
                          </Button>
                            </Grid>
                            <Grid item xs={ 4 }>
                                <Button
                                    className={ formStyles.reviewButton }
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    onClick={
                                            rent === '' ||
                                            utilities === '' ||
                                            tuition === '' 
                                            ? null : next }
                                >   Next
                          </Button>
                            </Grid>
                        </Grid>
                    </form> ) }
            </Formik>
        </Container>
    );
}

export default FormExpense;