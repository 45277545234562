import React, { useState, useEffect } from "react";
import axios from "axios";
import XMLParser from 'react-xml-parser';
import './News.css';

export default function RssFeed() {
    const [post, setPost] = useState([]);
    const [err, seterr] = useState(null);
    
    let url = 'https://www.hrw.org/rss';
    
    let p = [];
    useEffect(() => {
        
      //  console.log(props.location.state,url1,cat)
      console.log("get request is being sent now")
      axios.get(url, {
        headers: {
          "Access-Control-Request-Headers": "x-token",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Methods": "GET, OPTIONS, POST, PUT",
          "Access-Control-Allow-Headers": "X-Requested-With, Content-Type, X_Token, access-control-allow-origin, access-control-allow-methods, access-control-allow-headers",
          "content-type": "application/x-www-form-urlencoded",
        }})
        .then((d) => {
          var xml = new XMLParser().parseFromString(d.data);
          let news = xml.children[0].children;
          for (let i in news) {
            if (
              news[i].children !== null &&
              news[i].children !== [] &&
              news[i].children.length > 0
            ) {
              p.push(news[i].children);
              console.log("News RSS", news[i])
            }
          }
          setPost(p);
          console.log("p", p)
        })
        .catch((e) => {
          console.log("e",e);
          console.log("catch block ...")
          seterr(e);
        });
    }, []);
 
    return (
<div>
    {p}
</div>  
  );
  }