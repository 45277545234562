export const MenuItems = [
    {
        name:'NEWS',
        path:'/news',
        cName:'nav-links'
    },
    {
        name:'CONTACT US',
        path:'/contact',
        cName:'nav-links'
    },
    {
        name:'MEMBERS',
        path:process.env.REACT_APP_NEWS_API_BASE_URL + 'login',
        cName:'nav-links'
    },
]

