import React, { useState, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import
{
  TextField,
  Grid,
  Container,
  Typography,
  Link,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AuthService from '../../services/AuthService';
import Alert from '@material-ui/lab/Alert';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useLocation } from "react-router-dom";

// let passwordRegExp = new RegExp( );

const submissionValidationSchema = Yup.object({
  password: Yup.string().required( 'Please enter your new password with letters and numbers.' )
    .min( 8, 'Password is too short - should be 8 characters minimum.' ).max( 50, 'Password is too long - should be 49 characters maximum.' ),
  confirm: Yup.string().oneOf( [ Yup.ref( 'password' ), null ], 'Passwords must match' )
    .required( 'Please confirm your password' ),
  })  

const stylesFunc = makeStyles( ( theme ) => ( {
  wrapper: {
    marginTop: "3rem",
    height: "calc(200vh - 19.0625rem)",
    textAlign: 'center',
    marginBottom: "6rem",
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: ''
  },
  root: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 1,
    },
    '& input:invalid:focus + fieldset': {
      borderColor: 'red',
      borderWidth: 1,
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      borderColor: 'green',
      padding: '4px !important', // override inline-style
    },
  },
  formLogo: {
    maxWidth: 150,
    marginBottom: 20
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
  submitButton: {
    borderRadius:'3px',
    marginBottom: '1em',
    background: '#15678D',
    color: '#fff',
    border:'none',
    padding:'0.7rem 2rem',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#15678D',
      border:'1px solid #15678D',
      borderRadius:'3px',

    }
  },
  signUpLink: {
    color: '#34455E',
    justifyContent:'center'
  
  }
} ) );

const ResetPassword = ( props ) =>
{
  const [ isSuccess, setIsSuccess ] = useState( false );
  const [ isFailure, setIsFailure ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ message, setMessage ] = useState( "" );
  const [ isEnabled, setEnabled ] = useState(true);
  const [ showPassword, setShowPassword ] = useState(false);


  let location = useLocation();

  const handleClickShowPassword = () =>
  {
      setShowPassword(!showPassword)
  }
  const ref = useRef(null);

    var d = new Date();
  const handleResetPassword = async ( values ) =>
  {
    // const g_recaptcha_response = await reRef.current.executeAsync();
    // console.log("captcha_response: ", g_recaptcha_response)
      let token = location.search.split("?token=").pop();
      AuthService.resetPassword( ref.current.values.password, token).then(//g_recaptcha_response ).then(
        ( response ) =>
        {
          console.log("API response - success response ", response)
          setIsSuccess( true )
          setEnabled( false )
          setLoading ( true )
        },
        ( error ) =>
        {
          if (error.response.status === 500) {
            console.log("API response - error.response ", error.response)
            console.log("API response - error.response.status ", error.response.status)
            const resMessage =
            ( error.response &&
              error.response.data &&
              error.response.data.message ) ||
              error.message ||
              error.toString();
            setLoading ( true )
          } else {
            console.log("API response - error.response ", error.response)
            console.log("API response - error.response.status ", error.response.status)
            const resMessage =
            ( error.response &&
              error.response.data &&
              error.response.data.message ) ||
              error.message ||
              error.toString();
            setIsFailure( true )
            setLoading ( true )
          }
      }
    );
    //added below line to remove the preventDefault function
    return false;
  }

  const formStyles = stylesFunc();
  return (
    <Container className={ formStyles.wrapper } maxWidth="sm">
      <div style={ { display: 'flex', justifyContent: 'flex-end' } }>  </div>
      <div className={ formStyles.header }> 
      {/* { UserService.getUserBoard() } */}
        <i className="fas fa-graduation-cap fa-5x" style={ { color: "#15678D" } }></i>
        <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h4">
          Scholarship Program
              </Typography>
        <Typography className={ formStyles.formHeader } style={ { color: "#34455E" } } variant="h6">
        Enter your new password
        </Typography>
      </div>
      <Formik
        initialValues={ {
          password: '',
          confirm: '',
        } }
        innerRef={ref}
        validationSchema={ submissionValidationSchema }
        onSubmit={ handleResetPassword }
      >
        { ( { handleSubmit, handleChange, values, errors, dirty, isValid } ) => (
          <form onSubmit={ handleSubmit } className={ formStyles.root }>
            <Grid container spacing={ 3 } justify="center">
              <Grid item xs={ 10 }>
              <TextField
                  required
                  name="password"
                  label="Enter your new password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  value={ values.password }
                  onChange={ handleChange }
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={ !!errors.password }
                  helperText={ errors.password }
                />
              </Grid>
              <Grid item xs={ 10 }>
                <TextField
                  required
                  name="confirm"
                  type={showPassword ? "text" : "password"}
                  label="Confirm your new password"
                  variant="outlined"
                  fullWidth
                  value={ values.confirm }
                  onChange={ handleChange }
                  InputProps={{ 
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  error={ !!errors.confirm }
                  helperText={ errors.confirm }
                />
              </Grid>
              <Grid item xs={ 6 } >
                <button
                  disabled={ !(dirty && isValid) }
                  className={ formStyles.submitButton }
                  type="submit"
                  // variant="contained"
                  // fullWidth
                  onClick={ handleResetPassword }
                > RESET
                </button>
                  { isSuccess && ( <Alert variant="filled" severity="success">
                    Your password has been updated. Please sign in with your new password.</Alert>
                  ) }
                  { isFailure && ( <Alert variant="filled" severity="error">
                    We can't update your password at this time, please double check your inputs</Alert> ) }
              </Grid>
              <Grid container spacing={3} style={{justifyContent:"center"}}>
                <Grid item xs={ 10 } className={ formStyles.signUpLink }>
                  {isSuccess ? <Link href="/scholarship/signin" >Sign in</Link>
                    : <Link href="/scholarship/signin" >Sign in with another account</Link>}
                </Grid>
              </Grid>
            </Grid>
          </form>
        ) }
      </Formik>
    </Container>
  );
}

export default ResetPassword;