import axios from "axios";
import authHeader from "./AuthHeader";

const { REACT_APP_SCHOLARSHIP_SIGNIN_API } = process.env;

const getPublicContent = () => {
  return axios.get(REACT_APP_SCHOLARSHIP_SIGNIN_API + "/all");
};

const getUserBoard = () => {
  axios.get(REACT_APP_SCHOLARSHIP_SIGNIN_API + "/user", { headers: authHeader() });
  console.log("get request to be sent")
};

export default {
  getPublicContent,
  getUserBoard,
};