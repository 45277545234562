const stateNameList = [
    { value:1, stateName:'Alabama' },
    { value:2, stateName:'Alaska' },
    { value:3, stateName:'Arizona' },
    { value:4, stateName:'Arkansas' },
    { value:5, stateName:'California' },
    { value:6, stateName:'Colorado' },
    { value:7, stateName:'Connecticut' },
    { value:8, stateName:'Delaware' },
    { value:9, stateName:'Florida' },
    { value:10, stateName:'Georgia' },
    { value:11, stateName:'Hawaii' },
    { value:12, stateName:'Idaho' },
    { value:13, stateName:'Illinois' },
    { value:14, stateName:'Indiana' },
    { value:15, stateName:'Iowa' },
    { value:16, stateName:'Kansas' },
    { value:17, stateName:'Kentucky' },
    { value:18, stateName:'Louisiana' },
    { value:19, stateName:'Maine' },
    { value:20, stateName:'Maryland' },
    { value:21, stateName:'Massachusetts' },
    { value:22, stateName:'Michigan' },
    { value:23, stateName:'Minnesota' },
    { value:24, stateName:'Mississippi' },
    { value:25, stateName:'Missouri' },
    { value:26, stateName:'Montana' },
    { value:27, stateName:'Nebraska' },
    { value:28, stateName:'Nevada' },
    { value:29, stateName:'New Hampshire' },
    { value:30, stateName:'New Jersey' },
    { value:31, stateName:'New Mexico' },
    { value:32, stateName:'New York' },
    { value:33, stateName:'North Carolina' },
    { value:34, stateName:'North Dakota' },
    { value:35, stateName:'Ohio' },
    { value:36, stateName:'Oklahoma' },
    { value:37, stateName:'Oregon' },
    { value:38, stateName:'Pennsylvania' },
    { value:39, stateName:'Rhode Island' },
    { value:40, stateName:'South Carolina' },
    { value:41, stateName:'South Dakota' },
    { value:42, stateName:'Tennessee' },
    { value:43, stateName:'Texas' },
    { value:44, stateName:'Utah' },
    { value:45, stateName:'Vermont' },
    { value:46, stateName:'Virginia' },
    { value:47, stateName:'Washington' },
    { value:48, stateName:'West Virginia' },
    { value:49, stateName:'Wisconsin' },
    { value:50, stateName:'Wyoming' },
    { value:51, stateName:'Other' }
]
export {stateNameList};