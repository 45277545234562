import React, { useEffect } from "react";
import Aos from 'aos';
import
{
    Grid,
    Container,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const stylesFunc = makeStyles( ( theme ) => ( {
    wrapper: {
        marginTop: "3rem",
        height: "calc(200vh - 19.0625rem)",
        // textAlign: 'start',
        marginBottom: "30rem",

    },
    header: {
        display: 'flex',
        marginBottom: "3rem",
        alignItems: 'center',
        justifyContent: 'space-evenly'

    },

    documentItem: {
        textDecoration: 'none',
        color: "#34455E",
        alignSelf: 'center'
    },

    documentLine: {
        textAlign: 'start',
    },
    icon: {
        textAlign: 'end',
    },
    formHeader: {
        textAlign: 'center',
        marginBottom: '1rem',
        marginTop: '1rem',
        color: "#34455E"
    },
    submitButton: {
        marginBottom: '1em',
        background: '#14688D',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#fff',
            color: '#14688D'
        }
    },
    formStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '2rem'

    },
    videoContainer: {
        position: 'relative',
        width: '100%',
        height: '0',
        paddingBottom: '56.25%',
        marginBottom:'5rem' 
    },
    iFrame: {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',

    },
}
) );

const Awareness = () =>
{

    const formStyles = stylesFunc();
    useEffect( () =>
    {
        Aos.init( { duration: 3000 } );
    }, [] );

    Aos.refresh()

    return (

        <Container className={ formStyles.wrapper } maxWidth='md'>
                    <Typography className={ formStyles.formHeader } variant="h5">
                        NGO CSW FORUM 67
                </Typography>
                <Typography className={ formStyles.formHeader } variant="h5">
                      BTHRC had contributed to UN Commission on the Status of Women (CSW) with distinguished speakers
                      Dr. Erin Rider, Dr. Cihan Aydiner and Selin Senol Akin on highly important two different topics.
                        
                </Typography>
             <div className={formStyles.videoContainer}>
             <iframe 
                className={formStyles.iFrame}
                src="https://www.youtube.com/embed/pMGJy-GZOvE" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen
             />
             
    </div>
            <Grid container spacing={ 3 }>
                <Grid data-aos="fade-right" item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.header } >
                    <i className="fas fa-balance-scale fa-4x" style={ { color: "#14688D" } }></i>
                </Grid>
                <Grid data-aos="fade-left" item xs={ 10 } sm={ 10 } md={ 10 }>
                    <Typography className={ formStyles.formHeader } variant="h5">
                        Human Rights Awareness Documents
                </Typography>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="/documents/csw66.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="/documents/csw66.pdf" target="_blank">NGO CSW Forum 66 Report</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="https://www.ohchr.org/Documents/Issues/Opinion/Submissions/Academics/INSAN_HAKLARI_OKULU3.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="https://www.ohchr.org/Documents/Issues/Opinion/Submissions/Academics/INSAN_HAKLARI_OKULU3.pdf" target="_blank">Academic Freedoms in Turkey in the Period of the State of Emergency</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="/documents/pushback-white-paper.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="/documents/pushback-white-paper.pdf" target="_blank">Refugees and Push-Back Practices : Recent incidents</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="/documents/G2110633.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="/documents/G2110633.pdf" target="_blank">Report on Means to Address The Human Rights Impact of Pushbacks of Migrants on Land and at Sea</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="/documents/EUR2543072021ENGLISH.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="/documents/EUR2543072021ENGLISH.pdf" target="_blank">Refugees and Migrants Still Denied Safety and Asylum At Europe’s Borders</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="/documents/A_HRC_WGAD_2020_47_Advance_Edited_Version.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="/documents/A_HRC_WGAD_2020_47_Advance_Edited_Version.pdf" target="_blank">Human Rights Council Working Group on Arbitrary Detention 25 September 2020</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="/documents/HRC_WorkingGroupOnArbitraryDetention.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="/documents/HRC_WorkingGroupOnArbitraryDetention.pdf" target="_blank">Human Rights Council Working Group on Arbitrary Detention 18 September 2020</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="/documents/SenateTurkeyLetter.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="/documents/SenateTurkeyLetter.pdf" target="_blank">United States Senate Letter regarding Turkey</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="/documents/2021_hrw_world_report.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="/documents/2021_hrw_world_report.pdf" target="_blank">Human Rights Watch World Report 2021</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ formStyles.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ formStyles.icon } >
                        <a href="/documents/ConventionAgainstTorture.pdf" target="_blank"><i className="far fa-file-pdf fa-3x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 10 } sm={ 10 } md={ 10 } className={ formStyles.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ formStyles.documentItem } href="/documents/ConventionAgainstTorture.pdf" target="_blank">Convention against Torture and Other Cruel, Inhuman or Degrading Treatment or Punishment</a>
                        </Typography>
                    </Grid>
                </Grid>
                
                
                
                
                
                
                
                
            </Grid>
        </Container>
    );
}

export default Awareness;
