import React from "react";
import { useForm, useStep } from 'react-hooks-helper';
import FormBasics from "./FormBasics";
import FormIncome from "./FormIncome";
import FormExpense from "./FormExpense";
import Review from "./Review";
import Success from "./Success";

const steps = [
  {id: 'Basic Information'},
  {id: 'Expenditure'},
  {id: 'Income'},
  {id: 'Review'},
  {id: 'Success'},

];

const defaultData = {
  firstName: '',
  lastName: '',
  legalStatus:'',
  dob:'',
  schoolEmail:'',
  personalEmail:'',
  phone:'',
  address:'',
  city:'',
  zipcode:'',
  state:'',
  school:'',
  progress:'',
  program:'',
  studentId:'',
  startYear:'',
  grade:'',
  militaryAffiliation:'',
  salary: '',
  stipend:'',
  federal:'',
  incomeFromstate:'',
  university:'',
  zelle:'',
  bankRouting:'',
  bankAccount:'',
  other:'', 
  rent:'',
  utilities:'',
  // internet:'',
  // electiricityGas:'',
  // water: '',
  // car:'',
  // home:'',
  // medical:'',
  // food:'',
  tuition:'',
  // textbooks:'',
  // supplies:'',
  // creditCard:'',
  // carPurchase:'',
  // householdPurchase:''   
};

const MultiStepForm = ({ images }) => {
  const [inputData, setForm] = useForm (defaultData);
  const { step, navigation } = useStep ({ initialStep:0, steps });
  const { id } = step;
  
   const props = {inputData, setForm, navigation };

    switch (id) {
      case 'Basic Information':
        return <FormBasics {...props}/>;
      case 'Income':
        return <FormIncome {...props} />;
      case 'Expenditure':
        return <FormExpense {...props} />;
      case 'Review':
        return <Review {...props} />; 
      case 'Success':
      return <Success {...props} />; 
      default:
        return null;
    }
  };
  
export default MultiStepForm;
