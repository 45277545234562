export const TakeActionItems = [
    {
      title: 'Join Us',
      path: '/join',
      cName: 'dropdown-link'
    },
    {
      title: 'Donate',
      path: '/donate',
      cName: 'dropdown-link',
    },  
    {
      title: 'Subscribe to our Newsletter',
      path: '/news',
      cName: 'dropdown-link',
    },  
  ];