import React from "react";
import Alert  from '@material-ui/lab/Alert';
import AlertTitle  from '@material-ui/lab/AlertTitle';
import { Grid, Button } from "@material-ui/core";
import AuthService from '../../services/AuthService';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles( ( theme ) => ( {

  submitButton: {
  height: '3rem',
  background: '#1D58A8',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#1D58A8',
  }
},
}))


const Success = ( props ) =>
{
const classes = useStyles();

const handleSignOut = () => {
  AuthService.logout()
  props.history.push( "/scholarship/signin" );
}
  return (
    <Grid container style={ { marginTop: '2rem' } }>
      <Grid item md={2}></Grid>
        <Grid item md={ 8 } >
          <Alert 
            variant="filled" 
            severity="success" 
            action={
              <Button 
                variant="contained" 
                className={classes.submitButton} 
                size="large" 
                onClick={ handleSignOut }>
              SIGNOUT
              </Button>
            }
          >
            <AlertTitle>Success</AlertTitle>
            Your application is submitted successfully! We'll send you an email along with the documents you've submitted</Alert>
        </Grid>
      <Grid item md={2}></Grid>
    </Grid>
  );
}
export default Success;
