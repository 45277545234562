import React, { useState, useEffect } from "react";
import Aos from 'aos';
import '../testimonials/Testimonials.css'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { CardMedia } from '@material-ui/core'
import Card from '@material-ui/core/Card';
import pic1 from '../../../assets/images/melissasm.jpg'
import pic2 from '../../../assets/images/sara.jpeg'
import pic3 from '../../../assets/images/Cathy.jpg'
import pic4 from '../../../assets/images/Jan_Headshot.jpg'

const useStyles = makeStyles( ( theme ) => ( {
    root: {
        marginTop:'1rem',
        boxShadow: "0 0px 0px",
        // border:'1px solid #15678D',

    },
    directors: {
        marginBottom:'4rem',
        boxShadow: "0 0px 0px",
        // border:'1px solid #15678D',

    },
    media: {
        boxShadow: "2px 5px 10px grey",
    },
    who: {
        margin: '1em 2em',
        color: '#15678D',
    },
    info: {
        display: 'flex',
        flexDirection: 'column',
        height: '16rem',
        alignItems:'center',
        justifyContent:'space-around',
    },
    
    details: {
        color: '#15678D',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'1.2rem',
        textDecoration: 'none',
    },

    detailsSmall:{
        color: '#34455E',
        fontFamily: 'Arial, Helvetica, sans-serif',
        fontSize:'0.8rem',
        textDecoration: 'none',
    },

    whoInfo:{
       marginTop:'1rem',
       marginBottom:'1rem',
       fontSize:'1.6rem',
       display:'flex',
    },
    whoInfoSmall:{
        marginTop:'1rem',
        fontSize:'1.2rem',
        display:'flex',
        flexWrap:'wrap'

     },
     explanation:{
        // borderLeft:'4px solid #15678D',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
        flex:1,
        color:'#15678D',
        marginTop:'10rem',

     },
     explanationSmall:{
        borderTop:'4px solid #15678D',
        width:'100%',
        padding:'2rem 2rem',
        height:'100%',
        lineHeight:'2.5rem',
     },

    icon:{
        fontSize:'5rem',
        margin:'10rem 2rem',
    },

    iconSmall:{
        fontSize:'3rem',
        margin:'2rem 2rem',

    },
    whoBtn: {
        position: "relative",
        width: '8rem',
        height: '3rem',
        fontSize: 'medium',
        backgroundColor: '#fff',
        border: '1px solid #15678D',
        color: '#15678D',
        boxShadow: '0 0 3px 1px #fff',
        textDecoration: 'none',
        borderRadius:'0.2rem',
            '&:hover': {
                transition: '0.7s ease-in-out',
                background: '#15678D',
                borderStyle: 'none',
                color: '#fff',
                cursor: 'pointer',
            },
    },

    whoName:{
        color:'#15678D',
        fontSize:'1.2rem',
        textAlign:'center'
    }

} ) );

export default function Who ()
{
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    useEffect(() => {
        Aos.init({ duration: 3000});
    }, []);
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );
    Aos.refresh()
    return (
        <div  className={ classes.who }>
            <div data-aos="fade-up" className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}> 
                <div className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                    <i className="far fa-sad-tear"></i>
                </div>
                <div className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    As in all human tragedies, the ultimate victims are the
                    women and children. They are deeply suffering socially and
                    economically. 
                </div>
               
                <div className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                   Formal measures against them are eliminating all the options for having a job, receiving
                    healthcare, sending kids to school.
                </div>
              
                <div className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                   There is also an incredible pressure for creating an atmosphere of social
                    isolation against them and families.
                </div>
            </div>
            <div  className={(windowWidth > 1024 ? classes.whoInfo : classes.whoInfoSmall)}>
                <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up" )} className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                    <i className="fas fa-umbrella"></i>
                </div> 
                <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    We are not interested in who is doing this or why are they
                    doing this or can somebody do this. We are just interested
                    in relieving the innocent victims of this historical
                    calamity. We assisted people and communities on the basis of need,
                    regardless of race, religion or nationality.
                </div>
                <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={(windowWidth > 1024 ? classes.icon : classes.iconSmall)}>
                    <i className="far fa-life-ring"></i>
                </div>
                <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={(windowWidth > 1024 ? classes.explanation : classes.explanationSmall)}>
                    We are passionate people who believe women and kids
                    suffering from arbitrary practices, silenced and isolated
                    are in need of a voice and light of hope for defending their
                    dignity.
                </div>   
            </div >
            <h1 data-aos="fade-up" style={ { color: '#15678D', textShadow: '1px 1px #fff', textAlign:'center', marginTop: '5rem', marginBottom: '2em' } }>Senior Board of Directors</h1>
            <Grid data-aos="fade-up" container spacing={ 3 } className={ classes.directors }>
                <Grid item xs={ 12 } sm={ 12 } md={ 3 } >
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 1 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Board Director Card Media"
                                    height="300"
                                    className={ classes.media }
                                    image={ pic1 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                        <b>Melissa Page Deutsch</b>
                                    </div>
                                    <a href='/who-melissa'>
                                        <button className={classes.whoBtn}>
                                            About Melissa
                                        </button>
                                    </a>   
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 3 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 1 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Board Director Card Media"
                                    height="300"
                                    className={ classes.media }
                                    image={ pic2 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                        <b>Sara Elizabeth Dill</b>
                                    </div>
                                    <a href='/who-sara'>
                                        <button className={classes.whoBtn}>
                                            About Sara
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 3 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 1 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Board Director Card Media"
                                    height="300"
                                    className={ classes.media }
                                    image={ pic3 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                        <b>Cathy Lewis</b>
                                    </div>
                                    <a href='/who-cathy'>
                                        <button className={classes.whoBtn}>
                                            About Cathy
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 3 }>
                    <Card className={ classes.root }>
                        <Grid container direction='row' spacing={ 1 } >
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <CardMedia
                                    component="img"
                                    alt="Board Director Card Media"
                                    height="300"
                                    className={ classes.media }
                                    image={ pic4 }
                                />
                            </Grid>
                            <Grid item xs={ 6 } sm={ 6 } md={ 6 }>
                                <div className={classes.info}>
                                    <div className={classes.whoName}> 
                                        <b>Jan Pederson</b>
                                    </div>
                                    <a href='/who-jan'>
                                        <button className={classes.whoBtn}>
                                            About Jan
                                        </button>
                                    </a>    
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </div >
    )
}

