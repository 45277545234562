import React, { useEffect } from 'react';
import Aos from 'aos';
import Focus from '../../components/focus/Focus';
import Projects from "../../components/projects/Projects"
import TopImage from '../../components/topImage/TopImage'
import TopInfo from '../../components/topImage/TopInfo'
import './Home.css'

const Home = () => {
    
    return (
        <div className='homeRoot'>
            {/* <TopInfo/> */}
            <Focus />
            <Projects />
            <TopImage />
       </div>
    );
}
export default Home;