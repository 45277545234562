export const AboutusItems = [
    {
      title: 'Who we are',
      path: '/who',
      cName: 'dropdown-link'
    },
    {
      title: 'How we help',
      path: '/how',
      cName: 'dropdown-link'
    },
    {
      title: 'Meaning of Blue Tulip',
      path: '/meaning-of-bt',
      cName: 'dropdown-link'
    },
    {
      title: 'Annual Reports',
      path: '/annual-reports',
      cName: 'dropdown-link'
    },
    // {
    //   title: 'Testimonials',
    //   path: '/testimonials',
    //   cName: 'dropdown-link'
    // },
    // {
    //   title: 'Careers',
    //   path: '/careers',
    //   cName: 'dropdown-link'
    // }
  ];