import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Grid, Typography, Button } from "@material-ui/core";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    // position: "relative",
    display:'flex',
    flexDirection:'column',
    width: '36rem',
    backgroundColor: 'white',
    color:'red',
    border: "1px solid #34455E",
    borderRadius:'0.5rem',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 0,
    marginTop:'10rem',
    margin:'auto',
    fontSize:10,
    '@media screen and (max-width: 700px)':{
      width: '21rem',
      marginTop:'1rem',
      fontSize:10,
      padding: theme.spacing(1, 1, 1),
    }
  },
  readMoreButton:{
      backgroundColor:'#FF9800',
      alignContent:'center',
      color:'#fff',
      height:'5em',
      '&:hover': {
        backgroundColor: '#fff',
        color: '#FF9800',
        border: "1px solid #FF9800",
      }
  },
  modalBody:{
      display:'flex !important'
  }
}));

export default function SimpleModal() {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <Grid container className={classes.paper}>
      <Typography
        variant="h6"
        align="center"
        color="textSecondary"
      >
        Please answer following questions in your essay <br></br>(1,000 words or less)
      </Typography>

      <Typography
        variant="subtitle1"
        align="justify"
        color="textSecondary"
        component="p"
      >
        1. Tell us about yourself. (Please write a short autobiography including
        information about your family, work experience, community involvement,
        hobbies, spare time activities, and what you hope to do in the future.)
      </Typography>
      <Typography
        variant="subtitle1"
        align="justify"
        color="textSecondary"
        component="p"
      >
        2. What are your academic and/or career goals? (Explain what you want to
        study, and then what kind of career you want to lead afterwards.)
      </Typography>
      <Typography
        variant="subtitle1"
        align="justify"
        color="textSecondary"
        component="p"
      >
        3. How will this scholarship help you? (Please answer this by explaining
        how the award money will help you in at least one of the following ways:
        financially, professionally, and/or academically.)
      </Typography>
      <Typography
        variant="subtitle1"
        align="justify"
        color="textSecondary"
        component="p"
      >
        4. Why do you deserve this scholarship? (What differentiates you from
        other students who apply to our scholarship? Discuss your background,
        identity, interest, or talent)
      </Typography>
      <br></br>
      <Typography
        variant="h6"
        align='left'
        color="textSecondary"
      >
        Tuition Document should have your school, student ID, semester and tuition information.
      </Typography>

    </Grid>
  );

  return (
    <div className={classes.modalBody}>
      <Button className={classes.readMoreButton} onClick={handleOpen}>Read more</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
