import axios from "axios";

const { REACT_APP_SCHOLARSHIP_REGISTER_API, REACT_APP_SCHOLARSHIP_SIGNIN_API } = process.env

const register = ( firstName, lastName, email, phoneNumber, password, g_recaptcha_response ) =>
{
  return axios.post( REACT_APP_SCHOLARSHIP_REGISTER_API,
    {
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
    }, {
    headers: {
      g_recaptcha_response
    }
  } 
  );
};

const verifyOtp = ( email, code ) =>
{
  return axios.post( REACT_APP_SCHOLARSHIP_SIGNIN_API, "", {
    headers: {
      email,
      code,
    }
  } )
    .then( ( response ) =>
    {
      if ( response.headers.authorization ) {
        localStorage.setItem( "user", JSON.stringify( response.headers.authorization ) );
      }
      return response.status;
    } );
};

const login = ( email, password, g_recaptcha_response ) =>
{
  console.log("recaptcha: ", g_recaptcha_response)
  return axios.post( REACT_APP_SCHOLARSHIP_SIGNIN_API, "", {
    headers: {
      email,
      password,
      g_recaptcha_response
    }
  } )
    .then( ( response ) =>
    {
      return response.headers.authorization;
    } )
};

const logout = () =>
{
  localStorage.removeItem( "user" );
  window.location.pathname = ( '/scholarship/signin' );
};

const getCurrentUser = () =>
{
  return JSON.parse( localStorage.getItem( "user" ) );
};

const checkAccount = ( email ) => //, g_recaptcha_response ) =>
{
  return axios.post(process.env.REACT_APP_ACCOUNTCHECK_POST_URL,
    {
      email 
    },
    {
      headers: {
        'Content-type': 'application/json'
      }
    }
      // {
      //   headers: {
      //     g_recaptcha_response
      //   }
  );
};

const resetPassword = (password, token) => //, g_recaptcha_response ) =>
{
  console.log("AutService.js - Axios Post request...")
  return axios.post( process.env.REACT_APP_RESETPASSWORD_POST_URL,
    {
      password,
      token
    }
    //   }, {
    //   headers: {
    //     g_recaptcha_response
    //   }
  );
};

export default {
  register,
  verifyOtp,
  login,
  logout,
  getCurrentUser,
  checkAccount,
  resetPassword
};