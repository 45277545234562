import React, {useState, useEffect} from 'react';
import Grid from '@material-ui/core/Grid';
import CardMedia from "@material-ui/core/CardMedia";
import Aos from 'aos';
import "./Donate.css";
import roundUp from '../../assets/images/RoundUp_App.png'
import amazonSmile from '../../assets/images/amazon-smile-big.png'
import payPalBusinessModel from '../../assets/images/Paypal-Business-Model.jpeg'
import roundUpBusinessModel from '../../assets/images/roundup_Business_Model.jpeg'
import amazonSmileBusinessModel from '../../assets/images/AmazonSmile_Business_Model.png'
import zelle from "../../assets/images/zelle.png"

export default function Donate() {
    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );

    useEffect(() => {
        Aos.init({ duration: 3000});
    }, []);

    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );

    return (
        <div className= "topContainer" >
            <Grid
                container
                spacing={ 3 }
                alignItems="center"
                justify="center"
            >
                <Grid item xs={ 12 } >
                    <div className={"wrapper"}>
                        <div className='donate-text'>
                            <h1>Donate now to help women and children.</h1>
                            <h2>Your gift will protect human rights. </h2>
                        </div>
                    </div>
                </Grid>
                {windowWidth > 1024 ? <Grid className={"gapComponent"} item sm={ 12 } md={ 12 }></Grid> : null}
                <Grid item sm={ 2 } md={ 2 }></Grid>
                <div className={(windowWidth > 1024 ? null : "fas fa-award")} style={ { color: "#14688D", fontSize:50 } }></div>
                <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <CardMedia >
                        <div className="donationOptionsText">
                            <h3 className="donate-text">Make your donation securely with your bank account, Paypal balance or Credit Card.</h3>
                            <div>   
                                <form action={"https://www.paypal.com/cgi-bin/webscr"} method={"post"} target={"_blank"}>
                                    <input type={"hidden"} name={"cmd"} value={"_s-xclick"} />
                                    <input type="hidden" name="hosted_button_id" value="MXNGH2Z24SBU8" />
                                    <input 
                                        type={"image"} 
                                        src={"https://www.paypalobjects.com/webstatic/en_US/i/buttons/cc-badges-ppmcvdam.png"} 
                                        name={"submit"} title={"PayPal - The safer, easier way to pay online!"} 
                                        alt="Donate with PayPal button"/>
                                    <img 
                                        src={"https://www.paypal.com/en_US/i/scr/pixel.gif"} 
                                        width={"auto"} 
                                        height={"1"} 
                                        alt=""
                                        className="donate-img"
                                    /> 
                                </form>
                            </div>
                        </div>
                    </CardMedia>
                </Grid>
                <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <CardMedia  >
                        <div className={"donationOptions"}>
                            <form action={"https://www.paypal.com/cgi-bin/webscr"} method={"post"} target={"_blank"}>
                                <input type={"hidden"} name={"cmd"} value={"_s-xclick"} />
                                <input type="hidden" name="hosted_button_id" value="MXNGH2Z24SBU8" />
                                <input 
                                    type={"image"} 
                                    src={payPalBusinessModel} 
                                    name={"submit"} title={"PayPal - The safer, easier way to pay online!"} 
                                    alt="Donate with PayPal button"
                                    height={"100%"} 
                                    width={"100%"} 
                                    className="donate-img"
                                    />
                            </form>
                        </div>
                    </CardMedia>
                </Grid>

                <Grid item sm={ 2 } md={ 2 }></Grid>
                {windowWidth > 1024 ? <Grid className={"gapComponent"} item sm={ 12 } md={ 12 }></Grid> : null}
                <div data-aos="fade-up" className={(windowWidth > 1024 ? null : "fas fa-award")} style={ { color: "#14688D", fontSize:50 } }></div>
                <Grid item sm={ 2 } md={ 2 }></Grid>
                <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <CardMedia >
                        <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={"donationOptionsText"} >
                            <h3 className="donate-text" >If your bank offers Zelle©, you can send online financial gifts through your bank’s “person-to-person” payment service. 
                            <br></br>
                            Make your donation to <br></br><strong style={{fontWeight:900 }}>blue-tulip@blue-tulip.org</strong></h3>
                        </div>
                    </CardMedia>
                </Grid>
                <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <CardMedia  >
                        <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={"donationOptions"}>
                            <a href="https://www.zellepay.com/" target="_blank">
                                <img className="donate-img" src={zelle} alt=""/> 
                            </a>
                        </div>
                    </CardMedia>
                </Grid>
                <Grid item sm={ 2 } md={ 2 }></Grid>
                {windowWidth > 1024 ? <Grid className={"gapComponent"} item sm={ 12 } md={ 12 }></Grid> : null}
                {/* <div data-aos="fade-up" className={(windowWidth > 1024 ? null : "fas fa-award")} style={ { color: "#14688D", fontSize:50 } }></div>
                <Grid item sm={ 2 } md={ 2 }></Grid>
                <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <CardMedia >
                        <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={"donationOptionsText"} >
                            <h3 className="donate-text" >Select Blue Tulip HRC for your Amazon shopping to make Amazon donate us</h3>
                            <a href="https://www.aboutamazon.com/news/community/how-to-sign-up-for-amazonsmile" target="_blank">
                                <img className="donate-img" src={amazonSmile} alt=""/> 
                            </a>
                        </div>
                    </CardMedia>
                </Grid>
                <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <CardMedia  >
                        <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={"donationOptions"}>
                            <a href="https://www.aboutamazon.com/news/community/how-to-sign-up-for-amazonsmile" target="_blank">
                                <img className="donate-img" src={amazonSmileBusinessModel} alt=""/> 
                            </a>
                        </div>
                    </CardMedia>
                </Grid>
                <Grid item sm={ 2 } md={ 2 }></Grid> */}
                {windowWidth > 1024 ? <Grid className={"gapComponent"} item sm={ 12 } md={ 12 }></Grid> : null}
                <div data-aos="fade-up" className={(windowWidth > 1024 ? null : "fas fa-award")} style={ { color: "#14688D", fontSize:50 } }></div>
                <Grid item sm={ 2 } md={ 2 }></Grid>
                <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <CardMedia  >
                        <div data-aos={(windowWidth > 1024 ? "fade-right" : "fade-up")} className={"donationOptionsText"}>
                            <h3 className="donate-text">Donate the “rounded up” change from your everyday purchases to Blue Tulip HRC</h3>
                            <a  href="https://app.roundupapp.com/p/discover/details/4292f790-16a1-11e9-8e15-0b66e02f4cf0" target="_blank">
                                <img className="donate-img" src={roundUp} alt=""/> 
                            </a>
                        </div>
                    </CardMedia>
                </Grid>
                <Grid item xs={ 12 } sm={ 4 } md={ 4 }>
                    <CardMedia  >
                        <div data-aos={(windowWidth > 1024 ? "fade-left" : "fade-up")} className={"donationOptions"}>
                            <a href="https://app.roundupapp.com/p/discover/details/4292f790-16a1-11e9-8e15-0b66e02f4cf0" target="_blank">
                                <img className="donate-img" src={roundUpBusinessModel} alt=""/> 
                            </a>
                        </div>
                    </CardMedia>
                </Grid>
                <Grid item sm={ 2 } md={ 2 }></Grid>
                {windowWidth > 1024 ? <Grid className={"gapComponent"} item sm={ 12 } md={ 12 }></Grid> : null}
            </Grid>
        </div>

    )
}

