export const ProjectsItems = [
    {
      title: 'Scholarship',
      path: '/scholarship',
      cName: 'dropdown-link'
    },
    {
      title: 'Women in Tech',
      path: '/women-in-tech',
      cName: 'dropdown-link'
    },
    {
      title: 'Psychological Support',
      path: '/psychological-support',
      cName: 'dropdown-link'
    },
    {
      title: 'Human Rights Awareness',
      path: '/awareness',
      cName: 'dropdown-link'
    },
    {
      title: 'Scientific Research',
      path: '/research',
      cName: 'dropdown-link'
    },
    // {
    //   title: 'Certifications',
    //   path: '/certifications',
    //   cName: 'dropdown-link'
    // },
  
  ];