import React, { useState, useEffect } from 'react'
import Aos from 'aos';
import {
    Grid,
    Typography
} from '@material-ui/core';
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles( ( theme ) => ( {
    media: {
        position: "relative",
        paddingBottom: "68.25%", // 16:9
        backgroundSize: "80%",
        marginBottom: '3rem'
    },
    topImageContainer: {
        height: '140rem',
        marginTop:"3rem"
    },

    topImageContainerSmall: {
        height: '270rem',
        marginTop:"3rem"

    },
    explanationBlock: {
        display: 'flex',
        paddingTop: '2rem',
        margin: '2rem 2rem 0rem 2rem ',
        '@media screen and (max-width: 900px)': {
            flexDirection: 'column'
        },
    },
    explanation: {
        height: '100%',
        lineHeight: '2.5rem',
        flex: 1,
        padding: '1rem',
        color: "#15678D",
        fontWeight: 200,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 20,
        '@media screen and (max-width: 1024px)': {
            fontSize: 20,
            lineHeight: '2rem',

        },
        '@media screen and (max-width: 700px)': {
            fontSize: 20,
            lineHeight: '2rem',

        },
    },
    icon: {
        fontSize: '3rem',
        margin: '1rem',
        color: '#15678D',

    },
    iconConsentForm:{
        fontSize: '3rem',
        margin: '1rem',
        color: '#15678D',
        textAlign:"center"
        
    },
    iconSmall: {
        fontSize: '2rem',
        margin: '1rem',
        color: '#15678D',
        justifyContent: 'center'

    },
    impSpan: {
        fontWeight: 900
    },
    formStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '1rem'

    },
    documentItem: {
        textDecoration: 'none',
        color: "#15678D",
        alignSelf: 'start',
        fontSize: '1.5rem',
    },

    documentLine: {
        textAlign: 'start',
    },
} ) );
const ScientificResearch = () =>
{

    const [ windowWidth, setWindowWidth ] = useState( window.innerWidth );
    const classes = useStyles();
    useEffect( () =>
    {
        Aos.init( { duration: 3000 } );
    }, [] );
    useEffect( () =>
    {
        const handleWindowResize = () =>
        {
            setWindowWidth( window.innerWidth );
        };

        window.addEventListener( 'resize', handleWindowResize );

        return () =>
        {
            window.removeEventListener( 'resize', handleWindowResize );
        }
    }, [] );
    return (
        <div data-aos="fade-up" className={ ( windowWidth > 1024 ? classes.topImageContainer : classes.topImageContainerSmall )} >
            <Grid
                container
                spacing={ 0 }
                alignItems="center"
                justify="center"
            >
                <Grid data-aos="fade-up" className={ classes.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ classes.iconConsentForm } >
                        <a href="/documents/Call_for_research_participation.pdf" target="_blank"><i className="far fa-file-pdf fa-1x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 5 } sm={ 5 } md={ 5 } className={ classes.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ classes.documentItem } href="/documents/Call_for_research_participation.pdf" target="_blank">Call for research participation</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid data-aos="fade-up" className={ classes.formStyle } container spacing={ 3 }>
                    <Grid item xs={ 2 } sm={ 2 } md={ 2 } className={ classes.iconConsentForm } >
                        <a href="/documents/Consent_Form_Interview.pdf" target="_blank"><i className="far fa-file-pdf fa-1x" style={ { color: "#14688D" } } ></i></a>
                    </Grid>
                    <Grid item xs={ 5 } sm={ 5 } md={ 5 } className={ classes.documentLine }>
                        <Typography variant="subtitle1">
                            <a className={ classes.documentItem } href="/documents/Consent_Form_Interview.pdf" target="_blank">Please Sign - Informed Consent Form</a>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 10 } >
                    <CardMedia
                    >
                        <div className={ classes.explanationBlock }>
                            <div data-aos="fade-up" className={ ( windowWidth > 1024 ? classes.icon : classes.iconSmall ) }>
                                <i className="fas fa-user-tie"></i>
                            </div>
                            <div className={ classes.explanation }>
                                        As BlueTulip Human Rights Center, we are excited to support scientific research sponsored
                                by Embry-Riddle University <span className={ classes.impSpan }>Faculty Seed Grant (FSG)</span>,
                                on “Well-being of Highly Educated Forced Migrants” by participating in the researchers’
                                data collection process with our network.
                                Dr. Erin Rider, Jacksonville State University, and Dr. Cihan Aydiner, Embry-Riddle Aeronautical
                                University, are examining the experiences of highly educated Turkish people in the recently
                                forced migration flow. They search for the immigrants' process in educational attainment,
                                profession transfer, the positive and negative component, and integration issues during
                                the migration and inclusion process.
                            </div>
                            <br />
                            <br />
                        </div>
                    </CardMedia>
                </Grid>
                <Grid item xs={ 12 } sm={ 12 } md={ 10 } >
                    <CardMedia
                    >
                        <div className={ classes.explanationBlock }>
                            <div data-aos="fade-up" className={ ( windowWidth > 1024 ? classes.icon : classes.iconSmall ) }>
                                <i className="fas fa-globe-americas"></i>
                            </div>
                            <div className={ classes.explanation }>
                                We congratulate Dr. Cihan Aydiner, Embry-Riddle Aeronautical University, and Dr. Erin Rider, Jacksonville State 
                                University, for their <span className={ classes.impSpan }>Faculty Innovative Research in Science and Technology 
                                (FIRST)</span> grant with the "Fast-Track Integration Model for Highly Educated Immigrants." BlueTulip Human 
                                Rights Center will continue to support these researchers’ promising projects. We have talked with researchers 
                                about their new study.
                                <br></br>
                                <br></br>
                                Dr. Aydiner summarizes the current problem as follows, “Highly educated immigrants promise the benefits to 
                                contribute to the labor industries of the U.S. and Western countries. However, due to the host state’s 
                                accreditation and immigration policies, there is a delay in transitioning education/career standards and 
                                granting their immigration statuses for these professionals. Many skilled immigrants temporarily take on 
                                low-skilled jobs as they wait for accreditation and migration status or must pursue new educational and 
                                career fields. This delay and deskilling pathway undermine the U.S.’s ability to access a highly skilled 
                                labor pool and integration efforts to retain highly talented, skilled workers.”
                                <br></br>
                                <br></br>
                                In this study, researchers will examine the experiences of highly educated Turkish migrants’ and exiles’ 
                                job market participation in the U.S., their connection with state officials and practitioners, and how 
                                they negotiate temporary deskilling, host state’s economy policies/practices, and professional networks 
                                to find jobs appropriate with their previous statuses. Using this direct knowledge of their experiences 
                                will enable researchers to identify better models to decrease temporary accreditation delays of highly 
                                educated immigrants and support organizations working with a diverse global workforce.
                                <br></br>
                                <br></br>
                                Dr. Aydiner also talked about their interesting preliminary findings, “We have learned fascinating 
                                information from professional immigrants/expats in our first meeting. For example, more than 60 highly
                                 experienced (more than 15,000 flight hours) Turkish pilots were exiled or moved to North America. 
                                 The U.S. spends more than $10 million to train one fighter pilot like them. However, while almost 
                                 all pilots in Canada continue their careers as pilots in Canadian Aviation Industries, pilots in 
                                 the U.S. have been deskilled and could not continue their careers as pilots (except three people) 
                                 due to more than five years of waiting for the immigration process. They had to take on low-skilled 
                                 jobs and pursue new educational and career fields. We expect similar experiences from other 
                                 professionals like doctors, bureaucrats, and security practitioners.”
                                <br></br>
                                <br></br>
                                Researchers hope to find ways to integrate highly educated immigrants more easily into U.S. 
                                society, the job market, and life. They will also test the applicability of a fast-track integration 
                                model based on three pillars (informing, networking, and mentoring) used and applied in Flanders, 
                                Belgium, for the highly educated refugees group from different origin countries in an EU funded 
                                ALL-in-ONE-4-HER grant project. This action research-based model continues to support organizations 
                                and help refugees determine their path to work with professional career coaching and volunteer 
                                mentoring to bring relevant actors and initiatives together.
                            </div>
                                <br />
                                <br />
                        </div>
                    </CardMedia>
                </Grid>
            </Grid>
        </div>
    )
}
export default ScientificResearch;