import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import './News.css';
import SingleNewsElement from "./SingleNewsElement"
import NewsSideElement from "./NewsSideElement"
import RssFeed from "./RssFeed"
import Typography from "@material-ui/core/Typography";
import LoadSpinner from '../../components/LoadSpinner/LoadSpinner'
import moment from "moment";

const useStyles = makeStyles( ( theme ) => ( {
    details: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 12,
        color: 'white',
        fontWeight: 600,
        textShadow: '10',
        fontFamily: "Arial, Helvetica, sans-serif"
    },
    sideNewsTopic: {
        position: 'static',
        color: '#34455E',
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 26,
        marginLeft:'2rem',
        '@media screen and (max-width: 1024px)': {
            fontSize: 22,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 19,
        },
    },
    header: {
        position: "absolute",
        display: "inline",
        bottom: "10%",
        right: '1.6%',
        maxWidth: "90%",
        textAlign: "start",
        color: "black",
        backgroundColor: "white",
        padding: "4px 0",
        fontWeight: 600,
        boxShadow: "10px 0 0 white",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 16,
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 9,
        },
    },
    date: {
        position: "absolute",
        zIndex: 1,
        display: "inline",
        right: "1.6%",
        maxWidth: "50%",
        textAlign: "start",
        color: "black",
        backgroundColor: "white",
        padding: "2px 2px",
        fontWeight: 600,
        boxShadow: "10px 0 0 white",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 16,
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 9,
        },
    },
    tags: {
        position: "absolute",
        display: "inline",
        left: "0%",
        maxWidth: "50%",
        textAlign: "start",
        color: "red",
        backgroundColor: "white",
        padding: "2px 2px",
        fontWeight: 600,
        boxShadow: "10px 0 0 white",
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 16,
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 9,
        },
    },
    author: {
        position: "absolute",
        zIndex: 1,
        display: "inline",
        left: "10%",
        maxWidth: "50%",
        textAlign: "start",
        color: "white",
        fontWeight: 600,
        fontFamily: "Arial, Helvetica, sans-serif",
        fontSize: 16,
        '@media screen and (max-width: 1024px)': {
            fontSize: 12,
        },
        '@media screen and (max-width: 700px)': {
            fontSize: 9,
        },
    },
    mainNews: {
        // marginTop: "2rem"
    },
    sideNews: {
        marginTop: "2.5rem",
        marginBottom: "2rem",
    }
} ) )



function SingleNews ( { image, date, author, title, summary, body, link, tags } )
{
    const [isLoading, setIsLoading] = useState(true);
    const [ newsList, setNewsList ] = useState( [] );
    const [ newsOne, setNewsOne ] = useState( [] );
    const { REACT_APP_NEWS_API_BASE_URL } = process.env;

    let { id } = useParams();
    const classes = useStyles();

    useEffect( () =>
    {
        const fetchData = async () =>
        {
            const respGlobal = await axios.get( REACT_APP_NEWS_API_BASE_URL + "news" )
            setNewsList( respGlobal.data )
            console.log( "newsList: ", newsList )
            setIsLoading(false)
            const respNewsOne = await axios.get( REACT_APP_NEWS_API_BASE_URL + `news/${ id }` )
            setNewsOne( respNewsOne.data )
        };
        fetchData();
    }, [ id ] );
    return (
        <div className='focusCont' > 
        { isLoading ? <LoadSpinner/> :
        <Grid container alignItems="flex-start" justify="center" >
            <Grid item xs={ 8 } sm={ 5 } md={ 6 } className={ classes.mainNews } >
                <SingleNewsElement
                    id={ newsOne?.id }
                    image={ newsOne?.image }
                    date={ newsOne?.date }
                    author={ newsOne?.author }
                    title={ newsOne?.title }
                    summary={ newsOne?.summary }
                    body={ newsOne?.body }
                    link={ newsOne?.link }
                    tags={ newsOne?.tags }
                />
            </Grid>
            <Grid item xs={ 1 } sm={ 1 } md={ 2 } ></Grid>

            <Grid item xs={ 12 } sm={ 4 } md={ 3 } className={ classes.sideNews }>
                <Typography gutterBottom variant="h5" className={ classes.sideNewsTopic } >
                    RECENT NEWS
                </Typography><hr></hr>
                { newsList?.map( ( news ) => 
                {   
                    if ( newsOne?.id === news.id ) {
                        return;
                    } else {
                        return (
                            <>
                                <NewsSideElement
                                    id={ news?.id }
                                    image={ news?.image }
                                    date={ news?.date }
                                    author={ news?.author }
                                    title={ news?.title }
                                    summary={ news?.summary }
                                    body={ news?.body }
                                    link={ news?.link }
                                    tags={ news?.tags }
                                />
                            </>
                        )
                    }
                }
                ) }</Grid>
                <Grid>
                    <RssFeed/>
                </Grid>
        </Grid>
}
            </div > 
    
        );
    }

export default SingleNews;